<template>
  <div class="criteria-sheet" :class="relative ? 'is-relative' : ''">
    <div class="container">
      <div class="header z-[1]" v-if="!relative">
        <h1>
          {{
            !isMarking
              ? "Criteria Sheet"
              : criteriaSheet
              ? criteriaSheet.name
              : "Criteria Sheet"
          }}
        </h1>
        <div class="flex items-center">
          <div class="mr-5 relative" v-if="isMarking">
            <button
              class="bg-white px-3 rounded-[4px]"
              @click="handleGenerateScore"
            >
              Generate Score
            </button>
            <div
              class="absolute py-4 px-4 top-[35px] right-0 bg-white rounded-[4px] w-[340px]"
              v-if="showGeneratedScore"
            >
              <div class="flex justify-between items-center mb-4">
                <p class="text-sm">
                  Student's total score out of {{ criteriaSheet.totalScore }}
                </p>
                <p
                  class="text-center flex text-sm w-[60px] h-[30px] items-center justify-center rounded-[4px] border border-solid border-slate-500"
                >
                  {{ generatedScore.score }}
                </p>
              </div>
              <div class="flex justify-between items-center">
                <p class="text-sm">Student's final grade</p>
                <p
                  class="text-center flex text-sm w-[60px] h-[30px] items-center justify-center rounded-[4px] border border-solid border-slate-500"
                >
                  {{ generatedScore.grade }}
                </p>
              </div>
            </div>
          </div>
          <button class="btn-close" @click="toggleCriteriaSheet">
            <img src="@/assets/close.svg" />
          </button>
        </div>
      </div>
      <div
        class="header bg-flohh-neutral-85"
        v-if="relative && !preview && !previewMode"
      >
        <div class="flex items-center h-full gap-5 pl-2">
          <div>
            <div class="card flex justify-content-center">
              <button
                @click="toggleMenu($event, 'add')"
                v-tooltip.top="{
                  value: 'Add level or criteria',
                  showDelay: 500,
                  pt: {
                    text: 'relative left-[40px]',
                  },
                }"
              >
                <span v-html="icons.plusBlack"></span>
              </button>
              <Menu
                class="!mt-2 !py -1 !w-max"
                ref="menuActionRef"
                id="overlay_menu"
                :popup="true"
              >
                <template #start>
                  <div
                    @click="(e) => handleCell(e, 'Level', 'add')"
                    class="hover:bg-gray-50 cursor-pointer w-full flex border-b border-solid border-gray-200 transition-all rounded-t-md"
                  >
                    <span class="py-2 px-4 text-sm">Level</span>
                  </div>
                </template>
                <Divider />
                <template #end>
                  <div
                    @click="(e) => handleCell(e, 'Criteria', 'add')"
                    class="hover:bg-gray-50 w-full cursor-pointer flex transition-all rounded-b-md"
                  >
                    <span class="py-2 px-4 text-sm">Criteria</span>
                  </div>
                </template>
              </Menu>
            </div>
          </div>
          <div class="pt-2">
            <button
              @click="toggleMenu($event, 'layout')"
              v-tooltip.top="{ value: 'Layout view', showDelay: 500 }"
            >
              <span v-html="icons.pivotIconBlack"></span>
            </button>
            <Menu
              class="!mt-2 !py -1 !w-max"
              ref="menuLayoutRef"
              id="overlay_menu"
              :popup="true"
            >
              <template #start>
                <div
                  @click="(e) => handleCell(e, 'Criteria', 'change')"
                  :class="
                    activeLayout === 'Criteria' ? 'bg-flohh-neutral-95' : ''
                  "
                  class="hover:bg-gray-50 cursor-pointer w-full flex border-b border-solid border-gray-200 transition-all rounded-t-md"
                >
                  <span class="py-2 px-4 text-sm">Criteria layout</span>
                </div>
              </template>
              <template #end>
                <div
                  @click="(e) => handleCell(e, 'Rubric', 'change')"
                  :class="
                    activeLayout === 'Rubric' ? 'bg-flohh-neutral-95' : ''
                  "
                  class="hover:bg-gray-50 cursor-pointer w-full flex transition-all rounded-b-md"
                >
                  <span class="py-2 px-4 text-sm">Rubrics layout</span>
                </div>
              </template>
            </Menu>
          </div>
          <div>
            <button
              @click="handleRedo"
              :class="
                redoLayout && redoLayout.length
                  ? ''
                  : 'opacity-40 pointer-events-none'
              "
              v-tooltip.top="{ value: 'Redo', showDelay: 500 }"
            >
              <span v-html="icons.redoIconBlack"></span>
            </button>
          </div>
          <div>
            <button
              @click="handleUndo"
              :class="
                deletedLayout && deletedLayout.length
                  ? ''
                  : 'opacity-40 pointer-events-none'
              "
              v-tooltip.top="{ value: 'Undo', showDelay: 500 }"
            >
              <span v-html="icons.undoIconBlack"></span>
            </button>
          </div>
        </div>
      </div>

      <div
        class="absolute top-12 right-2 z-10 opacity-"
        :class="preview || previewMode ? '!top-3' : ''"
      >
        <AppButton
          type="white"
          @click="handleSidebar"
          v-tooltip.left="{
            value: 'Show the criteria sheet settings menu',
            showDelay: 500,
          }"
        >
          <span v-html="icons.BarArrowLeftBlack"></span>
        </AppButton>
      </div>

      <div class="content">
        <div
          class="mx-auto relative overflow-x-auto w-full h-[calc(100vh_-_120px)] px-2"
        >
          <div
            v-if="isFetchingData"
            class="w-full h-full absolute top-0 left-0 z-[9] px-6 flex justify-center items-center"
          >
            <LoadingState state="" backdrop="0" />
          </div>
          <GridLayout
            v-if="layout"
            :layout="layout"
            :is-draggable="isDraggable"
            :is-resizable="isResizable"
            :is-bounded="isBounded"
            :colNum="24"
            :row-height="30"
            prevent-collision
          >
            <template #item="{ item }">
              <div
                class="cell overflow-hidden"
                :class="
                  item.o.isLevel
                    ? 'is-level'
                    : item.o.isCriteria
                    ? 'is-criteria'
                    : item.o.isBlank
                    ? 'is-blank'
                    : item.static
                    ? 'is-static'
                    : ''
                "
                :style="{
                  backgroundColor: item.o.color,
                  borderColor: item.o.isSelected ? '#000' : '#000',
                  visibility: item.o.isBlank ? 'hidden' : 'visible',
                  cursor: isViewOnly
                    ? 'default'
                    : item.o.criterionNumber === '0'
                    ? item.o.unscoredCriterionLabel === 'Not Shown'
                      ? 'pointer'
                      : 'default'
                    : 'pointer'
                    ? 'pointer'
                    : 'default',
                }"
                @click="handleSelectCell(item)"
              >
                <div
                  class="absolute top-0 left-0 w-full h-full z-[1] cell-bg"
                  :class="[
                    item.o.isSelected ? 'active' : '',
                    item.o.isHalfScore ? 'is-half' : '',
                  ]"
                  style="background-color: #f9d4d0"
                  :style="{
                    borderColor: item.o.isSelected ? '#000' : '#000',
                    visibility: item.o.isBlank ? 'hidden' : 'visible',
                  }"
                ></div>
                <div class="relative w-full h-full z-[2]">
                  <div class="cell-heading">
                    <div class="criterion-heading">
                      <p
                        class="criterion-number"
                        :class="item.o.isCriteria ? 'hidden' : ''"
                      >
                        {{
                          !item.o.isLevel && !item.o.isBlank
                            ? activeLayout !== "Rubric" && item.o.isCriteria
                              ? ""
                              : criteriaSheet.isShowPoints
                              ? item.o.criterionNumber === "0"
                                ? item.o.unscoredCriterionLabel === "Not Shown"
                                  ? item.o.criterionNumber
                                  : ""
                                : item.o.criterionNumber
                              : ""
                            : ""
                        }}
                      </p>
                      <p
                        class="criterion-point"
                        v-if="
                          item.o.isScorable &&
                          !item.static &&
                          item.o.score > 0 &&
                          criteriaSheet.isShowPoints
                        "
                      >
                        {{ item.o.score }} {{ item.o.score > 1 ? "pts" : "pt" }}
                      </p>
                      <p
                        class="criterion-point"
                        v-if="
                          !item.o.isScorable &&
                          !item.o.isLevel &&
                          !item.o.isCriteria &&
                          criteriaSheet.isShowPoints
                        "
                      >
                        0 pt
                      </p>
                    </div>
                  </div>
                  <div
                    class="criterion-description"
                    v-tooltip="{
                      value: item.o.criterionLabel ? renderText(item) : '',
                      autoHide: false,
                    }"
                  >
                    <p :class="item.o.criterionLabel ? 'text-[14px]' : ''">
                      {{ renderText(item) }}
                    </p>
                  </div>
                  <div
                    class="edit-wrap"
                    v-if="
                      !isMarking &&
                      !isViewOnly &&
                      item.o.criterionType !== 'unscoredCriterionLabel' &&
                      isEdittable &&
                      !previewMode
                    "
                  >
                    <button
                      @click="
                        () => {
                          openContentEditor('cell', true);
                          handleCellForm(item);
                        }
                      "
                    >
                      <span
                        v-html="icons.pencilBlack"
                        class="block h-[12px] w-[12px] [&>*]:w-full [&>*]:h-full"
                      ></span>
                    </button>
                  </div>
                  <div class="link-wrap" v-if="item.o.criterionResourceLink">
                    <a
                      :href="validateHttpLink(item.o.criterionResourceLink)"
                      target="_blank"
                    >
                      <span
                        v-html="icons.iconLink"
                        class="block h-[18px] w-[18px] [&>*]:w-full [&>*]:h-full"
                      ></span>
                    </a>
                  </div>
                </div>
              </div>
            </template>
          </GridLayout>
        </div>
        <Sidebar
          v-model:visible="isSidebarOpen"
          position="right"
          class="pt-4 !w-[292px]"
          :pt="{
            header: {
              class: '!hidden',
            },
          }"
        >
          <div class="flex justify-between mb-4">
            <AppButton
              type="default"
              @click="isSidebarOpen = false"
              v-tooltip.left="{
                value: 'Hide the criteria sheet settings menu',
                showDelay: 500,
              }"
            >
              <span v-html="icons.BarArrowLeftBlack" class="rotate-180"></span>
            </AppButton>
            <div class="flex" v-if="!isViewOnly">
              <AppButton
                type="transparent"
                @click="deleteCriteriaSheet"
                v-if="criteriaSheetData.uuid && !isMarking"
              >
                <span v-html="icons.trashBlack"></span>
              </AppButton>
              <AppButton
                type="submit"
                @click="
                  () => {
                    saveCriteriaSheet();
                  }
                "
                :loading="isSaving"
                class="ml-2"
                v-if="
                  previewMode
                    ? false
                    : !isMarking && isEdittable
                    ? true
                    : isMarking
                    ? true
                    : false
                "
              >
                <span
                  v-html="icons.checkBlack"
                  class="mr-2"
                  v-if="!isSaving"
                ></span>
                Save
              </AppButton>
            </div>
          </div>
          <div class="space-y-2">
            <div
              class="py-4 px-4 bg-flohh-neutral-95 border border-flohh-neutral-85 border-solid rounded-md"
            >
              <div class="flex justify-between">
                <p class="text-flohh-text-body text-flohh-neutral-20">
                  Criteria Sheet Name
                </p>
                <button
                  v-if="!preview && !previewMode && isEdittable"
                  class="btn-content-editor"
                  @click="
                    () => {
                      // visible = true;
                      // isContentEditorOpen = true;
                      openContentEditor('name', true);
                    }
                  "
                >
                  <span v-html="icons.pencilBlack"></span>
                </button>
              </div>
              <!-- @click="openContentEditor('name', true)" -->
              <p
                class="text-flohh-text-title text-flohh-neutral-5 font-flohh-font-bold"
              >
                {{ criteriaSheet.name ? criteriaSheet.name : "Untitled" }}
              </p>
            </div>
            <div
              class="py-4 px-4 bg-flohh-neutral-95 border border-flohh-neutral-85 border-solid rounded-md"
            >
              <div class="flex justify-between">
                <p
                  class="text-flohh-text-body text-flohh-neutral-5 font-flohh-font-bold"
                >
                  <!-- <span v-if="preview"
                    >Total Score: {{ generatedScore.score }}</span
                  > -->
                  <span v-if="preview">
                    Total Score:{{
                      criteriaSheet.totalScore !== null
                        ? ` ${generatedScore.score || 0} / ${
                            criteriaSheet.totalScore || 0
                          }`
                        : ""
                    }}
                  </span>
                  <span v-if="!preview">
                    Total Score:{{
                      criteriaSheet.totalScore
                        ? ` ${criteriaSheet.totalScore}`
                        : ""
                    }}
                  </span>
                </p>
                <button
                  v-if="!preview && !previewMode && isEdittable"
                  class="btn-content-editor"
                  @click="
                    () => {
                      openContentEditor('totalScore', true);
                    }
                  "
                >
                  <span v-html="icons.pencilBlack"></span>
                </button>
              </div>
            </div>
            <div
              class="py-4 px-4 bg-flohh-neutral-95 border border-flohh-neutral-85 border-solid rounded-md"
              :class="
                criteriaSheet.totalScore === ''
                  ? 'pointer-events-none opacity-[0.5]'
                  : ''
              "
            >
              <div class="flex justify-between">
                <p
                  class="text-flohh-text-body text-flohh-neutral-5 font-flohh-font-bold"
                >
                  Grades<span v-if="preview">
                    {{ `: ${generatedScore.grade}` }}
                  </span>
                </p>
                <button
                  v-if="!preview || !previewMode"
                  class="btn-content-editor"
                  @click="
                    () => {
                      openContentEditor('grade', true);
                    }
                  "
                >
                  <span
                    v-html="icons.pencilBlack"
                    v-if="
                      previewMode
                        ? false
                        : !isMarking && isEdittable
                        ? true
                        : isMarking
                        ? false
                        : true
                    "
                  ></span>
                  <span
                    v-html="icons.eyeActive"
                    v-if="
                      previewMode
                        ? true
                        : !isMarking && isEdittable
                        ? false
                        : isMarking
                        ? true
                        : false
                    "
                  ></span>
                </button>
              </div>
            </div>
          </div>
        </Sidebar>

        <!-- FOCUSE HERE -->
      </div>
      <div class="footer" v-if="!relative">
        <ButtonComponent v-bind="$attrs" :title="'Save'" @click="save" />
      </div>
    </div>

    <!-- <CellFormComponet
      v-if="isCellFormOpen"
      :item="selectedItem"
      :close="toggleCellForm"
      @update="handleCellUpdate"
      @delete="handleCellDelete"
    /> -->

    <ContentEditorComponent
      v-if="dataLoaded && visible"
      :visible="visible"
      :contentEditorType="contentEditorType"
      :title="contentEditorTitle[contentEditorType]"
      :data="criteriaSheet"
      :close="toggleContentEditor"
      @update="handleContentEditor"
      @delete="handleCellDelete"
      @cellUpdate="handleCellUpdate"
      :selectedItem="selectedItem"
      @undo="handleUndoDelete"
      @getSheetName="handleGetSheetName"
      :previewMode="previewMode || preview || !isEdittable"
      :isRubricBank="isRubricBank"
      @saveCriteriaSheet="saveCriteriaSheet"
      :gradeEditable="selectedGradeEditable"
      @handleGradeEditable="handleGradeEditable"
      :gradeEdited="gradeEdited"
      @handleGradeEdited="handleGradeEdited"
    >
      <!-- Content creeation -->
      <template v-if="isContentEditorOpen" #name="{ isRequired }">
        <div>
          <div class="pt-6 pb-2">
            <p
              class="text-flohh-text-body font-flohh-font-medium text-flohh-neutral-20"
            >
              Name
            </p>
            <FormTextInput
              type="text"
              name="content"
              :required="isRequired"
              v-model="criteriaSheet.name"
              placeholder="Enter name"
            />
          </div>
        </div>
      </template>
      <!-- content end -->

      <!-- Grade -->
      <template
        v-if="isGradesOpen"
        #grade="{
          data,
          grades,
          handleScore,
          handleGrade,
          handleDelete,
          isGradeDisabled,
        }"
      >
        <div class="py-6">
          <div class="mb-4">
            <p
              class="text-flohh-text-body font-flohh-font-medium text-flohh-neutral-20 mb-2"
            >
              Name
            </p>
            <FormTextInput
              type="text"
              v-model="data.gradeSchemaName"
              placeholder="Enter Name"
              :disabled="
                isMarking
                  ? true
                  : isGradeDisabled
                  ? true
                  : previewMode
                  ? true
                  : !isMarking && isEdittable
                  ? false
                  : true
              "
            />
          </div>
          <h1 class="font-flohh-font-bold">
            Total Score:
            <span class="!font-normal">{{ data.totalScore }}</span>
          </h1>
          <Divider />
          <div class="flex justify-around font-flohh-font-bold">
            <h1>Score</h1>
            <h1 class="pr-4">Grade</h1>
          </div>

          <template v-for="(item, key) in grades" :key="key">
            <div
              class="flex gap-4"
              :class="
                preview || previewMode
                  ? 'pointer-events-none opacity-[0.5]'
                  : ''
              "
            >
              <FormTextInput
                type="number"
                v-model="item.score"
                @input="(e: Event) => { handleScore(e, key) }"
                placeholder="Enter points"
                :max="
                  grades[key - 1]
                    ? grades[key - 1].score > 0
                      ? grades[key - 1].score - 1
                      : 0
                    : data.totalScore
                "
                min="0"
                :style="
                  isGradeDisabled
                    ? ''
                    : Number(item.score) > Number(data.totalScore) ||
                      Number(item.score) >
                        (grades[key - 1] && Number(grades[key - 1].score))
                    ? 'border-color:red'
                    : ''
                "
                :disabled="
                  isGradeDisabled
                    ? true
                    : previewMode
                    ? true
                    : !isMarking && isEdittable
                    ? false
                    : isMarking
                    ? false
                    : true
                "
              />
              <FormTextInput
                type="text"
                v-model="item.grade"
                @input="(e: Event) => { handleGrade(e, key) }"
                placeholder="Enter grade"
                :disabled="
                  isGradeDisabled
                    ? true
                    : previewMode
                    ? true
                    : !isMarking && isEdittable
                    ? false
                    : isMarking
                    ? false
                    : true
                "
              />
              <button
                @click="handleDelete(key)"
                class="opacity-60 hover:opacity-80 transition-all"
                v-if="
                  !preview && !previewMode && isEdittable && !isGradeDisabled
                "
              >
                <span v-html="icons.closeBlack"></span>
              </button>
            </div>
            <hr class="my-2" v-if="key !== grades.length - 1" />
          </template>
        </div>
      </template>
      <!-- Grade end -->

      <!-- Score -->
      <template v-if="isTotalScoreOpen" #totalScore="{ onChange }">
        <div class="pt-6 pb-2 space-y-5">
          <div>
            <p
              class="text-flohh-text-body font-flohh-font-medium text-flohh-neutral-20"
            >
              Scoring Style
            </p>
            <Dropdown
              placeholder="Select scoring style"
              v-model="criteriaSheet.scoringStyle"
              :options="scoringStyles"
              class="w-full h-[53px] bg-flohh-neutral-95 mt-2"
              style="font-size: 14px !important"
              :pt="{ input: { class: '!font-medium' } }"
            />
          </div>
          <div>
            <p
              class="text-flohh-text-body font-flohh-font-medium text-flohh-neutral-20"
            >
              Which criterion are worth points?
            </p>
            <Dropdown
              placeholder="Select criterion"
              v-model="criteriaSheet.criterionType"
              :options="criterion"
              class="w-full h-[53px] bg-flohh-neutral-95 mt-2"
              style="font-size: 14px !important"
              :pt="{ input: { class: '!font-medium' } }"
            />
          </div>
          <div>
            <p
              class="text-flohh-text-body font-flohh-font-medium text-flohh-neutral-20"
            >
              Points
            </p>
            <FormTextInput
              type="number"
              name="criterionPoints"
              v-model="criteriaSheet.criterionPoints"
              @input="onChange($event, 'criterionPoints')"
              placeholder="Enter points"
            />
          </div>
        </div>
      </template>
      <!-- Score end -->

      <!-- Cell -->
      <template v-if="isCell" #cell="{ isRequired }">
        <div>
          <div class="pt-6 space-y-5" :class="isEdittable ? 'pb-2' : 'pb-6'">
            <div>
              <p
                class="pb-2 text-flohh-text-body font-flohh-font-medium text-flohh-neutral-20"
              >
                Criterion Label
              </p>
              <FormTextareaInput
                v-model="labelBaseOnType"
                @input="onInput"
                :placeholder="'Enter Criterion Label...'"
                :name="'label'"
                :rows="5"
                :cols="30"
              />
            </div>
            <div>
              <p
                class="text-flohh-text-body font-flohh-font-medium text-flohh-neutral-20"
              >
                Color
              </p>
              <div class="flex gap-4 pt-2">
                <div
                  class="cursor-pointer hover:opacity-80 transition-all"
                  v-for="color in colors"
                  :key="color.key"
                >
                  <div
                    @click="selectColor(color.name, color.hex)"
                    :class="
                      selectedColor.hex === color.hex
                        ? 'border-2 border-solid border-flohh-neutral-20 ' +
                          color.color
                        : color.color
                    "
                    class="border-5 solid w-6 h-6 rounded-full"
                  ></div>

                  <!-- class="border-[2px] border-solid border-flohh-neutral-35 w-6 h-6 rounded-full" -->
                </div>
              </div>
            </div>
            <div>
              <p
                class="pb-2 text-flohh-text-body font-flohh-font-medium text-flohh-neutral-20"
              >
                Resource Link
              </p>
              <FormTextInput
                type="text"
                name="content"
                :required="isRequired"
                v-model="selectedItem.o.criterionResourceLink"
                placeholder="https://www.google.com"
              />
              <small v-if="isNotLink" class="text-red-400 text-xs"
                >Please provide a valid link.</small
              >
            </div>
          </div>
        </div>
      </template>
      <!-- Cell end -->
    </ContentEditorComponent>

    <Dialog
      :visible="deleteCriteriaSheetModal"
      modal
      :closable="false"
      :close-on-escape="true"
      :style="{ width: '410px' }"
      :pt="{
        header: { class: '!py-4 !pl-6' },
        content: { class: '!p-0' },
        footer: { class: '!p-0' },
      }"
    >
      <template #header>
        <div class="">
          <h5
            class="text-default text-neutral-gray-500 flex font-bold items-center justify-start"
          >
            <i v-html="icons.trashBlack" class="mr-2"></i>Delete Criteria Sheet
          </h5>
        </div>
      </template>
      <div class="border-t border-solid border-neutral-gray-500 py-4 px-6">
        <p class="text-sm text-neutral-gray-500">
          Are you sure you want to delete this criteria sheet?
        </p>
      </div>
      <template #footer>
        <div class="py-2 px-6 flex items-center justify-end">
          <Button
            label="Cancel"
            @click="onClickHideDeleteAssignment"
            class="border border-solid border-neutral-gray-70 bg-transparent text-sm text-neutral-gray-70 rounded-[8px] h-[45px] font-medium btn-medium py-3 px-5"
          />
          <Button
            label="Delete"
            @click="onClickDeleteCriteriaSheet"
            class="border border-solid border-neutral-gray-500 bg-neutral-gray-85 text-sm text-neutral-gray-500 rounded-[8px] h-[45px] font-medium btn-medium py-3 px-5 !mr-0"
            :disabled="loading"
            :loading="loading"
            :class="[loading ? 'cursor-progress' : '']"
          />
        </div>
      </template>
    </Dialog>

    <div
      v-if="isSaving"
      class="absolute z-[11] top-0 left-0 bg-[rgba(0,0,0,0.3)] w-full h-full flex justify-center items-center"
    >
      <LoadingState state="Saving.." backdrop="0" />
    </div>
  </div>
</template>

<script lang="ts">
import { reactive, ref } from "vue";
import { Component, Prop, Vue, Ref, Watch } from "vue-facing-decorator";
import { GridLayout } from "grid-layout-plus";
import { LAYOUT_DATA } from "./template";
import CellFormComponet from "./CellFormComponent.vue";
import ContentEditorComponent from "./ContentEditorComponent.vue";
import ButtonComponent from "@/components/Button/ButtonComponent.vue";
import {
  ILayoutItemObject,
  ILayoutItemRequired,
  TLayoutItems,
  ICategoryMap,
} from "./types";
import { StorageDataKey } from "@/enums/enums";
import { generateGuid } from "@/utils/helper";
import { ILayoutItem } from "./types";
import SelectComponent from "../Input/Select/SelectComponent.vue";
import TextInputComponent from "../Input/TextInput/TextInputComponent.vue";
import Menu from "primevue/menu";
import Menubar from "primevue/menubar";
import Button from "primevue/button";
import Sidebar from "primevue/sidebar";
import Dialog from "primevue/dialog";
import Divider from "primevue/divider";
import Dropdown from "primevue/dropdown";
import RadioButton from "primevue/radiobutton";
import AppButton from "@/components/Layout/Buttons/AppButton.vue";
import { icons as AppIcons } from "@/utils/icons";
import OverlayPanel from "primevue/overlaypanel";
import FormTextInput from "@/components/Layout/Forms/FormTextInput.vue";
import TextArea from "primevue/textarea";
import FormTextareaInput from "@/components/Layout/Forms/FormTextareaInput.vue";
import { useToast } from "primevue/usetoast";
import CriteriaSheetService from "@/services/CriteriaSheetService";
import LoadingState from "../utilities/LoadingState.vue";
import {
  IAttachedResource,
  ICriteriaSheet,
  IGrades,
  IGradeSchema,
  IGradeSchemaItem,
} from "@/models/CriteriaSheet";
import { LAYOUT } from "../Dashboard/template";
import emitter from "@/config/emitter";

interface ICriteriaSheetData {
  uuid: string;
}

@Component({
  components: {
    GridLayout,
    CellFormComponet,
    ContentEditorComponent,
    ButtonComponent,
    SelectComponent,
    TextInputComponent,
    Menu,
    Button,
    Sidebar,
    AppButton,
    FormTextInput,
    Dialog,
    Dropdown,
    Divider,
    OverlayPanel,
    Menubar,
    RadioButton,
    TextArea,
    FormTextareaInput,
    LoadingState,
  },
})
export default class CriteriaSheetComponent extends Vue {
  eventBus = emitter;
  private criteriaSheetService = new CriteriaSheetService();
  toast = useToast();
  isDraggable = false;
  isResizable = false;
  isBounded = true;
  isCellFormOpen = false;
  isContentEditorOpen = false;
  isTotalScoreOpen = false;
  isGradesOpen = false;
  isSidebarOpen = false;
  dataLoaded = false;
  isCell = false;
  selectedColor = {
    hex: "",
    name: "",
  };
  isNotLink = false;

  contentEditorType = "";
  isMenuPopupVisible = [false, false, false];
  selectedCriterion = "";
  criterion = reactive(["Criterion with text only", "All criterion"]);
  scoringStyles = reactive(["Test Schema", "Holistic Scoring"]);
  contentEditorName = "";

  visible = false;
  isSaving = false;

  layout = reactive(JSON.parse(JSON.stringify([])));
  layoutReponseData = [];
  @Ref("menuAction") menuActionRef!: any;
  @Ref("menuLayout") menuLayoutRef!: any;

  @Prop({
    type: Object,
    required: true,
  })
  criteriaSheetData!: ICriteriaSheetData;

  @Prop({
    type: Boolean,
    required: false,
  })
  relative!: boolean;

  @Prop({
    type: Boolean,
    required: false,
  })
  preview!: boolean;

  @Prop({
    type: Boolean,
    required: false,
  })
  previewMode!: boolean;

  @Prop({
    type: Boolean,
    required: false,
  })
  isViewOnly!: boolean;

  @Prop({
    type: Boolean,
    required: false,
  })
  isMarking!: boolean;

  @Prop({
    type: Boolean,
    required: false,
  })
  isRubricBank!: boolean;

  @Prop({
    type: Function as () => void,
    required: false,
  })
  toggleCriteriaSheet!: () => void;

  @Prop({
    type: Function as () => void,
    required: false,
  })
  handleRefreshGrading!: () => void;

  @Prop({
    type: Function as () => void,
    required: false,
  })
  handleGeneratedScore!: () => void;

  @Prop({
    type: Function as () => void,
    required: false,
  })
  refreshGrading!: () => void;

  @Prop({
    type: Function as () => void,
    required: false,
  })
  handlePreviewMode!: (status: boolean) => void;

  @Prop({
    type: Function as () => void,
    required: false,
  })
  handleSaveSidebar!: (response: any) => void;

  @Prop({
    type: String,
    required: false,
  })
  assignmentUuid!: string;

  @Prop({
    type: String,
    required: false,
  })
  studentId!: string;

  @Prop({
    type: String,
    required: false,
  })
  submissionId!: string;

  @Prop({
    type: String,
    required: false,
  })
  gradeUuid!: string;

  @Prop({
    type: Object,
    required: true,
  })
  gradeSchema!: IGradeSchema;

  @Prop({
    type: Object,
    required: false,
  })
  grades!: IGrades;

  @Prop({
    type: String,
    required: false,
  })
  shareCode!: string;

  @Prop({
    type: String,
    required: false,
  })
  action!: string;

  @Prop({
    type: Function as () => void,
    required: false,
  })
  handleAction!: () => void;

  @Prop({
    type: Boolean,
    required: false,
  })
  makeCopy!: boolean;

  selectedItem: ILayoutItemRequired = reactive({} as ILayoutItemRequired);
  points!: number;
  onFocusPoints = false;

  contentEditorTitle: ICriteriaSheet = {
    name: "Criteria Sheet Name",
    totalScore: "Total Score",
    grade: "Grades",
    layout: "Layout Editor",
  };

  CRITERIASHEET_CONFIG = {
    name: "",
    totalScore: "",
    grade: "",
    criterionPoints: 1,
    criterionType: "Criterion with text only",
    scoringStyle: "Holistic Scoring",
    layoutType: "rubric",
    layoutValue: 1,
    gradeSchemaName: "",
    gradeSchemaUuid: "",
    gradesData: [
      {
        score: "",
        grade: "",
      },
      {
        score: "",
        grade: "",
      },
      {
        score: "",
        grade: "",
      },
      {
        score: "",
        grade: "",
      },
    ],
    isShowPoints: false,
  };

  isShowPoints = false;

  criteriaSheet: ICriteriaSheet = this.CRITERIASHEET_CONFIG;
  deletedLayout: TLayoutItems[] = reactive([]);
  redoLayout: TLayoutItems[] = reactive([]);
  activeLayout = "Rubric";

  layoutData: any = JSON.parse(JSON.stringify(LAYOUT_DATA));

  showGeneratedScore = false;
  generatedScore = {
    score: 0,
    grade: "",
    totalScore: 0,
  };

  items = ref([{ label: "Level" }, { label: "Career" }]);
  colors = [
    {
      name: "blue",
      key: "B",
      color: "bg-flohh-secondary-blue",
      hex: "#9CCEE2",
    },
    { name: "red", key: "R", color: "bg-flohh-secondary-red", hex: "#F59289" },
    { name: "pink", key: "P", color: "bg-flohh-primary-pink", hex: "#F9B2CE" },
    {
      name: "yellow",
      key: "Y",
      color: "bg-flohh-secondary-yellow",
      hex: "#FFDE7F",
    },
    {
      name: "orange",
      key: "O",
      color: "bg-flohh-secondary-orange",
      hex: "#FAB28A",
    },
    {
      name: "purple",
      key: "PP",
      color: "bg-flohh-secondary-purple",
      hex: "#A3AFF5",
    },
    {
      name: "green",
      key: "G",
      color: "bg-flohh-secondary-green",
      hex: "#A3DBBE",
    },
  ];
  icons = AppIcons;
  labelBaseOnType: string | undefined = "";
  deleteCriteriaSheetModal = false;
  criteriaSheetDeleted = false;
  loading = false;
  autosave = false;

  isEdittable = true;

  isFetchingData = true;

  gradeSchemaName = "";
  gradeSchemaCopy = false;
  isGradeEdited = false;

  selectedGradeEditable = false;
  gradeEdited = false;

  handleGradeEdited(status: boolean) {
    this.gradeEdited = status;
  }

  @Watch("previewMode")
  previewModeWatch(status: boolean) {
    if (status === false) {
      const cs = localStorage.getItem(StorageDataKey.CriteriaSheetConfig);
      if (cs) {
        this.criteriaSheet = JSON.parse(cs);
        if (this.criteriaSheet.layoutType) {
          this.activeLayout = this.capitalizeFirstLetter(
            this.criteriaSheet.layoutType
          );
          this.handleChangeLayout(this.activeLayout);
        }
      }
    }
  }
  @Watch("makeCopy")
  makeCopyWatch(status: boolean) {
    if (status) {
      this.isEdittable = true;
    }
  }

  handleSidebar() {
    const criteriaSheetConfig = localStorage.getItem(
      StorageDataKey.CriteriaSheetConfig
    );

    this.criteriaSheet = criteriaSheetConfig
      ? JSON.parse(criteriaSheetConfig)
      : this.CRITERIASHEET_CONFIG;
    this.isSidebarOpen = true;
  }

  onInput() {
    this.selectedItem.o.criterionType === "levelLabel"
      ? (this.selectedItem.o.levelLabel = this.labelBaseOnType)
      : this.selectedItem.o.criterionType === "criteriaLabel"
      ? (this.selectedItem.o.criteriaLabel = this.labelBaseOnType)
      : (this.selectedItem.o.criterionLabel = this.labelBaseOnType);
  }

  async saveCriteriaSheet(saveOnly = false, emitSaving = false) {
    this.eventBus.emit("EVENT_TRIGGER", "CS009");
    if (this.isMarking) {
      this.isSaving = true;
      await this.saveCriteriaSheetGrade();
    } else {
      if (this.isEdittable) {
        this.isSaving = true;
        return await this.saveCriteriaSheetTemplate(saveOnly, emitSaving);
      } else {
        this.$emit("toggleCriteriaSheet");
      }
    }
  }

  async saveCriteriaSheetTemplate(saveOnly = false, emitSaving = false) {
    try {
      let criteriaData: any = [];

      this.layout.forEach((item: any, index: number) => {
        let obj: any = {
          cellType: this.handleCellType(item),
          label: this.handleCriterionLabel(item),
          cellConfig: {
            client: "webapp",
            type: "basic",
            category: this.handleCriterionCategory(item),
            detail: item,
          },
          index: item.o.isBlank ? 0 : this.formatCellIndex(item),
          resourceLink: item.o.resourceLink,
        };

        if (item.o.criterionType === "levelLabel") {
          if (this.criteriaSheet.layoutType === "criteria") {
            obj.index = this.formatCellIndex(item)[1];
          } else {
            obj.index = this.formatCellIndex(item)[0];
          }
          obj["levelNumber"] = index + 1;
        } else if (item.o.criterionType === "criteriaLabel") {
          if (this.criteriaSheet.layoutType === "criteria") {
            obj.index = parseInt(item.o.criterionNumber) - 1;
          } else {
            obj.index = parseInt(item.o.criterionNumber) - 1;
          }
        } else {
          obj.criterionNumber = item.o.criterionNumber
            ? parseFloat(item.o.criterionNumber)
            : 0;
          obj.score = item.o.criterionNumber
            ? item.o.unscoredCriterionLabel === "Not Shown"
              ? 0
              : item.o.score === 0
              ? item.o.score
              : this.criteriaSheet.criterionPoints
            : 0;
        }

        if (this.criteriaSheetData.uuid && item.uuid) {
          obj.uuid == item.uuid;
        }

        criteriaData.push(obj);
      });

      const payload = {
        cells: criteriaData,
        sheetDetails: {
          name: this.criteriaSheet.name
            ? this.criteriaSheet.name
            : this.isRubricBank && !this.criteriaSheet.name && emitSaving
            ? `draft-${generateGuid()}`
            : this.criteriaSheet.name,
          sheetConfig: {
            client: "webapp",
            type: "basic",
            category: "flohh:criteria-sheet",
            detail: this.criteriaSheet,
          },
        },
      };
      let response;

      const gradeSchemaPayload: IGradeSchema = {
        name: this.criteriaSheet.gradeSchemaName || "",
        schema: [],
      };
      let isGradeValid = true;
      this.criteriaSheet.gradesData.map((item: any, index: number) => {
        if (
          item.score !== undefined &&
          item.score !== null &&
          !isNaN(item.score) &&
          item.score !== "" &&
          item.grade
        ) {
          const schemaItem: IGradeSchemaItem = {
            minimumScore: parseInt(item.score),
            level: this.criteriaSheet.gradesData.length - index,
            label: item.grade,
          };
          gradeSchemaPayload.schema.push(schemaItem);
        } else {
          if (item.grade) {
            isGradeValid = false;
          }
        }
      });
      const hasGradeSchema = gradeSchemaPayload.schema.length > 0;
      if (payload.sheetDetails.name && isGradeValid) {
        if (this.criteriaSheetData.uuid === "" || this.makeCopy) {
          response = await this.criteriaSheetService.postCriteriaSheet(payload);
          if (response.data.ok) {
            let res;
            if (this.assignmentUuid) {
              const criteriaSheetAttachePayload: IAttachedResource = {
                targetType: "assignment",
                targetId: this.assignmentUuid,
                resourceType: "criteria sheet",
                resourceId: response.data.data.sheetDetails.uuid,
              };
              res =
                await this.criteriaSheetService.attachedCriteriaSheetAssignment(
                  criteriaSheetAttachePayload
                );
            }
            if (hasGradeSchema) {
              let gradeSchemaAttachePayload: IAttachedResource = {
                targetType: "criteria sheet",
                targetId: response.data.data.sheetDetails.uuid,
                resourceType: "grade schema",
                resourceId: "",
              };
              let gradeResponse: any;
              if (this.criteriaSheet.gradeSchemaUuid && !this.gradeSchemaCopy) {
                //
                if (this.isGradeEdited) {
                  gradeResponse =
                    await this.criteriaSheetService.updateGradeSchema(
                      gradeSchemaPayload,
                      this.criteriaSheet.gradeSchemaUuid
                    );
                }
                gradeSchemaAttachePayload.resourceId =
                  this.criteriaSheet.gradeSchemaUuid;
              } else {
                //
                gradeResponse = await this.criteriaSheetService.postGradeSchema(
                  gradeSchemaPayload
                );
                gradeSchemaAttachePayload.resourceId =
                  gradeResponse.data.data.uuid;
              }

              res =
                await this.criteriaSheetService.attachedCriteriaSheetAssignment(
                  gradeSchemaAttachePayload
                );
              this.isSaving = false;
            }
            this.isSaving = false;
            this.criteriaSheetData.uuid = response.data.data.sheetDetails.uuid;
            this.criteriaSheet =
              response.data.data.sheetDetails.sheetConfig.detail;
            if (res) {
              if (res?.data.ok) {
                if (this.action) {
                  this.showToast("Criteria Sheet added to bank.", "success");
                  this.$emit("handleAction");
                  this.$emit("handleRefreshGrading");
                  this.$emit("toggleCriteriaSheet");
                } else {
                  this.showToast("Criteria Sheet has been created.", "success");
                  this.$emit("toggleCriteriaSheet");
                  this.$emit("handleRefreshGrading");
                }
              }
            } else {
              this.showToast("Criteria Sheet has been created.", "success");
              this.$emit("handleRefreshGrading");
              this.$emit("toggleCriteriaSheet");
            }
          }
        } else {
          response = await this.criteriaSheetService.updateCriteriaSheet(
            payload,
            this.criteriaSheetData.uuid
          );
          if (hasGradeSchema) {
            let gradeResponse;
            const gradeSchemaUuid =
              this.gradeSchema?.uuid || this.criteriaSheet.gradeSchemaUuid;
            if (gradeSchemaUuid) {
              if (this.isGradeEdited) {
                gradeResponse =
                  await this.criteriaSheetService.updateGradeSchema(
                    gradeSchemaPayload,
                    gradeSchemaUuid
                  );
              }
            } else {
              gradeResponse = await this.criteriaSheetService.postGradeSchema(
                gradeSchemaPayload
              );
            }
          }
          this.isSaving = false;

          if (response.data.ok) {
            this.showToast("Criteria Sheet has been updated.", "success");
            this.$emit("handleRefreshGrading", this.criteriaSheet);
            if (!this.action) {
              this.$emit("toggleCriteriaSheet");
            }
          }
        }
      } else {
        //
        if (!isGradeValid) {
          this.showToast("Please fill or remove empty grade rows.", "error");
        } else {
          this.showToast(
            "Give your criteria sheet a name before saving.",
            "error"
          );
        }
      }
      this.isSaving = false;

      if (this.handleSaveSidebar && !saveOnly) {
        this.handleSaveSidebar(response);
      }
      return response;
    } catch (e) {
      console.error(e);
      this.isSaving = false;
      this.isSidebarOpen = false;
    } finally {
      this.isSaving = false;
      if (!this.isRubricBank) {
        this.isSidebarOpen = false;
      }
    }
  }

  handleCellType(item: any) {
    let cellType;
    switch (true) {
      case item.o.isBlank:
        cellType = "criteria";
        break;
      case item.o.criterionType === "levelLabel":
        cellType = "level";
        break;
      case item.o.criterionType === "criteriaLabel":
        cellType = "criteria";
        break;
      default:
        cellType = "criterion";
    }
    return cellType;
  }

  handleCriterionLabel(item: any) {
    let label;
    switch (item.o.criterionType) {
      case "levelLabel":
        label = item.o.levelLabel;
        break;
      case "criteriaLabel":
        label = item.o.criteriaLabel;
        break;
      case "unscoredCriterionLabel":
        label = item.o.unscoredCriterionLabel;
        break;
      case "criterionLabel":
        label = item.o.criterionLabel;
        break;
      default:
        label = "temp";
    }
    return label || "temp";
  }

  handleCriterionCategory(item: any) {
    const categoryMap: any = {
      levelLabel: "flohh:scoring-level",
      criteriaLabel: "flohh:criteria",
      unscoredCriterionLabel: "flohh:criterion",
      criterionLabel: "flohh:criterion",
    };

    return categoryMap[item.o.criterionType] || "flohh:criterion";
  }

  formatCellIndex(data: any) {
    let arr = [0, 0];

    const number =
      data.o.criterionNumber && data.o.criterionNumber.length > 1
        ? data.o.criterionNumber.split(".")
        : [];

    if (number.length > 1 && !data.o.isBlank) {
      const x = parseInt(number[0]);
      const y = parseInt(number[1]);
      return (arr = [y, x - 1]);
    }

    // if (data.o.criterionType === "unscoredCriterionLabel") {
    //   //
    //   if (this.criteriaSheet.layoutType === "criteria") {
    //     const x = 0;
    //     const y = parseInt(data.y > 0 ? data.y / 2 : 0);
    //     return (arr = [x, y]);
    //   } else {
    //     const x = parseInt(data.x > 0 ? data.x - 1 : 0);
    //     const y = 0;
    //     return (arr = [y, x]);
    //   }
    // }

    return arr;
  }

  groupBy(arr: any, key: any) {
    return arr.reduce((acc: any, obj: any) => {
      const groupKey = obj[key];
      if (!acc[groupKey]) {
        acc[groupKey] = [];
      }
      acc[groupKey].push(obj);
      acc[groupKey].sort((a: any, b: any) => a.y - b.y);
      return acc;
    }, {});
  }
  getSelectedItemsBelowIndex(data: any) {
    const selectedItems = [];

    // Iterate over each group
    for (const groupKey in data) {
      const group = data[groupKey];

      // Iterate over each item in the group
      for (let i = 0; i < group.length; i++) {
        const item = group[i];

        // If item is selected, add it to the selectedItems array and get items below selected index
        if (item.o.isSelected) {
          selectedItems.push(item);
          // Add items below selected index
          if (
            !this.criteriaSheet.scoringStyle ||
            this.criteriaSheet.scoringStyle === "Holistic Scoring"
          ) {
            for (let j = i + 1; j < group.length; j++) {
              group[j].o.isSelected = true;
              selectedItems.push(group[j]);
            }
            break;
          }
          // Break the loop for the current group
        }
      }
    }

    return selectedItems;
  }

  async saveCriteriaSheetGrade() {
    //
    try {
      const filteredData = JSON.parse(JSON.stringify(this.layout)).filter(
        (obj: any) =>
          obj.o.isScorable || obj.o.unscoredCriterionLabel === "Not Shown"
      );
      const layoutType =
        this.criteriaSheet.layoutType === "criteria" ? "y" : "x";
      const getSelectedCells = this.getSelectedItemsBelowIndex(
        this.groupBy(filteredData, layoutType)
      );
      const criterionUUIDs = this.layoutReponseData
        .filter((objA: any) =>
          getSelectedCells.some(
            (objB: any) =>
              objB.i === objA.cellConfig.detail.i && objB.o.isSelected
          )
        )
        .map((item: any) => {
          return {
            uuid: item.uuid,
            score: item.cellConfig.detail.o.isHalfScore
              ? item.score / 2
              : item.score,
          };
        });
      let res;
      if (this.gradeUuid) {
        const payload = {
          cells: criterionUUIDs,
        };
        if (payload.cells.length) {
          res = await this.criteriaSheetService.updateGrade(
            payload,
            this.gradeUuid
          );
        } else {
          this.showToast(
            "Oops! Looks like you've left some criteria blank. Select the cells you want to mark before you try closing again.",
            "error",
            5000
          );
        }
      } else {
        const payload: IGrades = {
          cells: criterionUUIDs,
          student: this.studentId,
          submission: this.submissionId,
        };

        if (payload.cells.length) {
          res = await this.criteriaSheetService.postGrade(payload);
        } else {
          this.showToast(
            "Oops! Looks like you've left some criteria blank. Select the cells you want to mark before you try closing again.",
            "error",
            5000
          );
        }
      }
      if (res?.data.ok) {
        const percentage = this.handleCalcPercentage(
          this.generatedScore.score,
          Number(this.criteriaSheet.totalScore || 0)
        );
        if (!this.autosave) {
          this.showToast("Criteria Sheet has been marked.", "success");
        }
        this.$emit(
          "generateScore",
          {
            ...this.generatedScore,
            percentage,
          },
          true
        );
        this.$emit("toggleCriteriaSheet");
      }
      this.isSaving = false;
    } catch (e) {
      //
      console.error(e);
      this.isSaving = false;
    }
  }

  deleteCriteriaSheet() {
    this.deleteCriteriaSheetModal = true;
  }

  onClickHideDeleteAssignment() {
    this.deleteCriteriaSheetModal = false;
  }

  handleGradeEditable(status: boolean) {
    this.selectedGradeEditable = status;
  }

  async onClickDeleteCriteriaSheet() {
    //
    this.eventBus.emit("EVENT_TRIGGER", "CS016");
    this.criteriaSheetDeleted = false;
    this.loading = true;
    if (this.assignmentUuid) {
      const criteriaSheetDetachPayload: IAttachedResource = {
        targetType: "assignment",
        targetId: this.assignmentUuid,
        resourceType: "criteria sheet",
        resourceId: this.criteriaSheetData.uuid,
      };

      const criteriaSheetRes =
        await this.criteriaSheetService.detachedCriteriaSheetAssignment(
          criteriaSheetDetachPayload
        );
      this.loading = false;
      this.criteriaSheetDeleted = true;

      if (criteriaSheetRes.data.ok) {
        this.showToast("Criteria Sheet has been removed.", "success");
        localStorage.removeItem(StorageDataKey.CriteriaSheet);
        localStorage.removeItem(StorageDataKey.CriteriaSheetConfig);
        this.handleGetSheetName("Untitled");
        this.$emit("handleRefreshGrading", criteriaSheetRes.data.ok);
        this.$emit("getTitle", "");
      }
    } else {
      const res = await this.criteriaSheetService.deleteCriteriaSheet(
        this.criteriaSheetData.uuid
      );

      if (res.data.ok) {
        this.showToast("Criteria Sheet has been deleted", "success");
        this.$emit("handleRefreshGrading", res.data.ok);
      }
    }
    this.$emit("toggleCriteriaSheet");
    this.deleteCriteriaSheetModal = false;
  }

  showToast(
    message: string,
    severity: "success" | "info" | "warn" | "error" | undefined,
    duration = 3000
  ) {
    this.toast.add({
      severity: severity,
      detail: message,
      life: duration,
    });
  }

  selectColor(color: string, hex: string) {
    this.selectedColor = { name: color, hex };
  }

  toggleMenu(event: Event, type: string) {
    if (type === "add") {
      this.eventBus.emit("EVENT_TRIGGER", "CS012");
      this.menuActionRef.toggle(event);
    } else {
      this.eventBus.emit("EVENT_TRIGGER", "CS013");
      this.menuLayoutRef.toggle(event);
    }
  }

  async mounted() {
    this.criteriaSheetDeleted = false;
    this.autosave = false;
    await this.getData();

    const deletedData = localStorage.getItem(StorageDataKey.DeletedLayout);
    this.deletedLayout = deletedData ? JSON.parse(deletedData) : [];

    const redoData = localStorage.getItem(StorageDataKey.RedoLayout);
    this.redoLayout = redoData ? JSON.parse(redoData) : [];

    const points = localStorage.getItem(StorageDataKey.PointsMultiplier);
    this.points = points ? parseInt(JSON.parse(points)) : 0;

    const criteriaSheetConfig = localStorage.getItem(
      StorageDataKey.CriteriaSheetConfig
    );

    this.criteriaSheet = criteriaSheetConfig
      ? JSON.parse(criteriaSheetConfig)
      : this.CRITERIASHEET_CONFIG;

    this.dataLoaded = true;

    window.addEventListener("click", this.onClickOutside);
    sessionStorage.removeItem("shareCode");
    sessionStorage.removeItem("sharePage");

    // this.eventBus.on("HANDLE_SAVE_CRITERIA", () => {
    //   console.log("SAVE");
    //   this.saveCriteriaSheet();
    // });
  }

  async beforeUnmount() {
    // this.autosave = true;
    // if (!this.criteriaSheetDeleted && this.isMarking) {
    //   this.autosave = true;
    //   await this.saveCriteriaSheet();
    // }

    this.layout = [];
    this.layoutData = [];
    this.criteriaSheet = this.CRITERIASHEET_CONFIG;
    localStorage.removeItem(StorageDataKey.CriteriaSheet);
    localStorage.removeItem(StorageDataKey.CriteriaSheetConfig);
    localStorage.removeItem(StorageDataKey.RedoLayout);
    localStorage.removeItem(StorageDataKey.DeletedLayout);
  }
  async getData() {
    let layoutData;
    const isTeacher = localStorage.getItem("authenticatedAs") === "teacher";
    const isSuperadmin =
      localStorage.getItem("authenticatedAs") === "super admin";
    if (isTeacher || isSuperadmin) {
      layoutData = await this.handleGetCriteriaData();
    } else {
      layoutData = await this.handleStudentData();
    }
    this.isFetchingData = false;
    this.layout = layoutData;
  }

  beforeDestroy() {
    window.removeEventListener("click", this.onClickOutside);
  }

  private onClickOutside(event: Event) {
    const addDropdown = this.$refs.addDropdown as HTMLElement;
    const changeDropdown = this.$refs.changeDropdown as HTMLElement;
    const optionDropdown = this.$refs.optionDropdown as HTMLElement;
    if (addDropdown && !addDropdown.contains(event.target as Node)) {
      this.isMenuPopupVisible[0] = false;
    }
    if (changeDropdown && !changeDropdown.contains(event.target as Node)) {
      this.isMenuPopupVisible[1] = false;
    }
    if (optionDropdown && !optionDropdown.contains(event.target as Node)) {
      this.isMenuPopupVisible[2] = false;
    }
  }

  capitalizeFirstLetter(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  async handleGetCriteriaData() {
    if (this.assignmentUuid) {
      const res = await this.criteriaSheetService.getCriteriaSheetAssignment(
        this.assignmentUuid
      );

      if (res.data.ok) {
        if (!this.isMarking && res.data.data[0]) {
          this.isEdittable = res.data.data[0].editable;
          this.selectedGradeEditable = res.data.data[0].editable;
        }
        if (res.data.data.length && res.data.data[0].cells.length) {
          return this.handleCriteriaLayout(res.data.data[0]);
        } else {
          if (this.criteriaSheetData.uuid) {
            const res = await this.criteriaSheetService.getCriteriaSheet(
              this.criteriaSheetData.uuid
            );
            if (res.data.data && res.data.data.cells.length) {
              return this.handleCriteriaLayout(res.data.data);
            } else {
              return this.handleDefaultLayout();
            }
          }
        }
      } else {
        return this.handleDefaultLayout();
      }
    } else {
      if (this.criteriaSheetData.uuid) {
        const res = await this.criteriaSheetService.getCriteriaSheet(
          this.criteriaSheetData.uuid
        );

        if (!this.makeCopy) {
          this.isEdittable = res.data.data.editable;
          this.selectedGradeEditable = res.data.data.editable.editable;
        }
        if (res.data.data && res.data.data.cells.length) {
          return this.handleCriteriaLayout(res.data.data);
        } else {
          return this.handleDefaultLayout();
        }
      } else {
        if (this.shareCode && this.action === "share-code") {
          try {
            const res = await this.criteriaSheetService.getShareCode(
              this.shareCode
            );
            if (res.data.ok) {
              if (res.data.data && res.data.data.data.cells.length) {
                return this.handleCriteriaLayout(res.data.data.data);
              } else {
                return this.handleDefaultLayout();
              }
            } else {
              this.showToast("Share code has expired.", "error");
            }
          } catch (e: any) {
            //
            if (
              e &&
              e.response.data.details.entity === "CriteriaSheetEntity" &&
              e.response.data.details.info ===
                "Could not find the requested criteria sheet. The criteria sheet does not exist or has been deleted by the owner."
            ) {
              //
              this.$emit("toggleCriteriaSheet");
            }
          }
        } else {
          return this.handleDefaultLayout();
        }
      }
    }
  }

  async handleStudentData() {
    const res = await this.criteriaSheetService.getStudentCriteriaGrade(
      this.submissionId
    );
    if (res.data.ok && res.data.data) {
      if (res.data.data.criteriaSheet.cells.length) {
        return this.handleCriteriaLayout(res.data.data.criteriaSheet);
      } else {
        return this.handleDefaultLayout();
      }
    } else {
      return this.handleDefaultLayout();
    }
  }

  handleCriteriaLayout(criteriaData: any) {
    const data = criteriaData.cells;
    const sheetDetails = criteriaData.sheetDetails;
    const criteriaSheetCells: any = [];

    sheetDetails.sheetConfig.detail.name = sheetDetails.name;
    this.handleGetSheetName(sheetDetails.name);
    this.activeLayout = this.capitalizeFirstLetter(
      sheetDetails.sheetConfig.detail.layoutType
    );
    this.layoutReponseData = data;
    const selectedUUids: any = [];
    const criterionPoint = sheetDetails.sheetConfig.detail.criterionPoints;

    if (this.grades) {
      let groupedData: any = {};
      JSON.parse(JSON.stringify(data)).forEach((item: any) => {
        // const exists = this.grades.cells.some((o) => o.uuid === item.uuid);
        const foundObject = this.grades.cells.find(
          (o: any) => o.uuid === item.uuid
        );

        if (foundObject) {
          const indexValue = item.index[1];
          if (!groupedData[indexValue]) {
            groupedData[indexValue] = [];
          }
          groupedData[indexValue].push({
            ...item,
            score: foundObject.score,
          });
        }
      });
      for (const groupKey in groupedData) {
        const group = groupedData[groupKey];
        for (let i = 0; i < group.length; i++) {
          if (
            !sheetDetails.sheetConfig.detail.scoringStyle ||
            sheetDetails.sheetConfig.detail.scoringStyle === "Holistic Scoring"
          ) {
            if (i === 0) {
              selectedUUids.push({
                uuid: group[i].uuid,
                score: group[i].score,
                isHalfScore: group[i].score === criterionPoint / 2,
              });
            }
          } else {
            selectedUUids.push({
              uuid: group[i].uuid,
              score: group[i].score,
              isHalfScore: group[i].score === criterionPoint / 2,
            });
          }
        }
      }
    }

    data.forEach((item: any) => {
      const foundObject = selectedUUids.find((o: any) => o.uuid === item.uuid);
      if (foundObject) {
        item.cellConfig.detail.o.isSelected = true;
        item.cellConfig.detail.o.isHalfScore = foundObject.isHalfScore;
      }
      criteriaSheetCells.push(item.cellConfig.detail);
    });
    this.handleInitLayoutData(
      criteriaSheetCells,
      sheetDetails.sheetConfig.detail.layoutType
    );

    // this.layoutData = this.groupBy(criteriaSheetCells, lt)

    let csConfig = sheetDetails.sheetConfig.detail;
    if (criteriaData.gradeSchema) {
      csConfig.gradeSchemaUuid = criteriaData.gradeSchema.uuid;
    }
    localStorage.setItem(
      StorageDataKey.CriteriaSheet,
      JSON.stringify(criteriaSheetCells)
    );

    localStorage.setItem(
      StorageDataKey.CriteriaSheetConfig,
      JSON.stringify(csConfig)
    );

    if (this.grades) {
      this.criteriaSheet = csConfig;

      const score: any = this.grades.rawScore;
      this.generatedScore = {
        score: score,
        grade: this.grades.grade || "",
        totalScore: Number(this.criteriaSheet.totalScore || 0),
      };
    }

    return criteriaSheetCells;
  }

  handleLayoutData(data: any, groupKey: string, sortKey: string) {
    const groupedData = Object.values(
      data.reduce((result: any, currentItem: any) => {
        (result[currentItem[groupKey]] =
          result[currentItem[groupKey]] || []).push(currentItem);
        return result;
      }, {})
    );

    groupedData.forEach((group: any) => {
      group.sort((a: any, b: any) => a[sortKey] - b[sortKey]);
    });

    Object.values(groupedData).forEach((group: any) => {
      const isCriteriaTrue = group.filter(
        (obj: any) => obj.o.isCriteria || obj.o.isBlank
      );
      const isCriteriaFalse = group.filter(
        (obj: any) => !obj.o.isCriteria && !obj.o.isBlank
      );
      group.length = 0;
      group.push(...isCriteriaFalse, ...isCriteriaTrue);
    });

    return Object.values(groupedData);
  }

  sortByY(a: any, b: any) {
    return a.y - b.y;
  }

  sortByX(a: any, b: any) {
    return a.x - b.x;
  }

  handleInitLayoutData(data: any, criteria: any) {
    const lt = criteria === "criteria" ? "y" : "x";
    this.layoutData = this.handleLayoutData(data, lt, "y");
  }

  handleDefaultLayout() {
    const template = localStorage.getItem(StorageDataKey.CriteriaSheet);
    if (template) {
      this.handleInitLayoutData(
        JSON.parse(template),
        this.criteriaSheet.layoutType
      );
      return JSON.parse(template);
    } else {
      const data = JSON.parse(JSON.stringify(LAYOUT_DATA));
      this.layoutData = data;
      localStorage.setItem(
        StorageDataKey.CriteriaSheet,
        JSON.stringify(data.flat())
      );
      return data.flat();
    }
  }

  handleAutocompleteCriterion(value: string) {
    this.selectedCriterion = value;
  }

  openContentEditor(type: string, status: boolean) {
    this.visible = status;
    if (type === "totalScore") {
      this.eventBus.emit("EVENT_TRIGGER", "CS002");
      this.isTotalScoreOpen = status;
    } else if (type === "grade") {
      this.eventBus.emit("EVENT_TRIGGER", "CS003");
      this.isGradesOpen = status;
    } else if (type === "name") {
      this.eventBus.emit("EVENT_TRIGGER", "CS001");
      this.isContentEditorOpen = status;
    } else {
      this.eventBus.emit("EVENT_TRIGGER", "CS010");
      this.isCell = status;
    }
    this.contentEditorType = type;
  }

  toggleCellForm() {
    this.isCellFormOpen = !this.isCellFormOpen;
  }

  toggleContentEditor() {
    this.isContentEditorOpen = false;
    this.isTotalScoreOpen = false;
    this.isGradesOpen = false;
    this.isCell = false;
    this.visible = false;
    this.selectedItem = reactive({} as ILayoutItemRequired);
    this.selectedColor = { hex: "", name: "" };
    this.isNotLink = false;
  }

  handleGetSheetName(name: string) {
    this.$emit("getTitle", name);
  }

  handleCellForm(item: ILayoutItemRequired) {
    this.selectedItem = item;
    this.selectedColor = {
      hex: this.selectedItem.o.color,
      name: "",
    };

    this.labelBaseOnType =
      this.selectedItem.o.criterionType === "levelLabel"
        ? this.selectedItem.o.levelLabel
        : this.selectedItem.o.criterionType === "criteriaLabel"
        ? this.selectedItem.o.criteriaLabel
        : this.selectedItem.o.criterionLabel;
  }

  handleContentEditor(data: ICriteriaSheet, isGradeEdited = false) {
    this.criteriaSheet = data;
    this.isGradeEdited = isGradeEdited;

    let isValid = true;
    let message = "";
    if (this.contentEditorType === "layout") {
      if (this.criteriaSheet.layoutType === "criteria") {
        let newLayoutData: ILayoutItem[][] = [];
        this.layoutData.map((item: ILayoutItem[]) => {
          let arr = [];
          if (this.criteriaSheet.layoutValue) {
            for (let i = 0; i < this.criteriaSheet.layoutValue; i++) {
              const multiplier = this.criteriaSheet.layoutValue > 1 ? i + 1 : 1;
              const clonedFirstSubArray = {
                ...item[0],
                i: generateGuid(),
                o: {
                  ...item[0].o,
                  // text: "",
                  criterionNumber: item[0].o.criterionNumber
                    ? String(
                        (
                          parseFloat(item[0].o.criterionNumber) +
                          0.1 * multiplier
                        ).toFixed(1)
                      )
                    : "",
                },
              };
              arr.push(clonedFirstSubArray);
            }

            const newArray = [...arr.reverse(), ...item];
            newLayoutData.push(newArray);
          }
        });

        this.layoutData = newLayoutData.map((item: ILayoutItem[]) => {
          return item.map((o: ILayoutItem, k: number) => {
            return {
              ...o,
              i: generateGuid(),
              y: (k *= 2),
            };
          });
        });
      } else {
        //
        let newArray = [];
        if (this.criteriaSheet.layoutValue) {
          for (let i = 0; i < this.criteriaSheet.layoutValue; i++) {
            const lastCol = this.layoutData[this.layoutData.length - 1];
            const multiplier = this.criteriaSheet.layoutValue > 1 ? i + 1 : 1;
            const copiedArray = lastCol.map((obj: ILayoutItem) => ({
              ...obj,
              x: lastCol[0].x + 1 * multiplier,
              i: generateGuid(),
              o: {
                ...obj.o,
                // text: obj.o.text !== "Not Shown" ? "" : "Not Shown",
                criterionNumber: obj.o.criterionNumber
                  ? String(parseFloat(obj.o.criterionNumber) + multiplier)
                  : "",
              },
            }));
            newArray.push(copiedArray);
          }
        }

        this.layoutData = this.layoutData.concat(newArray);
      }
      this.savePoints();
    } else {
      this.criteriaSheet.isShowPoints = true;
    }

    if (this.contentEditorType === "totalScore") {
      this.savePoints();
    } else {
      localStorage.setItem(
        StorageDataKey.CriteriaSheetConfig,
        JSON.stringify(this.criteriaSheet)
      );
    }

    if (this.contentEditorType === "name") {
      if (data.name === "") {
        isValid = false;
        message = "Please add a criteria name";
      }
    }

    if (this.contentEditorType === "grade") {
      //

      const grades = data.gradesData;
      grades.forEach((item: any, index: number) => {
        if (
          Number(item.score) >
            (grades[index - 1] && Number(grades[index - 1].score)) &&
          isValid
        ) {
          isValid = false;
          message =
            "Please enter the grade scores in descending order (e.g., 14, 12, 10) to save the grade schema.";
        }
      });
      if (
        grades[0].score &&
        data.totalScore &&
        Number(grades[0].score) > Number(data.totalScore)
      ) {
        isValid = false;
        message = "Score should not be higher than total score.";
      }
    }

    if (isValid) {
      this.toggleContentEditor();
    } else {
      this.showToast(message, "error");
    }
  }

  handleCellDelete(data: ILayoutItemRequired) {
    let arrayIndex = null;
    const clonedLayoutData = Object.assign([], this.layoutData);
    if (localStorage.getItem(StorageDataKey.DeletedLayout)) {
      const deletedData: string | null = localStorage.getItem(
        StorageDataKey.DeletedLayout
      );
      if (deletedData) {
        this.deletedLayout = [...JSON.parse(deletedData), clonedLayoutData];
      }
    } else {
      this.deletedLayout.push(clonedLayoutData);
    }

    localStorage.setItem(
      StorageDataKey.DeletedLayout,
      JSON.stringify(this.deletedLayout)
    );

    localStorage.setItem(StorageDataKey.RedoLayout, JSON.stringify([]));
    this.redoLayout = [];

    if (data.o.isLevel) {
      this.layoutData.map((item: ILayoutItem[]) => {
        item.map((o: ILayoutItem, key: number) => {
          if (this.activeLayout === "Criteria") {
            //
            if (o.x === data.x) {
              item.splice(key, 1);
            }
          } else {
            if (o.y === data.y) {
              item.splice(key, 1);
            }
          }
        });
      });
    } else {
      this.layoutData.map((item: ILayoutItem[], index: number) => {
        item.map((o: ILayoutItem) => {
          if (this.activeLayout === "Criteria") {
            if (o.y === data.y) {
              arrayIndex = index;
            }
          } else {
            if (o.i === data.i) {
              arrayIndex = index;
            }
          }
        });
      });
      if (arrayIndex && this.layoutData[arrayIndex]) {
        this.layoutData.splice(arrayIndex, 1);
      }
    }

    if (this.activeLayout === "Criteria") {
      //
      const clonedData = Object.assign([], this.layoutData);
      let arr: ILayoutItem[][] = [];
      clonedData.map((item: ILayoutItem[], index: number) => {
        arr[index] = [];
        let num = item.length - 2;
        let n = 1;
        item.map((o: ILayoutItem, i: number) => {
          const decimalValue = (index + num * 0.1).toFixed(1);
          num--;
          const criterionNumber =
            i === item.length - 1 ? String(index) : decimalValue;
          arr[index].push({
            ...o,
            i: generateGuid(),
            y: o.o.isLevel ? 0 : index * 2,
            x: o.o.isBlank || o.o.isCriteria ? 0 : n,
            o: {
              ...o.o,
              criterionNumber: criterionNumber,
            },
          });
          n++;
        });
      });
      this.layoutData = arr;
    } else {
      const newData: ILayoutItem[][] = this.layoutData.map(
        (item: ILayoutItem[], index: number) => {
          let num = item.length - 2;
          return item.map((o: ILayoutItem, i: number) => {
            const decimalValue = (index + num * 0.1).toFixed(1);
            num--;
            const criterionNumber =
              i === item.length - 1 ? String(index) : decimalValue;
            return {
              ...o,
              x: index,
              y: i * 2,
              i: generateGuid(),
              o: {
                ...o.o,
                criterionNumber: criterionNumber,
              },
            };
          });
        }
      );
      this.layoutData = newData;
    }

    this.savePoints();
    // this.toggleCellForm();
    this.toggleContentEditor();
  }

  handleCellUpdate(data: ILayoutItem) {
    this.selectedItem.o.color = this.selectedColor.hex;
    let arrayIndex = null;
    let dataIndex = null;
    this.layoutData.map((item: ILayoutItem[], index: number) => {
      item.map((o: ILayoutItem, key: number) => {
        if (o.i === data.i) {
          arrayIndex = index;
          dataIndex = key;
        }
      });
    });

    if (data.o.criterionResourceLink) {
      if (this.isInvalidLink()) {
        return;
      }
    }

    this.toggleContentEditor();

    if (arrayIndex === null) return;
    if (dataIndex === null) return;

    this.layoutData[arrayIndex][dataIndex] = data;
    this.savePoints();

    this.toggleCellForm();
  }

  handleUndoDelete(status: boolean, type: string) {
    this.redoLayout.push(this.layoutData);

    localStorage.setItem(
      StorageDataKey.RedoLayout,
      JSON.stringify(this.redoLayout)
    );

    const deletedLayout = localStorage.getItem(StorageDataKey.DeletedLayout);

    if (this.layoutData && status && deletedLayout) {
      let newData = Object.assign([], JSON.parse(deletedLayout));
      if (newData.length) {
        this.layoutData = newData[newData.length - 1];
        newData.pop();
        localStorage.setItem(
          StorageDataKey.DeletedLayout,
          JSON.stringify(newData)
        );
        this.deletedLayout = newData;
        this.savePoints();
      }
    }

    if (!type) {
      this.toggleContentEditor();
    }
  }

  isInvalidLink() {
    this.isNotLink = false;

    const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;

    if (!urlRegex.test(this.selectedItem.o.criterionResourceLink)) {
      this.isNotLink = false;
      if (this.selectedItem.o.criterionResourceLink) this.isNotLink = true;
    }

    return this.isNotLink;
  }

  savePoints() {
    if (this.criteriaSheet.criterionPoints) {
      this.layout = this.computePoints(
        this.layoutData,
        this.criteriaSheet.criterionPoints
      );
      localStorage.setItem(
        StorageDataKey.CriteriaSheet,
        JSON.stringify(this.layout)
      );
    }

    localStorage.setItem(
      StorageDataKey.CriteriaSheetConfig,
      JSON.stringify(this.criteriaSheet)
    );
    this.onFocusPoints = false;
  }

  computePoints(data: ILayoutItem[][], points: number) {
    if (data && data.length > 0) {
      const value = points;
      let totalScore = 0;
      data.map((item: ILayoutItem[], index: number) => {
        let max = points;
        let decimalVal = index + 0.1;
        item.reverse().map((o: ILayoutItem) => {
          if (o.o.isScorable) {
            if (value > -1) {
              if (
                this.criteriaSheet.criterionType === "Criterion with text only"
              ) {
                if (this.handleText(o) !== "") {
                  o.o.score = max;

                  if (
                    !this.criteriaSheet.scoringStyle ||
                    this.criteriaSheet.scoringStyle === "Holistic Scoring"
                  ) {
                    max += value;
                  } else {
                    max = value;
                  }

                  totalScore += value;

                  o.o.criterionNumber = decimalVal.toFixed(1);
                  decimalVal += 0.1;
                } else {
                  o.o.criterionNumber = "";
                  o.o.score = 0;
                }
              } else {
                o.o.score = max;

                if (
                  !this.criteriaSheet.scoringStyle ||
                  this.criteriaSheet.scoringStyle === "Holistic Scoring"
                ) {
                  max += value;
                } else {
                  max = value;
                }

                totalScore += value;

                // o.o.criterionNumber = "0";
                o.o.criterionNumber = decimalVal.toFixed(1);
                decimalVal += 0.1;
              }
            } else {
              o.o.score = 0;
            }
          }
          o.i = generateGuid();
        });
        item.reverse().map((o) => o);
      });

      this.criteriaSheet.totalScore = String(totalScore);
      this.criteriaSheet.gradesData[0].score = String(totalScore);
      return data.flat();
    } else {
      return data;
    }
  }

  handleCriteriaSheetBank() {
    const data = localStorage.getItem(StorageDataKey.CriteriaSheetBank);
    const dataBank = data ? JSON.parse(data) : [];
    dataBank.push({
      id: generateGuid(),
      template: this.layout,
      config: this.criteriaSheet,
    });

    localStorage.setItem(
      StorageDataKey.CriteriaSheetBank,
      JSON.stringify(dataBank)
    );
  }

  save() {
    localStorage.setItem(
      StorageDataKey.CriteriaSheet,
      JSON.stringify(this.layout)
    );
    localStorage.setItem(
      StorageDataKey.PointsMultiplier,
      JSON.stringify(this.points)
    );

    localStorage.setItem(
      StorageDataKey.CriteriaSheetConfig,
      JSON.stringify(this.criteriaSheet)
    );

    this.handleCriteriaSheetBank();

    if (!this.relative) {
      this.toggleCriteriaSheet();
    }

    alert("Criteria Sheet Saved!");
  }

  handleCalcPercentage(score: number, total: number) {
    const percentage = (score / total) * 100;
    return Number(percentage.toFixed(0));
  }

  handleSelectCell(item: ILayoutItem) {
    if (this.isViewOnly) return;
    if (!this.isMarking) return;
    if (!this.isEdittable) return;
    if (item.o.isLevel || item.o.isCriteria) return;
    if (!item.o.criterionNumber) return;
    if (
      !item.o.unscoredCriterionLabel &&
      item.o.criterionNumber === "0" &&
      item.o.score === 0
    )
      return;

    const isCriteria =
      this.criteriaSheet.layoutType?.toLocaleLowerCase() === "criteria";
    const cellType = isCriteria ? "y" : "x";
    const selectedIndex = this.layout.findIndex(
      (o: ILayoutItemRequired) => o.i === item.i
    );
    let totalScore = 0;
    const criterionPoint = this.criteriaSheet.criterionPoints || 0;

    let st: any = null;
    this.layout.map((obj: ILayoutItemRequired, index: number) => {
      if (selectedIndex === index) {
        // for half score implementation
        if (obj.o.isSelected) {
          obj = this.handleHalfScore(obj);
        } else {
          obj.o.isSelected = !obj.o.isSelected;
        }

        // obj.o.isSelected = !obj.o.isSelected;
      } else {
        if (obj[cellType] === item[cellType]) {
          if (
            !this.criteriaSheet.scoringStyle ||
            this.criteriaSheet.scoringStyle === "Holistic Scoring"
          ) {
            obj.o.isSelected = false;
            obj.o.isHalfScore = false;
          }
        }
      }
      if (obj.o.isSelected && obj.o.score) {
        if (obj.o.isHalfScore) {
          totalScore += obj.o.score - criterionPoint / 2;
        } else {
          totalScore += obj.o.score;
        }
      }
    });

    this.generatedScore = {
      score: totalScore,
      grade: this.handleGrades(totalScore) || "",
      totalScore: Number(this.criteriaSheet.totalScore || 0),
    };

    // const percentage = this.handleCalcPercentage(
    //   this.generatedScore.score,
    //   Number(this.criteriaSheet.totalScore)
    // );

    localStorage.setItem(
      StorageDataKey.CriteriaSheet,
      JSON.stringify(this.layout)
    );

    // this.$emit("generateScore", { ...this.generatedScore, percentage });
  }

  handleHalfScore(obj: ILayoutItemRequired) {
    if (obj.o.isScorable) {
      if (obj.o.isHalfScore) {
        obj.o.isSelected = false;
        obj.o.isHalfScore = false;
      } else {
        obj.o.isHalfScore = true;
      }
    } else {
      obj.o.isSelected = true;
    }
    return obj;
  }

  handleGenerateScore() {
    this.showGeneratedScore = !this.showGeneratedScore;
  }

  handleGrades(computedScore: number): string {
    const grades = this.criteriaSheet.gradesData;
    if (!computedScore || !Array.isArray(grades) || grades.length === 0)
      return "";

    let grade = "";
    for (const item of grades) {
      const score = parseInt(item.score);
      if (!isNaN(score) && computedScore >= score) {
        grade = item.grade;
        break;
      }
    }
    return grade;
  }

  handleMenuItem(index: number) {
    this.isMenuPopupVisible[index] = !this.isMenuPopupVisible[index];
    // menu.value.toggle(event);
  }

  handleAddCell(cellType: string) {
    if (this.activeLayout === "Criteria") {
      if (cellType === "Level") {
        //
        let newLayoutData: ILayoutItem[][] = [];

        this.layoutData.map((item: ILayoutItem[]) => {
          let arr: ILayoutItem[] = [];
          if (this.criteriaSheet.layoutValue) {
            for (let i = 0; i < this.criteriaSheet.layoutValue; i++) {
              const multiplier = this.criteriaSheet.layoutValue > 1 ? i + 1 : 1;
              const clonedFirstSubArray: ILayoutItem = {
                ...item[0],
                i: generateGuid(),
                o: {
                  ...item[0].o,
                  // text: "",
                  levelLabel: "",
                  criteriaLabel: "",
                  criterionLabel: "",
                  unscoredCriterionLabel:
                    item[0].o.unscoredCriterionLabel || "",
                  criterionNumber: item[0].o.criterionNumber
                    ? String(
                        (
                          parseFloat(item[0].o.criterionNumber) +
                          0.1 * multiplier
                        ).toFixed(1)
                      )
                    : "",
                },
              };
              arr.push(clonedFirstSubArray);
            }

            const newArray = [...arr, ...item];
            newLayoutData.push(newArray);
          }
        });
        this.layoutData = newLayoutData.map((item: ILayoutItem[]) => {
          return item.map((o: ILayoutItem, k: number) => {
            return {
              ...o,
              i: generateGuid(),
              x: o.o.isCriteria ? 0 : k + 1,
            };
          });
        });
      } else {
        //
        let newLayoutData: ILayoutItem[][] = [];
        if (this.criteriaSheet.layoutValue) {
          for (let i = 0; i < this.criteriaSheet.layoutValue; i++) {
            const clonedRow = this.layoutData[this.layoutData.length - 1];

            const updatedRow = clonedRow.map((o: ILayoutItem) => {
              return {
                ...o,
                o: {
                  ...o.o,
                  levelLabel: "",
                  criteriaLabel: "",
                  criterionLabel: "",
                  unscoredCriterionLabel: o.o.unscoredCriterionLabel || "",
                  criterionNumber: o.o.criterionNumber
                    ? String((parseFloat(o.o.criterionNumber) + 1).toFixed(1))
                    : "",
                },
              };
            });
            newLayoutData = [...this.layoutData, updatedRow];
          }

          this.layoutData = newLayoutData.map(
            (item: ILayoutItem[], index: number) => {
              return item.map((o: ILayoutItem) => {
                return {
                  ...o,
                  i: generateGuid(),
                  y: index * 2,
                };
              });
            }
          );
        }
      }
    } else {
      if (cellType === "Level") {
        let newLayoutData: TLayoutItems = [];
        this.layoutData.map((item: ILayoutItem[]) => {
          let arr = [];
          if (this.criteriaSheet.layoutValue) {
            for (let i = 0; i < this.criteriaSheet.layoutValue; i++) {
              const multiplier = this.criteriaSheet.layoutValue > 1 ? i + 1 : 1;
              const clonedFirstSubArray = {
                ...item[0],
                i: generateGuid(),
                o: {
                  ...item[0].o,
                  // text: "",
                  levelLabel: "",
                  criteriaLabel: "",
                  criterionLabel: "",
                  unscoredCriterionLabel: "",
                  criterionNumber: item[0].o.criterionNumber
                    ? String(
                        (
                          parseFloat(item[0].o.criterionNumber) +
                          0.1 * multiplier
                        ).toFixed(1)
                      )
                    : "",
                },
              };
              arr.push(clonedFirstSubArray);
            }

            const newArray = [...arr.reverse(), ...item];
            newLayoutData.push(newArray);
          }
        });
        this.layoutData = newLayoutData.map((item: ILayoutItem[]) => {
          return item.map((o: ILayoutItem, k: number) => {
            return {
              ...o,
              i: generateGuid(),
              y: (k *= 2),
            };
          });
        });
      } else {
        //
        let newArray: TLayoutItems = [];
        if (this.criteriaSheet.layoutValue) {
          for (let i = 0; i < this.criteriaSheet.layoutValue; i++) {
            const lastCol = this.layoutData[this.layoutData.length - 1];
            const multiplier = this.criteriaSheet.layoutValue > 1 ? i + 1 : 1;
            let num = lastCol.length - 2;
            const copiedArray = lastCol.map((obj: ILayoutItem, k: number) => {
              const decimalValue = (this.layoutData.length + num * 0.1).toFixed(
                1
              );
              num--;
              return {
                ...obj,
                x: lastCol[0].x + 1 * multiplier,
                i: generateGuid(),
                o: {
                  ...obj.o,
                  // text: obj.o.text !== "Not Shown" ? "" : "Not Shown",
                  levelLabel: "",
                  criteriaLabel: "",
                  criterionLabel: "",
                  unscoredCriterionLabel:
                    obj.o.criterionType === "unscoredCriterionLabel"
                      ? "Not Shown"
                      : "",
                  criterionNumber:
                    k === lastCol.length - 1
                      ? this.layoutData.length
                      : decimalValue,
                },
              };
            });

            const ca: ILayoutItemRequired[] =
              copiedArray as ILayoutItemRequired[];
            newArray.push(ca);
          }
        }

        this.layoutData = this.layoutData.concat(newArray);
      }
    }
    this.savePoints();
    this.isMenuPopupVisible[0] = false;
  }

  handleCell(event: Event, type: string, action: string) {
    event.preventDefault();
    if (action === "add") {
      this.handleAddCell(type);
    } else {
      this.handleChangeLayout(type);
    }
  }

  handleChangeLayout(type: string) {
    this.handleInitLayoutData(this.layout, this.criteriaSheet.layoutType);

    this.activeLayout = type;
    this.criteriaSheet.layoutType = type.toLocaleLowerCase();
    if (type === "Criteria") {
      //
      let newData: ILayoutItem[][] = [];
      this.layoutData.map((item: any, index: number) => {
        newData[index] = [];
        let x = 0;
        item.map((obj: ILayoutItem) => {
          if (obj.o.isBlank || obj.o.isCriteria) {
            x = 0;
          } else {
            x++;
          }
          newData[index].push({
            ...obj,
            i: generateGuid(),
            x: x,
            y: index * 2,
          });
        });
      });
      this.layoutData = newData;
      this.savePoints();
    } else {
      //
      let newData: ILayoutItem[][] = [];
      this.layoutData.map((item: any, index: number) => {
        newData[index] = [];
        item.map((obj: ILayoutItem, i: number) => {
          newData[index].push({
            ...obj,
            i: generateGuid(),
            x: index,
            y: i * 2,
          });
        });
      });
      this.layoutData = newData;
      this.savePoints();
    }
    this.deletedLayout = [];
    this.redoLayout = [];

    localStorage.setItem(StorageDataKey.DeletedLayout, JSON.stringify([]));
    localStorage.setItem(StorageDataKey.RedoLayout, JSON.stringify([]));
    this.isMenuPopupVisible[1] = false;
  }

  handleUndo() {
    //
    this.eventBus.emit("EVENT_TRIGGER", "CS014");
    this.handleUndoDelete(true, "direct");
  }

  handleRedo() {
    //
    if (this.redoLayout && this.redoLayout.length) {
      this.eventBus.emit("EVENT_TRIGGER", "CS015");
      if (this.redoLayout.length) {
        this.deletedLayout.push(this.layoutData);
        localStorage.setItem(
          StorageDataKey.DeletedLayout,
          JSON.stringify(this.deletedLayout)
        );
        this.layoutData = this.redoLayout[this.redoLayout.length - 1];

        this.redoLayout.pop();
        localStorage.setItem(
          StorageDataKey.RedoLayout,
          JSON.stringify(this.redoLayout)
        );
        this.savePoints();
      }
    }
  }

  handleText(item: ILayoutItem) {
    return (
      item.o.levelLabel ||
      item.o.criteriaLabel ||
      item.o.criterionLabel ||
      item.o.unscoredCriterionLabel
    );
  }

  renderText(item: ILayoutItem) {
    const text = this.handleText(item);
    return item.o.isBlank
      ? ""
      : item.o.isScorable || text
      ? text
      : !item.o.isScorable &&
        !item.o.isCriteria &&
        !item.o.isLevel &&
        !item.o.unscoredCriterionLabel
      ? "Not Shown"
      : "";
  }

  validateHttpLink(link: string) {
    if (!/^https?:\/\//i.test(link)) {
      return "https://" + link;
    }
    return link;
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.criteria-sheet {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 21;
  /* background-color: rgba(0, 0, 0, 0.5); */
  display: flex;
  align-items: center;
  justify-content: center;

  &.is-relative {
    background-color: transparent;
    position: relative;
    z-index: 1;
    padding: 0;
    .container {
      max-width: 100%;
      max-height: 100%;
    }

    .header {
      height: 40px;
    }
    .grid-content {
      margin: 40px auto;
    }
  }

  .container {
    max-width: 90%;
    max-height: 90%;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}
.content {
  width: 100%;
  .menu-wrap {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* padding: 15px; */
    background-color: #fcfcfc;
    position: stick;
    top: 80px;
    z-index: 4;
    .menu-items {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
    .save-button {
      button {
        width: 100px;
      }
    }
    .menu-item {
      position: relative;
      margin-right: 10px;
      button {
        /* width: 25px;
        height: 25px; */
        /* padding: 5px; */
        /* border: 1px solid #626262; */
        transition: 0.3s ease;

        .icon-table-pivot {
          transform: rotateX(180deg);
          transform-origin: center;
        }

        &.disabled {
          pointer-events: none;
          opacity: 0.5;
        }
        &:hover {
          background-color: #ccc;
        }
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

.option-menu {
  position: absolute;
  top: 0;
  left: calc(100% + 5px);
  z-index: 5;
  width: 100px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.48);

  &.option-layout {
    width: 150px;
  }
  a {
    padding: 10px;
    border-bottom: 1px solid #555;
    display: block;
    transition: background 0.3s ease;

    &.active {
      background-color: #b5b5b5;
    }
    &:hover {
      background-color: #ccc;
    }
    &:last-child {
      border: 0;
    }
  }
}
.grid-content {
  /* display: none; */
  width: 100%;
  /* margin: 40px auto 0; */
  overflow-x: auto;
}
.vgl-layout {
  width: 4800px;
  padding: 0;

  .cell {
    border: none !important;
    border-radius: 8px;
  }
}

:deep(.vgl-item:not(.vgl-item--placeholder)) {
  background-color: transparent;
}

:deep(.vgl-item--resizing) {
  opacity: 90%;
}

:deep(.vgl-item--static) {
  background-color: #cce;
}

.form-wrap {
  background-color: #fff;
  width: 100%;
  padding: 20px 15px 0;
  overflow: auto;
  max-height: 350px;
  &.form-grade {
    padding: 0;
  }
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #555;
  padding: 20px 15px;
  &:last-child {
    border: 0;
  }
  .col {
    width: 50%;
    display: flex;
    &.center {
      justify-content: center;
    }
    &:first-child {
      align-items: center;
      width: 40%;
    }
    &:last-child {
      width: 60%;
      align-items: center;
      display: flex;
    }
    padding: 0 15px;
    button {
      width: 14px;
      height: 15px;
      margin-left: 10px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .text-input {
      margin: 0;
      .input-field {
        margin-top: 0 !important;
        text-align: center;
      }
    }
  }
}
.header {
  height: 50px;
  /* border-bottom: 1px solid #000; */
  /* background-color: #ccc; */
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;

  button {
    &.btn-close {
      padding: 0;
      margin: 0;
      border: 0;
      width: 15px;
      height: 15px;
      background-color: transparent;
    }
    cursor: pointer;
    transition: 0.3s ease;
    &.btn-ellipsis {
      width: 25px;
      height: 25px;
      border: 1px solid rgb(85, 85, 85);
    }
    &:hover {
      opacity: 0.5;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }

  .btn-content-editor {
    margin-left: 10px;
  }

  .left-wrap {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    a {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin: 0px;
      height: 41px;
      padding: 0px 12px;
      position: relative;
      cursor: not-allowed;
      opacity: 0.5;
      &:after {
        content: "";
        display: block;
        position: absolute;
        right: 0px;
        top: 50%;
        height: 20px;
        width: 1px;
        transform: translateY(-50%);
        background-color: rgba(0, 0, 0, 0.25);
      }

      &:last-child {
        &:after {
          display: none;
        }
      }
      span {
        margin-left: 10px;
        display: block;
      }
    }
  }
  .right-wrap {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .point-field {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      label {
        margin-right: 10px;
        display: block;
      }
      input {
        width: 60px;
        padding: 0;
        text-align: center;
      }
      button {
        width: auto;
        height: auto;
        margin-left: 10px;
      }
    }
  }
  .col {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 20px;
    position: relative;
    .option-menu {
      top: calc(100% + 5px);
      left: auto;
      right: 0;
      width: 200px;
    }
  }
}

.footer {
  width: 100%;
  background-color: #fff;
  padding: 20px 15px;
  border-top: 1px solid #000;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  button {
    max-width: 100px;
  }
}

.cell {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  margin: auto;
  font-size: 14px;
  text-align: center;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: #bbdeec;
  flex-direction: column;
  cursor: pointer;

  .cell-bg {
    opacity: 0;
    transition: opacity 0.3s ease, clip-path 0.1s ease 0.3s;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    &.active {
      transition: opacity 0.3s ease, clip-path 0.3s ease;
      opacity: 1;
    }
    &.is-half {
      transition: clip-path 0.3s ease, opacity 0.3s ease;
      clip-path: polygon(0 100%, 100% 0, 100% 100%, 0% 100%);
    }
  }

  .cell-heading {
    width: 100%;
    position: absolute;
    top: 0;
  }
  .criterion-heading {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 8px;
    p {
      font-size: 10px;
      margin: 0;
      font-weight: 700;

      /* &.criterion-point {
        color: rgb(37, 192, 113);
      } */
    }
  }
  .criterion-description {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    padding: 20px 12px;
    width: 100%;
    text-align: left;
    p {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      line-height: 1.2;
    }
  }
  &.is-criteria {
    background-color: #c7ebda;
    font-weight: bold;
    cursor: initial;
  }
  &.is-level {
    background-color: #fccbdf;
    font-weight: bold;
    cursor: initial;
  }
  &.is-selected {
    background-color: #f9d4d0 !important;
  }
  &.is-blank {
    opacity: 0;
    pointer-events: none;
  }
  &:hover {
    .edit-wrap {
      opacity: 1;
    }

    .link-wrap {
      opacity: 1;
    }
  }

  .edit-wrap {
    position: absolute;
    right: 10px;
    bottom: 8px;
    opacity: 0;
    transition: 0.3s ease;
    z-index: 2;
    width: 14px;
    height: 14px;
    button {
      border: 0;
      background-color: transparent;
      padding: 0;
      margin: 0;
      cursor: pointer;
      width: 100%;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .link-wrap {
    position: absolute;
    bottom: 2px;
    left: 9px;
    opacity: 1;
    transition: 0.3s ease;
    z-index: 2;
    width: 18px;
    height: 18px;
    a {
      text-decoration: none;
      cursor: pointer;
      width: 100%;
      height: 100%;
      display: block;
      img,
      svg {
        width: 100%;
        height: 100%;
      }
    }
  }

  /* Sidebar */
  :deep(.p-component-overlay) {
    z-index: 0;
  }
}
</style>
