<template>
  <div
    v-show="selected === 1"
    class="flex items-center justify-center gap-4 bg-[#EAE5FF] rounded-lg h-full flex-col px-20"
  >
    <p class="text-flohh-text-body leading-[1.5] font-medium">
      Explore annotation tools, rubrics, and comment banks. Try Flohh’s demo
      assignment to get a feel for the marking screen without having to upload
      your own submissions or invite students.
    </p>
    <AppButton
      class="!bg-[#CEC3FF] hover:brightness-105 w-full"
      @click="markDemoSubmission"
      :disabled="loading || !demoUuid"
      :loading="loading || !demoUuid"
      >Try the marking screen</AppButton
    >
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-facing-decorator";
import emitter from "@/config/emitter";

import AppButton from "../../Layout/Buttons/AppButton.vue";
import AppTypographyText from "../../Layout/Typhography/AppTypographyText.vue";

@Component({
  components: {
    AppButton,
    AppTypographyText,
  },
})
export default class TaskMarkDemoAssignment extends Vue {
  eventBus = emitter;

  @Prop({
    type: Number,
    default: 1,
  })
  selected!: number;

  @Prop({
    type: String,
    default: "",
  })
  demoUuid!: string;

  loading = false;

  mounted() {
    this.eventBus.on("MARK_DEMO_ASSIGNMENT", () => {
      this.markDemoSubmission();
    });
  }

  markDemoSubmission() {
    this.$router.push({
      name: "Marking",
      params: { id: this.demoUuid },
    });
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
