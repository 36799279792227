import axios from "axios";

const BASE_URL = "/super-admin";
export default class SuperAdminService {
  downloadTeachersList() {
    return axios.get(`/reports/teachers`);
  }

  uploadTeachers(payload: any) {
    const formData = new FormData();
    formData.append("file", payload.file);
    formData.append("schoolName", payload.schoolName);
    formData.append("country", payload.country);
    formData.append("state", payload.state);
    return axios.post(`${BASE_URL}/teachers/bulk`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  putStudent(payload: any, studentUuid: string) {
    return axios.put(`${BASE_URL}/students/${studentUuid}`, payload);
  }

  getStudents() {
    return axios.get(`${BASE_URL}/students`);
  }

  getTeacherActiveSubscription(uuid: string) {
    return axios.get(`${BASE_URL}/teachers/subscription/active/${uuid}`);
  }
}
