import { ActionContext } from "vuex";
import { RootState } from "@/store";
import * as mutationType from "../mutation-types";
import { DashboardState } from "@/store/dashboard/dashboardTypes";
import axios, { AxiosResponse } from "axios";

export const SetPendingActionsAction = async (
  context: ActionContext<DashboardState, RootState>
) => {
  try {
    const authenticatedAs = localStorage.getItem("authenticatedAs");

    const response: AxiosResponse = await axios.get(
      `/session?role=${authenticatedAs}`
    );
    if (response.data.ok) {
      context.commit(mutationType.SET_PENDING_ACTIONS, response.data.data);
    }
  } catch (error) {
    console.error(error);
  }
};
