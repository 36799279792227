<script lang="ts">
import { Vue, Component, Model, Prop, Watch } from "vue-facing-decorator";
import { icons as AppIcons } from "@/utils/icons";
import UserMenu from "./UserMenu.vue";
import { ProfileDetails, getProfileDetails } from "@/config/auth";
import { PendingActions } from "@/store/dashboard/dashboardTypes";

@Component({ components: { UserMenu } })
export default class TopNavigation extends Vue {
  @Prop({
    type: Boolean,
    required: false,
  })
  isSuperAdmin!: boolean;

  @Model({
    type: Boolean,
    default: false,
    name: "sidebarOpen",
  })
  sidebarOpenModel!: boolean;

  @Model({
    type: Boolean,
    default: false,
    name: "hideNavigation",
  })
  hideNavigationModel!: boolean;

  @Prop({
    type: Array,
    required: true,
  })
  pendingActions!: PendingActions[];

  @Prop({
    type: Object,
    required: true,
  })
  profile!: ProfileDetails;

  icons = AppIcons;
  role!: string;

  isStudent = false;

  mounted() {
    const authenticatedAs = localStorage.getItem("authenticatedAs");
    if (authenticatedAs) {
      this.isStudent = authenticatedAs === "student";
      this.role = authenticatedAs;
    }
  }
}
</script>
<template>
  <div
    class="sticky top-0 z-[50] flex h-[4.3rem] shrink-0 items-center gap-x-4 border-b border-gray-200 bg-flohh-neutral-100 px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8"
  >
    <div class="flex self-stretch flex-1 gap-x-4 lg:gap-x-6">
      <div class="flex items-center justify-start flex-1 gap-x-2">
        <div class="flex items-center justify-between w-full">
          <div id="top_navigation_title"></div>
          <div id="top_navigation_buttons_teleport" class="flex"></div>
        </div>
      </div>
      <div class="flex items-center gap-x-4 lg:gap-x-4">
        <div v-if="profile && isStudent">
          Hey {{ profile.profile.firstName }}!
        </div>
        <UserMenu
          v-if="profile"
          :isSuperAdmin="isSuperAdmin"
          :role="role"
          :userInfo="profile"
          :pendingActions="pendingActions"
        />
      </div>
    </div>
  </div>
</template>
