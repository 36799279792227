<template>
  <ModalUtility
    v-model="open"
    title="Welcome to Flohh!"
    width="90vw"
    class="max-w-[1100px]"
    @onClose="handleModalClose"
  >
    <template #content>
      <div class="p-[1.5em] flex gap-10 flex-col xl:flex-row xl:gap-4">
        <div class="flex flex-col gap-6 xl:w-[45%]">
          <p class="font-flohh-font-bold text-flohh-font-title">
            {{ firstName }}, ready to streamline your assessment process?
          </p>
          <p class="text-flohh-font-title">
            Our founder, Ange Alcock, is a high school English teacher who
            experienced the same time-consuming challenges you have. Spending
            hours and hours on weekends assessing student work. She designed
            Flohh to solve these challenges for herself and other teachers just
            like you.
          </p>
          <p class="text-flohh-font-title">
            Here is a quick video from her running through the best way to get
            the most out of Flohh.
          </p>
          <p class="text-flohh-font-title">
            Once you’ve taken a look, jump straight into a demo assignment to
            try out the marking screen.
          </p>
        </div>
        <div class="xl:w-[55%]">
          <OnboardingVideoComponent />
        </div>
      </div>
    </template>
    <template #footer
      ><div class="flex justify-end pt-6">
        <AppButton
          label="Try marking screen"
          iconLeft="arrowForwardBlackIcon"
          type="submit"
          @click="markDemoAssignment"
        /></div></template
  ></ModalUtility>
</template>

<script lang="ts">
import { Component, Vue, Model, Prop } from "vue-facing-decorator";
import emitter from "@/config/emitter";

import ModalUtility from "@/components/utilities/ModalUtility.vue";
import OnboardingVideoComponent from "./OnboardingVideoComponent.vue";
import AppButton from "../Layout/Buttons/AppButton.vue";

@Component({
  components: { ModalUtility, OnboardingVideoComponent, AppButton },
})
export default class UpdatesModal extends Vue {
  eventBus = emitter;

  @Model({
    type: Boolean,
    default: false,
    required: true,
  })
  open!: boolean;

  @Prop({
    type: String,
    required: true,
  })
  firstName!: string;

  markDemoAssignment() {
    this.eventBus.emit("EVENT_TRIGGER", "TD004");
    localStorage.setItem("welcomeModalOpened", "true");
    this.eventBus.emit("MARK_DEMO_ASSIGNMENT");
  }

  handleModalClose() {
    this.eventBus.emit("EVENT_TRIGGER", "TD004");
    localStorage.setItem("welcomeModalOpened", "true");
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
