<template>
  <div
    v-show="selected === 3"
    class="flex items-center justify-center gap-4 bg-[#EAE5FF] rounded-lg h-full flex-col px-20"
  >
    <p class="text-flohh-text-body leading-[1.5] font-medium">
      Make the most of Flohh’s time-saving annotating tool, give great feedback
      and engage your students. <strong>Hint:</strong> don’t forget to update
      the status of your submissions to ‘marked’ once you’ve completed them.
    </p>
    <AppButton
      class="!bg-[#CEC3FF] hover:brightness-105 w-full"
      @click="handleStartMarking"
      :disabled="loading || !demoUuid"
      :loading="loading || !demoUuid"
      >Start marking</AppButton
    >
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-facing-decorator";
import { AssignmentDashboard } from "@/store/dashboard/dashboardTypes";
import { PendingActions } from "@/store/dashboard/dashboardTypes";

import AppButton from "../../Layout/Buttons/AppButton.vue";

@Component({
  components: {
    AppButton,
  },
})
export default class TaskMarkupSubmissions extends Vue {
  @Prop({
    type: Number,
    default: 1,
  })
  selected!: number;

  @Prop({
    type: Array,
    required: true,
  })
  assignments!: AssignmentDashboard[];

  @Prop({
    type: Array,
    required: true,
  })
  onboardingChecklist: PendingActions[] = [];

  @Prop({
    type: String,
    default: "",
  })
  demoUuid!: string;

  loading = false;
  submissionUuid = "";

  @Watch("demoUuid")
  demoUuidWatcher() {
    this.getSubmissions();
  }

  getSubmissions() {
    const task = this.onboardingChecklist.find(
      (item: PendingActions) => item.type === "onboardingMarkSubmission"
    );

    if (task) {
      const uploadedSubmissions = task.data.uploadedSubmissions;
      const submissions = task.data.submissions;
      if (uploadedSubmissions) {
        this.getNonDemoSubmission(uploadedSubmissions);
        return;
      }

      if (submissions) {
        this.getNonDemoSubmission(submissions);
        return;
      }
    }
  }

  getNonDemoSubmission<T>(submissionUuids: T) {
    const uuids = submissionUuids as string[];
    const submissionUuid = uuids.find((item: string) => item !== this.demoUuid);

    if (submissionUuid) {
      this.submissionUuid = submissionUuid;
    } else {
      this.submissionUuid = uuids[0];
    }
  }

  handleStartMarking() {
    this.$router.push({
      name: "Marking",
      params: { id: this.submissionUuid },
    });
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
