<template>
  <div class="gradient-bg">
    <div class="doodle-bg"></div>
  </div>
  <div
    :class="
      hasSidebar ? 'main-page-container-with-sidebar' : 'main-page-container'
    "
  >
    <slot></slot>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-facing-decorator";

@Component({
  components: {},
})
export default class PageViewContainer extends Vue {
  @Prop({
    type: Boolean,
    default: false,
  })
  hasSidebar!: boolean;
}
</script>

<style scoped lang="scss">
body {
  margin: 0;
  overflow: hidden !important;
}
.main-page-container {
  padding: 16px;
}
.main-page-container-with-sidebar {
  padding-left: 16px;
}
.gradient-bg {
  background-image: url("../../../assets/bg-gradient.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
}
.doodle-bg {
  background-image: url("../../../assets/bg-doodle.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
}
</style>
