import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0e27175d"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "px-0 pt-[16px] h-full"
}
const _hoisted_2 = {
  role: "list",
  class: "flex flex-1 flex-col gap-[2px] w-full relative mb-[32px]"
}
const _hoisted_3 = ["onClick", "onMouseenter", "onMouseleave"]
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = ["id", "onMouseenter", "onMouseleave"]
const _hoisted_7 = { class: "p-[8px] border border-flohh-neutral-85 border-solid rounded-lg" }
const _hoisted_8 = ["id", "onMouseenter", "onMouseleave"]
const _hoisted_9 = { class: "flex flex-col gap-1 p-[8px] border border-flohh-neutral-85 border-solid rounded-lg" }
const _hoisted_10 = {
  key: 0,
  class: "w-full relative overflow-hidden"
}
const _hoisted_11 = ["innerHTML"]
const _hoisted_12 = {
  role: "list",
  class: "flex flex-1 flex-col gap-[2px] w-full relative"
}
const _hoisted_13 = { class: "w-full relative overflow-hidden" }
const _hoisted_14 = ["innerHTML"]
const _hoisted_15 = { class: "w-full relative overflow-hidden" }
const _hoisted_16 = ["innerHTML"]
const _hoisted_17 = {
  key: 1,
  class: "flex flex-col items-center gap-[32px] px-0 pt-[16px]"
}
const _hoisted_18 = {
  role: "list",
  class: "flex flex-1 flex-col gap-[2px] w-full relative"
}
const _hoisted_19 = ["onClick"]
const _hoisted_20 = ["innerHTML"]
const _hoisted_21 = ["id"]
const _hoisted_22 = ["id"]
const _hoisted_23 = {
  key: 0,
  class: "w-full relative"
}
const _hoisted_24 = ["innerHTML"]
const _hoisted_25 = { class: "flex flex-1 flex-col gap-[2px] w-full" }
const _hoisted_26 = { class: "w-full relative" }
const _hoisted_27 = ["innerHTML"]
const _hoisted_28 = { class: "w-full relative" }
const _hoisted_29 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavigationClassList = _resolveComponent("NavigationClassList")!
  const _component_router_link = _resolveComponent("router-link")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_ctx.expanded)
    ? (_openBlock(), _createElementBlock("nav", _hoisted_1, [
        _createElementVNode("ul", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.navigation, (navItem, i) => {
            return (_openBlock(), _createElementBlock("li", {
              key: i,
              class: "w-full relative"
            }, [
              (!navItem.isHidden)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass([
            navItem.path === _ctx.$route.path ||
            _ctx.navItemHovered === navItem.name ||
            _ctx.selectedNavItem === navItem.name
              ? 'bg-flohh-primary-pink'
              : 'text-gray-700 hover:text-gray-600 hover:bg-flohh-primary-pink',
            'cursor-pointer w-full group flex gap-x-3 items-center rounded-md py-[6px] px-[12px] font-flohh-font-medium text-flohh-text-body',
          ]),
                    onClick: ($event: any) => (_ctx.handleNavigate(navItem.route, {}, {})),
                    onMouseenter: ($event: any) => (_ctx.handleHoverNavItem($event, i, navItem.name)),
                    onMouseleave: ($event: any) => (_ctx.handleUnhoverNavItem($event, i))
                  }, [
                    _createElementVNode("span", {
                      innerHTML: navItem.icon,
                      "aria-hidden": "true",
                      class: "[&>svg]:w-[24px] [&>svg]:h-[auto]"
                    }, null, 8, _hoisted_4),
                    _createTextVNode(" " + _toDisplayString(navItem.name) + " ", 1),
                    (
              (navItem.children && navItem.children.length !== 0) ||
              navItem.name === 'Classes'
            )
                      ? (_openBlock(), _createElementBlock("span", {
                          key: 0,
                          innerHTML: _ctx.icons.dropdownIconBlack,
                          "aria-hidden": "true",
                          class: "ml-auto"
                        }, null, 8, _hoisted_5))
                      : _createCommentVNode("", true)
                  ], 42, _hoisted_3))
                : _createCommentVNode("", true),
              (navItem.name === 'Classes')
                ? (_openBlock(), _createElementBlock("div", {
                    key: 1,
                    class: "bg-white animate-height absolute rounded-bl-lg rounded-br-lg w-full",
                    id: `child-${i}`,
                    onMouseenter: ($event: any) => (_ctx.handleHoverNavItem($event, i, navItem.name)),
                    onMouseleave: ($event: any) => (_ctx.handleUnhoverNavItem($event, i))
                  }, [
                    _createElementVNode("div", _hoisted_7, [
                      _createVNode(_component_NavigationClassList, { onOnClassSelect: _ctx.handleClassSelect }, null, 8, ["onOnClassSelect"])
                    ])
                  ], 40, _hoisted_6))
                : (navItem.children.length > 0)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 2,
                      class: "bg-white animate-height absolute rounded-bl-lg rounded-br-lg w-full",
                      id: `child-${i}`,
                      onMouseenter: ($event: any) => (_ctx.handleHoverNavItem($event, i, navItem.name)),
                      onMouseleave: ($event: any) => (_ctx.handleUnhoverNavItem($event, i))
                    }, [
                      _createElementVNode("div", _hoisted_9, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(navItem.children, (subNav, index) => {
                          return (_openBlock(), _createBlock(_component_router_link, {
                            to: subNav.path,
                            class: _normalizeClass([
                subNav.path === _ctx.$route.path
                  ? 'bg-flohh-primary-pink rounded-[8px]'
                  : 'text-black-700 hover:text-black-600 hover:bg-flohh-primary-pink rounded-[8px]',
                'cursor-pointer group flex gap-x-3 justify-start px-[10px] py-[10px] items-center text-sm font-flohh-font-medium text-flohh-text-caption',
              ]),
                            key: index,
                            onClick: ($event: any) => (_ctx.handleSelected(subNav.parent))
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(subNav.name), 1)
                            ]),
                            _: 2
                          }, 1032, ["to", "class", "onClick"]))
                        }), 128))
                      ])
                    ], 40, _hoisted_8))
                  : _createCommentVNode("", true)
            ]))
          }), 128)),
          (_ctx.showCredits && _ctx.isTeacher)
            ? (_openBlock(), _createElementBlock("li", _hoisted_10, [
                _withDirectives((_openBlock(), _createElementBlock("div", {
                  class: _normalizeClass([
            'text-gray-700 hover:text-gray-600 hover:bg-flohh-primary-pink',
            'text-nowrap cursor-pointer w-full group flex gap-x-3 items-center rounded-md py-[6px] px-[12px] font-flohh-font-medium text-flohh-text-body capitalize',
          ]),
                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openCredit && _ctx.openCredit(...args)))
                }, [
                  _createElementVNode("span", {
                    innerHTML: _ctx.icons.iconCoin,
                    "aria-hidden": "true"
                  }, null, 8, _hoisted_11),
                  _createTextVNode(" " + _toDisplayString(_ctx.credits.available) + " Credits Remaining ", 1)
                ])), [
                  [_directive_tooltip, {
            value: `${
              _ctx.credits.available === 'unlimited'
                ? 'Unlimited'
                : _ctx.credits.available
            } Credits Remaining`,
            autoHide: false,
          }]
                ])
              ]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("ul", _hoisted_12, [
          _createElementVNode("li", _hoisted_13, [
            _withDirectives((_openBlock(), _createElementBlock("div", {
              class: _normalizeClass([
            _ctx.$route.path === '/help'
              ? 'bg-flohh-primary-pink'
              : 'text-gray-700 hover:text-gray-600 hover:bg-flohh-primary-pink',
            'cursor-pointer w-full group flex items-center gap-[15px] rounded-md py-[6px] px-[14px] font-flohh-font-medium text-flohh-text-body whitespace-nowrap min-h-[37px]',
          ]),
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.redirectToHelpPage && _ctx.redirectToHelpPage(...args)))
            }, [
              _createElementVNode("span", {
                innerHTML: _ctx.icons.navHelpBlack,
                "aria-hidden": "true"
              }, null, 8, _hoisted_14),
              _createTextVNode(" Help Center ")
            ], 2)), [
              [_directive_tooltip, {
            value: 'Help Center',
            autoHide: false,
          }]
            ])
          ]),
          _createElementVNode("li", _hoisted_15, [
            _withDirectives((_openBlock(), _createElementBlock("div", {
              class: _normalizeClass([
            _ctx.$route.path === '/app/version'
              ? 'bg-flohh-primary-pink'
              : 'text-gray-700 hover:text-gray-600 hover:bg-flohh-primary-pink',
            'cursor-pointer w-full group flex items-center gap-[15px] rounded-md py-[6px] px-[14px] font-flohh-font-medium text-flohh-text-body whitespace-nowrap min-h-[37px]',
          ]),
              onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.redirectToAppInfoPage && _ctx.redirectToAppInfoPage(...args)))
            }, [
              _createElementVNode("span", {
                innerHTML: _ctx.icons.navInfoCircleBlack
              }, null, 8, _hoisted_16),
              _createTextVNode("About App ")
            ], 2)), [
              [_directive_tooltip, {
            value: 'About App',
            autoHide: false,
          }]
            ])
          ])
        ])
      ]))
    : (_openBlock(), _createElementBlock("nav", _hoisted_17, [
        _createElementVNode("ul", _hoisted_18, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.navigation, (navItem, i) => {
            return (_openBlock(), _createElementBlock("li", {
              key: i,
              class: "w-full relative"
            }, [
              (!navItem.isHidden)
                ? _withDirectives((_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass([
            navItem.path === _ctx.$route.path ||
            _ctx.navItemHovered === navItem.name ||
            _ctx.selectedNavItem === navItem.name
              ? 'bg-flohh-primary-pink'
              : 'hover:bg-flohh-primary-pink',
            'cursor-pointer w-full group flex justify-start py-[6px] px-[12px] rounded-lg',
          ]),
                    onClick: 
            ($event) => {
              if (navItem.name === 'Classes' || navItem.children.length > 0) {
                _ctx.handleToggleNavItems($event, i, navItem.name);
                return;
              }
              _ctx.handleNavigate(navItem.route, {}, {});
            }
          ,
                    id: "menu-item"
                  }, [
                    _createElementVNode("span", {
                      innerHTML: navItem.icon,
                      "aria-hidden": "true",
                      class: "[&>svg]:w-[24px] [&>svg]:h-[auto]"
                    }, null, 8, _hoisted_20)
                  ], 10, _hoisted_19)), [
                    [_directive_tooltip, {
            value: navItem.name,
            autoHide: false,
          }]
                  ])
                : _createCommentVNode("", true),
              (navItem.name === 'Classes')
                ? (_openBlock(), _createElementBlock("div", {
                    key: 1,
                    class: "flex flex-col gap-1 p-2 bg-white w-[200px] h-auto fixed ml-[50px] border border-flohh-neutral-85 border-solid rounded-lg hidden-sub-menu",
                    id: `child-${i}`
                  }, [
                    (_ctx.navItemHovered === navItem.name)
                      ? (_openBlock(), _createBlock(_component_NavigationClassList, {
                          key: 0,
                          onOnClassSelect: _ctx.handleClassSelect
                        }, null, 8, ["onOnClassSelect"]))
                      : _createCommentVNode("", true)
                  ], 8, _hoisted_21))
                : (navItem.children.length > 0)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 2,
                      class: "flex flex-col gap-2 p-2 bg-white w-[200px] h-auto fixed ml-[50px] border border-flohh-neutral-85 border-solid rounded-lg hidden-sub-menu",
                      id: `child-${i}`
                    }, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(navItem.children, (subNav, index) => {
                        return (_openBlock(), _createBlock(_component_router_link, {
                          to: subNav.path,
                          class: _normalizeClass([
              subNav.path === _ctx.$route.path
                ? 'bg-flohh-primary-pink rounded-[8px]'
                : 'text-black-700 hover:text-white hover:bg-flohh-primary-pink',
              'cursor-pointer group flex gap-x-3 justify-start p-[10px] items-center text-sm font-flohh-font-medium text-flohh-text-caption rounded-lg',
            ]),
                          key: index,
                          onClick: ($event: any) => (_ctx.handleSelected(subNav.parent))
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(subNav.name), 1)
                          ]),
                          _: 2
                        }, 1032, ["to", "class", "onClick"]))
                      }), 128))
                    ], 8, _hoisted_22))
                  : _createCommentVNode("", true)
            ]))
          }), 128)),
          (_ctx.showCredits && _ctx.isTeacher)
            ? (_openBlock(), _createElementBlock("li", _hoisted_23, [
                _withDirectives((_openBlock(), _createElementBlock("div", {
                  class: _normalizeClass([
            'cursor-pointer w-full group flex justify-start py-[6px] px-[12px] rounded-lg capitalize hover:bg-flohh-primary-pink min-h-[37px]',
          ]),
                  onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.openCredit && _ctx.openCredit(...args)))
                }, [
                  _createElementVNode("span", {
                    innerHTML: _ctx.icons.iconCoin,
                    "aria-hidden": "true"
                  }, null, 8, _hoisted_24)
                ])), [
                  [_directive_tooltip, {
            value: `${
              _ctx.credits.available === 'unlimited'
                ? 'Unlimited'
                : _ctx.credits.available
            } Credits Remaining`,
            autoHide: false,
          }]
                ])
              ]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("ul", _hoisted_25, [
          _createElementVNode("li", _hoisted_26, [
            _withDirectives((_openBlock(), _createElementBlock("div", {
              class: _normalizeClass([
            _ctx.$route.path === '/help' ? 'bg-flohh-primary-pink' : '',
            'cursor-pointer hover:bg-flohh-primary-pink flex rounded-lg justify-start items-center py-[6px] px-[14px] p-0 min-h-[37px]',
          ]),
              onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.redirectToHelpPage && _ctx.redirectToHelpPage(...args)))
            }, [
              _createElementVNode("span", {
                innerHTML: _ctx.icons.navHelpBlack,
                "aria-hidden": "true"
              }, null, 8, _hoisted_27)
            ], 2)), [
              [_directive_tooltip, {
            value: 'Help Center',
            autoHide: false,
          }]
            ])
          ]),
          _createElementVNode("li", _hoisted_28, [
            _withDirectives((_openBlock(), _createElementBlock("div", {
              class: _normalizeClass([
            _ctx.$route.path === '/app/version' ? 'bg-flohh-primary-pink' : '',
            'cursor-pointer hover:bg-flohh-primary-pink flex rounded-lg justify-start items-center py-[6px] px-[14px] p-0 min-h-[37px]',
          ]),
              onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.redirectToAppInfoPage && _ctx.redirectToAppInfoPage(...args)))
            }, [
              _createElementVNode("span", {
                innerHTML: _ctx.icons.navInfoCircleBlack
              }, null, 8, _hoisted_29)
            ], 2)), [
              [_directive_tooltip, {
            value: 'About App',
            autoHide: false,
          }]
            ])
          ])
        ])
      ]))
}