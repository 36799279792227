<script lang="ts">
import { Vue, Component, Model, Emit, Prop } from "vue-facing-decorator";
import ModalUtility from "../../utilities/ModalUtility.vue";
import AppButton from "@/components/Layout/Buttons/AppButton.vue";
import { Icon, icons } from "@/utils/icons";
import ClassInformation from "../ClassInformation.vue";
import FormField from "@/components/Layout/Forms/FormField.vue";
import AddStudentsSelection from "./AddStudentsSelection.vue";
import Toast from "primevue/toast";
import emitter from "@/config/emitter";

type Selection = {
  name: string;
  type: string;
  image: string;
  current: boolean;
  imagePosition: string;
};

@Component({
  components: {
    ModalUtility,
    AppButton,
    ClassInformation,
    FormField,
    AddStudentsSelection,
    Toast,
  },
})
export default class AddStudentsForClassPopup extends Vue {
  @Model({
    type: Boolean,
    default: false,
  })
  openDialog!: boolean;
  eventBus = emitter;

  @Prop({
    type: Boolean,
    default: false,
  })
  isEdit!: boolean;

  icon: Icon = icons;

  selections: Selection[] = [
    // remove for now for MVP
    {
      name: "Import Students from Google Classroom",
      type: "google",
      image: "rectangle-green.svg",
      current: false,
      imagePosition: "br",
    },
    {
      name: "Invite with a class link",
      type: "link",
      image: "rectangle-pink.svg",
      current: false,
      imagePosition: "br",
    },
    {
      name: "Upload a CSV file",
      type: "upload",
      image: "rectangle-red.svg",
      current: false,
      imagePosition: "tl",
    },
    {
      name: "Add information manually",
      type: "manual",
      image: "rectangle-blue.svg",
      current: false,
      imagePosition: "tl",
    },
  ];

  @Emit("onSkipOrGoBack")
  handleOnSkipOrGoBackEmit(index: number) {
    return index;
  }

  @Emit("onClickSave")
  handleOnClickSaveEmit() {
    return;
  }

  handleCloseCreateClass() {
    this.$store.dispatch("createClassPopup/resetClass");
  }

  handleClickSelection(selected: Selection) {
    this.selections = this.selections.map((selection) => {
      if (selection.type === selected.type) {
        selection.current = true;
      } else {
        selection.current = false;
      }

      return selection;
    });

    this.$store.dispatch("createClassPopup/setType", selected.type);
  }

  handleSaveSelectTypeAddingOfStudent() {
    this.handleOnClickSaveEmit();
  }

  handleClickSkipOrGoBack(stepIndex = 0) {
    this.handleOnSkipOrGoBackEmit(stepIndex);
  }

  unmounted() {
    this.selections = this.selections.map((select) => {
      select.current = false;
      return select;
    });
  }
}
</script>
<template>
  <ModalUtility
    v-model="openDialog"
    width="50vw"
    title="Add your students"
    subtitle="Select one of the options below to add students to your class"
    @onClose="handleCloseCreateClass"
  >
    <template #content>
      <div class="px-4">
        <div class="py-2 mt-5">
          <FormField label="Class Information">
            <ClassInformation />
          </FormField>
        </div>
        <div class="w-full">
          <FormField label="Add your students" subLabel="(Optional)">
            <AddStudentsSelection
              :selections="selections"
              @onClickSelection="handleClickSelection"
            />
          </FormField>
        </div>
      </div>
    </template>
    <template #footer>
      <div
        class="flex flex-row pt-5 border-t border-solid border-flohh-neutral-85"
      >
        <div class="flex-1 flex justify-start items-center">
          <AppButton text @click="handleClickSkipOrGoBack()" v-if="!isEdit">
            Go Back
          </AppButton>
        </div>
        <div class="flex-1 flex justify-end items-center gap-x-4">
          <AppButton text @click="handleClickSkipOrGoBack(3)" v-if="!isEdit">
            Skip this step
          </AppButton>
          <AppButton type="submit" @click="handleSaveSelectTypeAddingOfStudent">
            <template #icon_left>
              <span v-html="icon.checkBlack"></span>
            </template>
            Save
          </AppButton>
        </div>
      </div>
    </template>
  </ModalUtility>
</template>
<style lang="scss" scoped>
.p-dialog .p-dialog-footer button {
  margin: 0 !important;
}
</style>
