<script lang="ts">
import { Vue, Component, Prop, Emit } from "vue-facing-decorator";
import AppButton from "../../Layout/Buttons/AppButton.vue";
import { icons as AppIcons } from "@/utils/icons";
import CreateClassPopup from "@/components/CreateClassPopup/CreateClassPopup.vue";
import AddStudentsForClassPopup from "@/components/CreateClassPopup/AddStudents/AddStudentsForClassPopup.vue";
import ManualAddStudentPopup from "@/components/CreateClassPopup/ManualAddStudentPopup.vue";
import CreateClassUploadPopup from "@/components/CreateClassPopup/CreateClassUploadPopup.vue";
import CreateClassLinkPopup from "@/components/CreateClassPopup/CreateClassLinkPopup.vue";
import CreateClassGooglePopup from "@/components/CreateClassPopup/CreateClassGooglePopup.vue";
import Toast from "primevue/toast";
import { useToast } from "primevue/usetoast";
import AuthService from "@/services/AuthService";
import ClassService from "@/services/ClassService";
import { Class } from "@/models/Class";
import emitter from "@/config/emitter";

@Component({
  components: {
    AppButton,
    CreateClassPopup,
    AddStudentsForClassPopup,
    ManualAddStudentPopup,
    CreateClassUploadPopup,
    CreateClassLinkPopup,
    CreateClassGooglePopup,
    Toast,
  },
})
export default class CreateClassButton extends Vue {
  private authService: AuthService = new AuthService();
  private classService: ClassService = new ClassService();
  @Prop({
    type: String,
    default: "submit",
  })
  type!: string;

  @Prop({
    type: String,
    default: "plusBlack",
  })
  icon!: string;

  @Prop({
    type: Boolean,
    default: false,
  })
  isIcon!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  isEdit!: boolean;

  eventBus = emitter;
  openClassDialog = false;
  icons = AppIcons;
  openClassAddStudentDialog = false;
  openAddStudentManuallyDialog = false;
  openUploadDialog = false;
  openLinkDialog = false;
  openGoogleDialog = false;
  toast = useToast();

  @Emit("onSaveCreateClass")
  handleOnCreateClassEmit() {
    return;
  }

  mounted() {
    //
    let urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get("token")) {
      this.openGoogleDialog = true;
    }
  }

  handleOnCreateClass() {
    this.openClassDialog = false;
    this.openClassAddStudentDialog = true;
    if (this.isEdit) {
      // Edit event
    }
    this.handleOnCreateClassEmit();
  }

  handleSkipOrGoBack(index: number) {
    this.openClassAddStudentDialog = false;
    if (index === 0) {
      this.openClassDialog = true;
      return;
    }
    this.handleRedirectClassPage();
  }

  handleSkipOrGobackAddManually(index: number) {
    if (index === 0) {
      this.openAddStudentManuallyDialog = false;
      this.openClassAddStudentDialog = true;
      return;
    }
    this.handleRedirectClassPage();
  }

  handleRedirectClassPage() {
    let assignment = "";
    if (this.$route.query && this.$route.query.assignment) {
      assignment = this.$route.query.assignment as string;
    }
    if (
      this.$store.state.createClassPopup.classInfo &&
      this.$store.state.createClassPopup.classInfo.uuid
    ) {
      if (assignment) {
        this.$router.push({
          name: "Classes",
          params: {
            id: this.$store.state.createClassPopup.classInfo.uuid,
          },
        });
        return;
      }
      this.$router.push({
        name: "Classes",
        params: { id: this.$store.state.createClassPopup.classInfo.uuid },
      });
      this.openClassAddStudentDialog = false;
      this.openAddStudentManuallyDialog = false;
      this.openUploadDialog = false;
      this.openLinkDialog = false;
      this.openGoogleDialog = false;
    }
  }

  handleOnSaveStudent() {
    this.openAddStudentManuallyDialog = false;
    this.toast.add({
      severity: "success",
      summary: "Add Students",
      detail: `Import students successfully`,
      life: 3000,
    });
    this.resetStore();
  }

  handleSkipOrGoBackUpload(index: number) {
    if (index === 0) {
      this.openUploadDialog = false;
      this.openClassAddStudentDialog = true;
      return;
    }
    this.handleRedirectClassPage();
  }

  handleOnSaveFileUploadStudent() {
    this.toast.add({
      severity: "success",
      summary: "Add Students",
      detail: `Import students successfully`,
      life: 3000,
    });
    this.resetStore();
  }

  handleOnSaveGoogleImport(data: any) {
    this.openGoogleDialog = false;
    this.toast.add({
      severity: data.status,
      summary: "Add Students",
      detail: data.message,
      life: 3000,
    });
    this.resetStore();
  }

  handleCloseSkipOrGoBackLink(index: number) {
    if (index === 0) {
      this.openLinkDialog = false;
      this.openClassAddStudentDialog = true;
      return;
    }
    this.handleRedirectClassPage();
  }

  handleCloseSkipOrGoBackGoogle(index: number) {
    if (index === 0) {
      this.openGoogleDialog = false;
      this.openClassAddStudentDialog = true;
      return;
    }
    this.handleRedirectClassPage();
  }

  handleClickOnSave() {
    if (this.$store.state?.createClassPopup.type === "manual") {
      this.openClassAddStudentDialog = false;
      this.openAddStudentManuallyDialog = true;
    }
    if (this.$store.state?.createClassPopup.type === "upload") {
      this.openClassAddStudentDialog = false;
      this.openUploadDialog = true;
    }
    if (this.$store.state?.createClassPopup.type === "link") {
      this.openClassAddStudentDialog = false;
      this.openLinkDialog = true;
    }
    if (this.$store.state?.createClassPopup.type === "google") {
      this.handleGoogleClassroom();

      // this.openGoogleDialog = true;
    }
  }

  async handleGoogleClassroom() {
    //
    const path = window.location.origin + window.location.pathname;
    const successRedirectUrl = path;
    const failedRedirectUrl = path;
    const auth = localStorage.getItem("auth");
    if (path && auth) {
      //
      const parsedAuth = JSON.parse(auth);
      // const res = this.classService.getGoogleClassroom(parsedAuth.accessToken);
      const res = await this.authService.getIdentifcationToken();

      if (res?.data.ok) {
        const identificationToken = res.data.data.token;
        const googleUrl = `${process.env.VUE_APP_API_URL}/google-classroom/authenticate?token=${identificationToken}&successRedirectUrl=${successRedirectUrl}&failedRedirectUrl=${failedRedirectUrl}`;
        window.location.href = googleUrl;
      }
    }
    // this.openGoogleDialog = true;
  }

  resetStore() {
    localStorage.removeItem("ClassInfo");
    this.$store.dispatch("createClassPopup/resetClass");
  }

  handleClickClass() {
    this.openClassDialog = true;
    this.resetStore();
  }
}
</script>
<template>
  <slot :handleClickClass="handleClickClass">
    <AppButton
      :type="type"
      @click="handleClickClass"
      v-tooltip.top="{
        value: 'Create a new class',
        showDelay: 500,
      }"
    >
      <span v-html="icons[icon]"></span>
      <template v-if="!isIcon">Class</template>
    </AppButton>
  </slot>
  <CreateClassPopup
    v-model="openClassDialog"
    @onCreateClass="handleOnCreateClass"
    :isEdit="isEdit"
  />
  <AddStudentsForClassPopup
    v-model="openClassAddStudentDialog"
    @onSkipOrGoBack="handleSkipOrGoBack"
    @onClickSave="handleClickOnSave"
  />
  <ManualAddStudentPopup
    v-model="openAddStudentManuallyDialog"
    @onSkipOrGoBack="handleSkipOrGobackAddManually"
    @onSaveAddStudentManually="handleOnSaveStudent"
  />
  <CreateClassUploadPopup
    v-model="openUploadDialog"
    @onSkipOrGoBack="handleSkipOrGoBackUpload"
    @onSaveFileUploadStudent="handleOnSaveFileUploadStudent"
  />
  <CreateClassLinkPopup
    v-model="openLinkDialog"
    @onCloseSkipOrGoBack="handleCloseSkipOrGoBackLink"
  />
  <CreateClassGooglePopup
    v-if="openGoogleDialog"
    v-model="openGoogleDialog"
    @onCloseSkipOrGoBack="handleCloseSkipOrGoBackGoogle"
    @onSaveGoogleImport="handleOnSaveGoogleImport"
  />
</template>
