<template>
  <div class="help-center-container">
    <div class="section">
      <div class="container">
        <h1 class="text-flohh-h1">Product Help Page</h1>
        <section>
          <h2 class="text-flohh-h2">How can we help?</h2>
          <p>
            At Flohh, we’re all about making grading
            <strong>faster, easier, and more effective</strong> for teachers.
            Our mission is to reduce your workload and help your students
            achieve better outcomes—all with a product you’ll love.
          </p>
          <p>
            We’re here to ensure your experience is
            <strong>smooth, enjoyable, and productive</strong>, with support
            every step of the way.
          </p>
        </section>
        <hr class="my-4" />
        <section>
          <h2 class="text-flohh-h2">Quick Links to Get You Started</h2>
          <p>
            Here are some of the most popular resources to help you hit the
            ground running:
          </p>
          <p>
            Need help getting started? Check out our
            <a
              href="https://www.flohh.com.au/docs-category/getting-started/"
              target="_blank"
            >
              getting started guide</a
            >:
          </p>
          <br />
          <div class="flex">
            <div class="flex flex-col w-[50%]">
              <h3 class="mb-1 font-flohh-h3">Getting Started</h3>
              <ul class="list-disc pl-[1.5em]">
                <li v-for="(faq, i) in GETTING_STARTED" :key="i">
                  <a :href="faq.link" target="_blank">{{ faq.title }}</a>
                </li>
              </ul>
            </div>
            <div class="flex flex-col w-[50%]">
              <h3 class="mb-1 font-flohh-h3">Getting Started</h3>
              <ul class="list-disc pl-[1.5em]">
                <li v-for="(faq, i) in ANNOTATIONS_AND_FEEDBACK" :key="i">
                  <a :href="faq.link" target="_blank">{{ faq.title }}</a>
                </li>
              </ul>
            </div>
          </div>
          <br />
          <div class="flex">
            <div class="flex flex-col w-[50%]">
              <h3 class="mb-1 font-flohh-h3">Organising Your Workflow</h3>
              <ul class="list-disc pl-[1.5em]">
                <li v-for="(faq, i) in ORGANISING_YOUR_WORKFLOW" :key="i">
                  <a :href="faq.link" target="_blank">{{ faq.title }}</a>
                </li>
              </ul>
            </div>
            <div class="flex flex-col w-[50%]">
              <h3 class="mb-1 font-flohh-h3">Gradebook and Advanced Tools</h3>
              <ul class="list-disc pl-[1.5em]">
                <li v-for="(faq, i) in GRADEBOOK_AND_ADVANCED_TOOLS" :key="i">
                  <a :href="faq.link" target="_blank">{{ faq.title }}</a>
                </li>
              </ul>
            </div>
          </div>
          <br />
          <p>
            Can’t find what you’re looking for? Explore more guides and
            resources in our
            <a
              href="https://www.flohh.com.au/articles/"
              target="_blank"
              class="font-flohh-font-bold"
              >Help Center</a
            >.
          </p>
        </section>
        <hr class="my-4" />
        <section>
          <h2 class="text-flohh-h2">Need Help? Chat With Us</h2>
          <p>
            Have a question or need assistance? Our friendly, Melbourne-based
            support team is just a click away. Use the
            <strong>on-screen chat</strong> at the bottom left of your screen to
            message us directly.
          </p>
          <br />
          <h3 class="mb-1 font-flohh-h3">When Are We Available?</h3>
          <ul class="list-disc pl-[1.5em]">
            <li><strong>Monday-Friday:</strong> 8am-8pm AEST</li>
            <li>
              <strong>Weekends:</strong> Flexible hours (we’ll let you know when
              we’re online!)
            </li>
            <li>
              If you contact us outside these hours, we’ll respond via email as
              soon as we’re back online.
            </li>
          </ul>
          <br />
          <h3 class="mb-1 font-flohh-h3">Still Have Questions?</h3>
          <p>
            Reach out to us anytime at
            <a href="mailto: support@flohh.com.au" target="_blank"
              >support@flohh.com.au</a
            >. We’re available 7 days a week and always happy to help.
          </p>
        </section>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";
import {
  GETTING_STARTED,
  ANNOTATIONS_AND_FEEDBACK,
  ORGANISING_YOUR_WORKFLOW,
  GRADEBOOK_AND_ADVANCED_TOOLS,
} from "./data";

@Component({
  components: {},
})
export default class HelpCenterComponent extends Vue {
  GETTING_STARTED = GETTING_STARTED;
  ANNOTATIONS_AND_FEEDBACK = ANNOTATIONS_AND_FEEDBACK;
  ORGANISING_YOUR_WORKFLOW = ORGANISING_YOUR_WORKFLOW;
  GRADEBOOK_AND_ADVANCED_TOOLS = GRADEBOOK_AND_ADVANCED_TOOLS;

  mounted() {
    //
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.help-center-container {
  width: 100%;
  margin: 0 auto;
  padding: 0 0 0;
  background-color: #ffffff;
  border-radius: 8px;

  h1 {
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  h2 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  h3 {
    font-weight: bold;
    font-size: 21px;
    margin-bottom: 20px;
    text-align: left;
  }

  a {
    text-decoration: underline;
    color: #1458cdce;
    cursor: pointer;

    &:hover {
      filter: brightness(100%);
    }
  }

  .content {
    text-align: left;
    background-color: #fbd1ba;
    padding: 30px 0;
    &.bg-pink {
      background-color: #f1c9d5;
    }
  }

  .section {
    padding: 2.5em;
    text-align: left;
    .container {
      background-color: #fff;
      padding: 30px;
      border-radius: 8px;
      h4 {
        font-size: 18px;
        font-weight: bold;
      }
    }
    p {
      margin-bottom: 7px;
    }
    ul {
      li {
        margin-bottom: 7px;
      }
    }
  }
}
</style>
