<template>
  <div class="flex flex-col gap-3 bg-white">
    <div class="flex gap-6">
      <div class="flex-col gap-3 w-[30%] min-w-[320px]">
        <h6
          class="text-flohh-text-title font-flohh-font-bold text-flohh-neutral-20"
        >
          Let's make Flohh work for you
        </h6>
        <p class="text-flohh-text-body text-flohh-neutral-20">
          Follow these steps to get the most out of Flohh
        </p>
      </div>
      <div class="flex gap-2 items-end w-[70%]">
        <div class="bg-flohh-neutral-85 rounded-lg w-full h-2">
          <div
            class="h-full bg-flohh-secondary-green-dark rounded-lg"
            :style="{ width: `${(totalCompleted / checklist.length) * 100}%` }"
          ></div>
        </div>
        <p class="text-flohh-text-small">
          {{ totalCompleted }}/{{ checklist.length }}
        </p>
      </div>
    </div>
    <div class="flex gap-6">
      <div class="flex flex-col gap-3 w-[30%] min-w-[320px]">
        <button
          v-for="(item, index) in checklist"
          :key="index"
          @click="selected = item.id"
          :class="[
            {
              'bg-[#EAE5FF]': selected === item.id,
              'cursor-not-allowed bg-flohh-neutral-85': item.disabled,
              'hover:bg-flohh-tertiary-purple': !item.disabled,
            },
            'px-3 py-4 flex gap-4 rounded-lg border border-solid border-flohh-neutral-85 items-center',
          ]"
          :disabled="item.disabled"
        >
          <span v-if="item.completed" v-html="icons.checklistChecked" />
          <span v-else v-html="icons.checklistUnchecked" />
          <p
            class="font-flohh-font-bold text-flohh-text-subtitle text-flohh-neutral-20"
          >
            {{ item.id }}. {{ item.name }}
          </p>
        </button>
      </div>
      <div class="w-[70%]">
        <TaskMarkDemoAssignment :selected="selected" :demoUuid="demoUuid" />
        <TaskUploadSubmission :selected="selected" />
        <TaskMarkupSubmissions
          :selected="selected"
          :assignments="assignments"
          :onboardingChecklist="onboardingChecklist"
          :demoUuid="demoUuid"
        />
        <TaskReturnFeedback
          :selected="selected"
          :assignments="assignments"
          :onboardingChecklist="onboardingChecklist"
          :demoUuid="demoUuid"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-facing-decorator";
import { icons } from "@/utils/icons";
import { PendingActions } from "@/store/dashboard/dashboardTypes";
import { AssignmentDashboard } from "@/store/dashboard/dashboardTypes";
import SubmissionService from "@/services/SubmissionService";
import { AxiosResponse } from "axios";

import AppTypographyText from "../Layout/Typhography/AppTypographyText.vue";
import AppButton from "../Layout/Buttons/AppButton.vue";
import TaskMarkDemoAssignment from "./OnboardingTaskCards/TaskMarkDemoAssignment.vue";
import TaskMarkupSubmissions from "./OnboardingTaskCards/TaskMarkupSubmissions.vue";
import TaskReturnFeedback from "./OnboardingTaskCards/TaskReturnFeedback.vue";
import TaskUploadSubmission from "./OnboardingTaskCards/TaskUploadSubmission.vue";
import ProgressLoader from "../utilities/ProgressLoader.vue";

export interface OnboardingChecklist {
  id: number;
  name: string;
  type: string;
  completed: boolean;
  disabled: boolean;
  status: string;
}

@Component({
  components: {
    AppButton,
    AppTypographyText,
    TaskMarkDemoAssignment,
    TaskMarkupSubmissions,
    TaskReturnFeedback,
    TaskUploadSubmission,
    ProgressLoader,
  },
})
export default class OnboardingTasksComponent extends Vue {
  private submissionService = new SubmissionService();
  icons = icons;

  @Prop({
    type: Array,
    required: true,
  })
  onboardingChecklist!: PendingActions[];

  @Prop({
    type: Array,
    required: true,
  })
  assignments!: AssignmentDashboard[];

  selected = 0;
  totalCompleted = 0;
  demoUuid = "";

  checklist: OnboardingChecklist[] = [
    {
      id: 1,
      completed: false,
      name: "Try the Marking Screen",
      type: "onboardingDemo",
      disabled: false,
      status: "pending",
    },
    {
      id: 2,
      completed: false,
      name: "Upload Your Submissions",
      type: "onboardingUploadSubmission",
      disabled: false,
      status: "pending",
    },
    {
      id: 3,
      completed: false,
      name: "Mark Up Your Submissions",
      type: "onboardingMarkSubmission",
      disabled: false,
      status: "pending",
    },
    {
      id: 4,
      completed: false,
      name: "Return Your Feedback",
      type: "onboardingReturnSubmission",
      disabled: false,
      status: "pending",
    },
  ];

  mounted() {
    this.getDemoSubmission();
    this.handleComposeChecklist();
    this.handleGetCompletedCount();
  }

  async getDemoSubmission() {
    try {
      const response: AxiosResponse =
        await this.submissionService.getDemoSubmission();

      if (response.data.ok) {
        this.demoUuid = response.data.data.uuid;
      }
    } catch (e) {
      console.error(e);
    }
  }

  handleComposeChecklist() {
    const checklist: OnboardingChecklist[] = [];
    this.checklist.forEach((item: OnboardingChecklist, index: number) => {
      const checklistItem = item;
      const exist = this.onboardingChecklist.find(
        (obj: PendingActions) => obj.type === item.type
      );
      if (!exist) {
        checklistItem.completed = true;
      } else {
        if (exist.status === "fulfilled") {
          checklistItem.completed = true;
        }
      }

      if (item.id === 3) {
        if (checklist[1].completed) {
          checklistItem.disabled = false;
        } else {
          checklistItem.disabled = true;
        }
      }

      if (item.id === 4) {
        if (checklist[1].completed && checklist[2].completed) {
          checklistItem.disabled = false;
        } else {
          checklistItem.disabled = true;
        }
      }

      if (!this.selected && !checklistItem.completed) {
        this.selected = index + 1;
      }

      checklist.push(checklistItem);
    });
    this.checklist = checklist;
  }

  handleGetCompletedCount() {
    const completedTask = this.checklist.filter(
      (item: OnboardingChecklist) => item.completed
    );
    this.totalCompleted = completedTask.length;
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
