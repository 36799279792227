import axios, { AxiosResponse } from "axios";

export const version = {
  releaseDate: "04/02/2025",
  commit: "b50da0ef9af58ff2ab29ff3ec60f3b3b0ede8cdc",
  environment: process.env.VUE_APP_NODE_ENV,
  number: "support/2501.0.1",
};

export const getAPIVersion = async () => {
  try {
    const appURL = process.env.VUE_APP_API_URL;
    const splitURL = appURL ? appURL.split("/api/v1").join("") : "";
    const response = await fetch(`${splitURL}/version`);

    if (response) {
      return await response.json();
    }
  } catch (err) {
    console.error(err);
  }
};
