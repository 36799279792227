<template>
  <PageTitle title="Help Center" :routes="routes" />
  <PageViewContainer>
    <HelpCenterComponent />
  </PageViewContainer>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";
import HelpCenterComponent from "@/components/HelpCenter/HelpCenterComponent.vue";
import PageTitle, { TRoute } from "@/components/Layout/Partials/PageTitle.vue";
import PageViewContainer from "@/components/Layout/Partials/PageViewContainer.vue";

@Component({
  components: {
    HelpCenterComponent,
    PageTitle,
    PageViewContainer,
  },
})
export default class HelpCenterView extends Vue {
  routes: TRoute[] = [
    {
      title: "",
      routeTo: "/help",
      current: false,
    },
  ];
  /**
   * mounted
   */
  public mounted() {
    //
  }

  /**
   * destroyed
   */
  public destroyed() {
    //
  }
}
</script>

<style>
body {
  margin: 0;
}
</style>
