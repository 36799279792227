<template>
  <Dialog
    v-model:visible="visible"
    modal
    header="Header"
    :style="{ width: '600px' }"
    :pt="{
      header: {
        class: '!p-0',
      },
      content: {
        class: '!px-5 !pb-0',
      },
      footer: {
        class: '!px-3',
      },
      closeButton: {
        class: '!hidden',
      },
    }"
  >
    <template #header>
      <section
        class="w-full h-[76px] bg-flohh-primary-pink rounded-t-[4px] sm:py-0 flex flex-col justify-center items-start bg-[url('@/assets/modal-header-bg.png')] bg-repeat bg-[length:100%_auto] z-[1]"
      >
        <div class="flex justify-between items-center h-full w-full px-5">
          <h1
            class="text-flohh-h6 font-flohh-font-bold flex items-center gap-2"
          >
            {{ title ? title : "Content Editor" }}
            <span
              v-if="title === 'Grades'"
              class="cursor-pointer"
              v-html="icons.infoCircleBlack"
              v-tooltip.bottom="{
                escape: true,
                value: `<div><p>The grades settings can be used to automatically calculate the grades for each student. <br>
                        To set up your grade calculation you simply enter the lowest score for each grade range next to the name for the grade. <br>
                        You should enter the highest grades at the top and the lowest grades at the bottom. </p>
                        <p class='leading-3'>E.g. <br>
                        10 Very High  <br>
                        8 High <br>
                        6 Medium  <br>
                        4 Low <br>
                        0 Very Low</p></div>`,
                pt: {
                  text: `w-[430px]`,
                },
              }"
            ></span>
          </h1>
          <div class="flex items-center gap-6">
            <div v-if="contentEditorType === 'cell'" class="pt-1">
              <button
                v-if="selectedItem.o.isCriteria || selectedItem.o.isLevel"
                @click="handleDeleteCell"
              >
                <img
                  src="@/assets/table-icons/icon-trash.svg"
                  class="w-[13px]"
                />
              </button>
            </div>
            <div
              v-if="
                contentEditorType === 'grade' && hasGradeSchema && !isEditted
              "
              class="pt-1 gap-[8px] flex justify-end"
            >
              <AppButton
                type="default"
                @click="handleCopy"
                v-tooltip.bottom="{
                  value: 'Make a copy',
                  showDelay: 500,
                }"
              >
                <span v-html="icons.copyContent"></span>
              </AppButton>
              <AppButton
                type="default"
                @click="handleEdit"
                v-tooltip.bottom="{
                  value: !isEdittable
                    ? 'This grade schema is attached to a criteria sheet and cannot be updated.'
                    : 'Edit',
                  showDelay: 500,
                }"
                :disabled="!isEdittable"
                :class="!isEdittable ? 'opacity-[0.7]' : ''"
              >
                <span v-html="icons.pencilBlack"></span>
              </AppButton>
            </div>
            <button @click="close" class="hover:opacity-80">
              <span v-html="icons.closeBlack"></span>
            </button>
          </div>
        </div>
      </section>
    </template>

    <slot
      name="cell"
      :onInput="onInput"
      :data="data"
      :isRequired="isRequired"
    />

    <slot
      name="name"
      :onInput="onInput"
      :data="data"
      :isRequired="isRequired"
    />

    <slot name="totalScore" :onChange="onChange" :data="data"></slot>

    <slot
      name="grade"
      :data="data"
      :grades="grades"
      :handleScore="handleScore"
      :handleGrade="handleGrade"
      :handleDelete="handleDelete"
      :isGradeDisabled="isGradeDisabled"
    />

    <!-- This code not implemented in default before relayout -->
    <div class="form-wrap" v-if="contentEditorType === 'layout'">
      <div class="right-wrap">
        <button class="btn-ellipsis" @click="toggleOption()">
          <img src="@/assets/icon-ellipsis-vert.svg" />
        </button>
        <div class="option-menu" v-if="isOptionVisible">
          <a href="#" @click="handleUndoDelete">Undo</a>
          <a
            href="#"
            @click="
              (e) => {
                e.preventDefault();
                close();
              }
            "
            >Close</a
          >
        </div>
      </div>
      <div class="row">
        <SelectComponent
          name="layoutType"
          :label="'What would you like to add?'"
          :value="data ? data.layoutType : 'Level'"
          @change="onChange($event, 'layoutType')"
          :options="['Level', 'Criteria']"
        />
      </div>
      <div class="row">
        <SelectComponent
          name="layoutValue"
          :label="'How many would you like to add?'"
          :value="data ? data.layoutType : 'Level'"
          @change="onChange($event, 'layoutValue')"
          :options="[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
        />
      </div>
      <div class="row" v-if="selectedItem">
        <SelectComponent
          name="insertPosition"
          :label="'Where do you want to add?'"
          :value="data ? data.layoutType : 'Level'"
          @change="onChange($event, 'layoutValue')"
          :options="['Insert Before', 'Insert After']"
        />
      </div>
    </div>
    <!-- end -->
    <Divider v-if="!previewMode" />

    <template #footer v-if="!previewMode">
      <div
        class="flex items-center w-full"
        :class="
          contentEditorType === 'grade' ? 'justify-between' : 'justify-end'
        "
      >
        <div class="pt-4" v-if="contentEditorType === 'grade'">
          <GradeSchemaListModal
            v-model="gradeSchemaModalOpen"
            :handleGradeSchemaData="handleGradeSchemaData"
            :currentCriteriaSheet="gradeSchemaBankData"
            :handleEditGradeSchema="handleEditGradeSchema"
            :totalScore="data.totalScore"
            :isMarkingScreen="true"
            :isRubricBank="isRubricBank"
            @handleSaveCriteria="handleSaveCriteria"
          />
        </div>
        <div class="flex justify-end pt-4">
          <AppButton
            v-if="contentEditorType === 'grade' && !isGradeDisabled"
            v-bind="$attrs"
            type="submit"
            @click="handleRow"
            :disabled="isGradeDisabled"
            :class="isGradeDisabled ? 'opacity-[0.5]' : ''"
          >
            <span v-html="icons.plusBlack" class="mr-2"></span>
            Add grade
          </AppButton>

          <AppButton type="submit" v-bind="$attrs" @click="save">
            <template #icon_left>
              <span v-html="icons.checkBlack" class="pr-2"></span>
            </template>
            {{
              isGradeDisabled && gradeSchemaData.uuid === "" ? "Attach" : "Save"
            }}
          </AppButton>
        </div>
      </div>
    </template>
  </Dialog>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-facing-decorator";
import TextInputComponent from "@/components/Input/TextInput/TextInputComponent.vue";
import SelectComponent from "@/components/Input/Select/SelectComponent.vue";
import ButtonComponent from "@/components/Button/ButtonComponent.vue";
import { ILayoutItemRequired, TGrade } from "./types";
import AppButton from "../Layout/Buttons/AppButton.vue";
import Dialog from "primevue/dialog";
import Divider from "primevue/divider";
import { icons as AppIcons } from "@/utils/icons";
import ModalUtility from "../utilities/ModalUtility.vue";
import { ICriteriaSheet } from "@/models/CriteriaSheet";
import emitter from "@/config/emitter";
import GradeSchemaListModal from "../Assignments/utilities/GradeSchemaListModal.vue";
import { useToast } from "primevue/usetoast";
interface BankData {
  uuid: string;
  name: string;
}
@Component({
  components: {
    TextInputComponent,
    SelectComponent,
    ButtonComponent,
    AppButton,
    Dialog,
    Divider,
    ModalUtility,
    GradeSchemaListModal,
  },
})
export default class ContentEditorComponent extends Vue {
  toast = useToast();
  icons = AppIcons;
  eventBus = emitter;
  @Prop({
    type: Function as () => void,
    required: false,
  })
  close!: () => void;

  @Prop({
    type: Boolean,
    required: true,
  })
  previewMode!: boolean;

  @Prop({
    type: Boolean,
    required: true,
  })
  isContentEditorOpen!: boolean;

  @Prop({
    type: Boolean,
    required: true,
  })
  isTotalScoreOpen!: boolean;

  @Prop({
    type: Boolean,
    required: true,
  })
  isGradesOpen!: boolean;

  @Prop({
    type: Boolean,
    required: true,
  })
  isRubricBank!: boolean;

  // @Prop({
  //   type: Boolean,
  //   required: false,
  // })
  // isGradeDisabled!: boolean;

  // open = isTotalScoreOpen;

  @Prop({
    type: String,
    required: true,
  })
  contentEditorType!: string;

  @Prop({
    type: Boolean,
    required: true,
  })
  visible!: boolean;

  @Prop({
    type: String,
    required: true,
  })
  title!: string;

  @Prop({
    type: Object,
    required: true,
  })
  data!: ICriteriaSheet;

  @Prop({
    type: Boolean,
  })
  gradeEditable = false;

  @Prop({
    type: Boolean,
  })
  gradeEdited = false;

  @Prop({
    type: Object,
    required: true,
  })
  selectedItem!: ILayoutItemRequired;

  text!: string;

  grades: TGrade = [
    {
      score: "",
      grade: "",
    },
    {
      score: "",
      grade: "",
    },
    {
      score: "",
      grade: "",
    },
    {
      score: "",
      grade: "",
    },
  ];

  isOptionVisible = false;
  isRequired = true;
  open = false;

  gradeSchemaModalOpen = false;
  gradeSchemaBankData: BankData = {
    uuid: "",
    name: "",
  };
  gradeSchemaEdit = false;
  gradeSchemaPreview = false;
  gradeSchemaData = {
    uuid: "",
  };
  showGradeSchemaTemplateOpen = false;
  hasGradeSchema = false;
  isGradeDisabled = false;

  isEdittable = false;
  isEditted = false;

  handleSaveCriteria() {
    this.$emit("saveCriteriaSheet", false, true);
  }

  handleDeleteCell() {
    // e.preventDefault();
    this.$emit("delete", this.selectedItem);
    // this.toggleOption();
  }

  mounted() {
    if (this.data.gradesData) {
      this.grades = this.data.gradesData;
    } else {
      this.grades[0].score = this.data.totalScore;
    }

    console.log(this);

    if (this.data.gradeSchemaUuid) {
      this.hasGradeSchema = true;
      this.isGradeDisabled = true;
      this.gradeSchemaData.uuid = this.data.gradeSchemaUuid || "";
      this.isEdittable = this.gradeEditable;
    }

    if (this.gradeEdited) {
      this.hasGradeSchema = true;
      this.isEditted = true;
      this.isGradeDisabled = false;
    }
  }

  onInput(event: Event) {
    const evt = event.target as HTMLInputElement;
    if (this.contentEditorType !== "cell") {
      this.data[this.contentEditorType] = evt.value;
    } else {
      if (this.selectedItem.o.criterionType) {
        this.selectedItem.o[this.selectedItem.o.criterionType] = evt.value;
      }
    }
  }

  onChange(event: Event, type: string) {
    const evt = event.target as HTMLInputElement;
    if (this.data[type] !== null) {
      this.data[type] =
        type === "criterionPoints" ? parseInt(evt.value) : evt.value;
    }
  }

  handleCopy() {
    //
    this.hasGradeSchema = false;
    this.isGradeDisabled = false;
    this.data.gradeSchemaUuid = "";
  }

  handleEdit() {
    this.isGradeDisabled = false;
    this.isEditted = true;
    this.$emit("handleGradeEdited", true);
  }

  save() {
    if (this.contentEditorType === "cell") {
      this.eventBus.emit("EVENT_TRIGGER", "CS011");
      this.$emit("cellUpdate", this.selectedItem);
    } else {
      if (this.contentEditorType === "grade") {
        this.eventBus.emit("EVENT_TRIGGER", "CS007");
        if (!this.data.gradeSchemaName) {
          this.showToast("Please provide grade schema name", "error");
          return false;
        }
      }

      // this.gradeSchemaData.uuid = this.data.gradeSchemaUuid || "";

      console.log(this.isEdittable);
      this.$emit("update", this.data, this.isEditted);

      if (this.contentEditorType === "name") {
        this.eventBus.emit("EVENT_TRIGGER", "CS004");
        this.$emit("getSheetName", this.data.name);
      }
    }
  }

  handleScore(event: Event, index: number) {
    //
    const input = event.target as HTMLInputElement;
    this.grades[index].score = input.value;
    this.data.gradesData = this.grades;
  }

  handleGrade(event: Event, index: number) {
    //
    const input = event.target as HTMLInputElement;
    this.grades[index].grade = input.value;
    this.data.gradesData = this.grades;
  }

  handleRow() {
    this.eventBus.emit("EVENT_TRIGGER", "CS006");
    this.grades.push({
      score: "",
      grade: "",
    });
  }

  handleDelete(index: number) {
    this.eventBus.emit("EVENT_TRIGGER", "CS008");
    this.grades.splice(index, 1);
  }

  handleUndoDelete(e: Event) {
    e.preventDefault();
    this.$emit("undo", true);
    this.toggleOption();
  }

  toggleOption() {
    this.isOptionVisible = !this.isOptionVisible;
  }

  handleGradeSchemaData(uuid: string, template: any) {
    //
    // this.gradeSchemaData.uuid = uuid;
    // this.showGradeSchemaTemplateOpen = uuid !== "";
    // this.gradeSchemaPreview = isPreview;
    if (template) {
      this.grades = template.schema.map((o: any) => {
        return {
          score: o.minimumScore,
          grade: o.label,
        };
      });
      this.data.gradesData = this.grades;
      this.data.gradeSchemaName = template.name;
      this.isEdittable = template.editable;
      this.$emit("handleGradeEditable", template.editable);
      console.log(template);
      console.log(this.isEdittable);
    }
    this.data.gradeSchemaUuid = uuid;
    this.gradeSchemaModalOpen = false;
    this.hasGradeSchema = true;
    this.isGradeDisabled = true;

    this.gradeSchemaData.uuid = "";
    this.isEditted = false;
    this.$emit("handleGradeEdited", false);

    // this.grade
  }

  handleEditGradeSchema(uuid: string) {
    this.gradeSchemaEdit = true;
    this.gradeSchemaPreview = false;
    this.handleGradeSchemaData(uuid, false);
  }

  showToast(
    message: string,
    severity: "success" | "info" | "warn" | "error" | undefined,
    duration = 3000
  ) {
    this.toast.add({
      severity: severity,
      detail: message,
      life: duration,
    });
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.content-editor {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 21;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  .container {
    max-width: 600px;
    max-height: 90%;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #555;
    padding: 20px 15px;
    &:last-child {
      border: 0;
    }
    .col {
      width: 50%;
      display: flex;
      &.center {
        justify-content: center;
      }
      &:first-child {
        align-items: center;
        width: 40%;
      }
      &:last-child {
        width: 60%;
        align-items: center;
        display: flex;
      }
      padding: 0 15px;
      button {
        width: 14px;
        height: 15px;
        margin-left: 10px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .text-input {
        margin: 0;
        .input-field {
          margin-top: 0 !important;
          text-align: center;
        }
      }
    }
  }
  /* .header {
    height: 80px;
    border-bottom: 1px solid #000;
    background-color: #ccc;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;
    position: relative;
    h1 {
      font-weight: bold;
    }
    button {
      padding: 0;
      margin: 0;
      border: 0;
      width: 15px;
      height: 15px;
      background-color: transparent;
      cursor: pointer;
      transition: 0.3s ease;
      &:hover {
        opacity: 0.5;
      }
      img {
        width: 100%;
        height: 100%;
      }
    }
  } */
  .right-wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    .btn-ellipsis {
      width: 25px;
      height: 25px;
      margin-right: 0;
    }
  }
  .option-menu {
    position: absolute;
    top: calc(100% - 20px);
    right: 10px;
    width: 100px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.48);
    a {
      padding: 10px;
      border-bottom: 1px solid #555;
      display: block;
      transition: background 0.3s ease;
      &:hover {
        background-color: #ccc;
      }
      &:last-child {
        border: 0;
      }
    }
  }
  .form-wrap {
    background-color: #fff;
    width: 100%;
    padding: 20px 15px 0;
    overflow: auto;
    max-height: 350px;
    &.form-grade {
      padding: 0;
    }
  }

  .footer {
    width: 100%;
    background-color: #fff;
    padding: 20px 15px;
    border-top: 1px solid #000;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    &.space-between {
      justify-content: space-between;
    }
    button {
      max-width: 100px;
    }
  }
}
</style>
