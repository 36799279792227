import { EventDetail, EventName } from "../type";

const markingScreenEvents = (eventName: EventName): EventDetail[] => [
  {
    id: "MS001",
    name: eventName.button,
    description: "Filter annotations",
  },
  {
    id: "MS002",
    name: eventName.button,
    description: "Delete annotation",
  },
  {
    id: "MS003",
    name: eventName.button,
    description: "Edit annotation bank",
  },
  {
    id: "MS004",
    name: eventName.button,
    description: "Add annotation",
  },
  {
    id: "MS005",
    name: eventName.button,
    description: "Save annotation bank",
  },
  {
    id: "MS006",
    name: eventName.button,
    description: "Save added annotation to the library",
  },
  {
    id: "MS007",
    name: eventName.drag,
    description: "Drag annotation to the assignment",
  },
  {
    id: "MS008",
    name: eventName.button,
    description: "Show comment library",
  },
  {
    id: "MS009",
    name: eventName.button,
    description: "Filter comment library",
  },
  {
    id: "MS010",
    name: eventName.button,
    description: "Comment allocate tag",
  },
  {
    id: "MS011",
    name: eventName.button,
    description: "Save flattened document",
  },
  {
    id: "MS012",
    name: eventName.button,
    description: "Save and close flattened document",
  },
  {
    id: "MS013",
    name: eventName.drag,
    description: "Reposition annotation",
  },
  {
    id: "MS014",
    name: eventName.button,
    description: "Open marking screen settings",
  },
  {
    id: "MS015",
    name: eventName.menu,
    description: "Select tools in marking screen settings",
  },
  {
    id: "MS016",
    name: eventName.button,
    description: "Save marking screen settings",
  },
  {
    id: "MS017",
    name: eventName.button,
    description: "Close marking screen settings",
  },
  {
    id: "MS018",
    name: eventName.button,
    description: "Update annotation",
  },
];

export default markingScreenEvents;
