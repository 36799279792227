import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex flex-col"
}
const _hoisted_2 = {
  key: 0,
  class: "flex items-center justify-between px-4 py-4 mx-4 mt-4 rounded-lg bg-flohh-secondary-blue"
}
const _hoisted_3 = { class: "flex items-center gap-2" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { class: "text-flohh-text-subtitle" }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = { class: "flex items-center gap-2" }
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = {
  key: 0,
  class: "text-flohh-text-subtitle"
}
const _hoisted_10 = ["onClick"]
const _hoisted_11 = {
  key: 1,
  class: "text-flohh-text-subtitle"
}
const _hoisted_12 = ["onClick"]
const _hoisted_13 = ["onClick"]
const _hoisted_14 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (!_ctx.routesExcluded.includes(_ctx.routeName))
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.getStartedBannerShow)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("span", {
                  innerHTML: _ctx.icons.infoCircleBlack
                }, null, 8, _hoisted_4),
                _createElementVNode("p", _hoisted_5, [
                  _createTextVNode(" New around here? Learn how to get the most out of Flohh with our "),
                  _createElementVNode("button", {
                    class: "underline hover:text-black",
                    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleOpenQuickStartGuide && _ctx.handleOpenQuickStartGuide(...args)))
                  }, " quick-start guide"),
                  _createTextVNode(". ")
                ])
              ]),
              _createElementVNode("button", {
                onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleHideGetStartedBanner && _ctx.handleHideGetStartedBanner(...args)))
              }, [
                _createElementVNode("span", {
                  innerHTML: _ctx.icons.closeBlack
                }, null, 8, _hoisted_6)
              ])
            ]))
          : _createCommentVNode("", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.bulkUploads, (item, index) => {
          return _withDirectives((_openBlock(), _createElementBlock("div", {
            key: index,
            class: "flex items-center justify-between px-4 py-4 mx-4 mt-4 rounded-lg bg-flohh-secondary-blue"
          }, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("span", {
                innerHTML: _ctx.icons.infoCircleBlack
              }, null, 8, _hoisted_8),
              (item.data.status === 'pending')
                ? (_openBlock(), _createElementBlock("p", _hoisted_9, [
                    _createTextVNode(" You have pending bulk upload for "),
                    _createElementVNode("button", {
                      class: "underline hover:text-black",
                      onClick: () => _ctx.handleGoToBulkUpload(item.data.assignment)
                    }, _toDisplayString(item.data.assignmentName), 9, _hoisted_10),
                    _createTextVNode(". ")
                  ]))
                : (_openBlock(), _createElementBlock("p", _hoisted_11, [
                    _createTextVNode(" Processing bulk upload for "),
                    _createElementVNode("button", {
                      class: "underline hover:text-black",
                      onClick: () => _ctx.handleGoToBulkUpload(item.data.assignment)
                    }, _toDisplayString(item.data.assignmentName), 9, _hoisted_12),
                    _createTextVNode(". ")
                  ]))
            ]),
            _createElementVNode("button", {
              onClick: ($event: any) => (_ctx.handleHideBulkUploadBanner(item.data.assignment))
            }, [
              _createElementVNode("span", {
                innerHTML: _ctx.icons.closeBlack
              }, null, 8, _hoisted_14)
            ], 8, _hoisted_13)
          ])), [
            [_vShow, !_ctx.getBulkUploadBanner(item.data.assignment)]
          ])
        }), 128))
      ]))
    : _createCommentVNode("", true)
}