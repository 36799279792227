export const GETTING_STARTED = [
  {
    title: "Uploading student work",
    link: "https://www.flohh.com.au/articles/uploading-your-students-work/",
  },
  {
    title: "Using the Annotation Tool",
    link: "https://www.flohh.com.au/articles/using-the-annotation-tool/",
  },
  {
    title: "Sharing Feedback",
    link: "https://www.flohh.com.au/articles/sharing-feedback/",
  },
  {
    title: "Navigating and using the Gradebook",
    link: "https://www.flohh.com.au/articles/navigating-and-using-the-gradebook/",
  },
];

export const ANNOTATIONS_AND_FEEDBACK = [
  {
    title: "How to set up the annotation bank",
    link: "https://www.flohh.com.au/articles/how-to-use-the-annotation-bank/",
  },
  {
    title: "Bulk upload annotations",
    link: "https://www.flohh.com.au/articles/can-i-bulk-upload-annotations-to-the-bank/",
  },
  {
    title: "Set up criteria sheets",
    link: "https://www.flohh.com.au/articles/how-to-build-a-criteria-sheet/",
  },
  {
    title: "How to use test and holistic schemas",
    link: "https://www.flohh.com.au/articles/how-to-use-test-and-holistic-schemas/",
  },
];

export const ORGANISING_YOUR_WORKFLOW = [
  {
    title: "How to add a class",
    link: "https://www.flohh.com.au/articles/how-to-add-a-class/",
  },
  {
    title: "How to create an assignment",
    link: "https://www.flohh.com.au/articles/how-to-create-an-assignment/",
  },
  {
    title: "How to make assignments visible to students",
    link: "https://www.flohh.com.au/articles/how-to-make-assignments-visible-to-students/",
  },
  {
    title: "How to share assignments",
    link: "https://www.flohh.com.au/articles/how-to-share-assignments/",
  },
];

export const GRADEBOOK_AND_ADVANCED_TOOLS = [
  {
    title: "How to download Gradebook Results",
    link: "https://www.flohh.com.au/articles/download-gradebook-results/",
  },
];
