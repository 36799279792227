<template>
  <div class="py-0 w-full max-w-[960px] px-5 pb-[80px]">
    <div
      class="rounded-md flex flex-col justify-center items-center pb-0 px-6 md:px-10 pt-5 bg-white w-full"
    >
      <div
        class="flex flex-col w-full items-center justify-center p-4 relative z-[1]"
      >
        <img
          src="@/assets/flohh-logo.svg"
          class="object-contain object-[14px] max-w-[210px] w-full mx-auto my-0"
        />
      </div>
      <Divider />
      <div class="pt-2 pb-6 text-center max-w-2xl">
        <p class="text-flohh-text-title color-flohh-neutral-20 font-bold">
          Create an account
        </p>
        <label
          class="ml-2 text-flohh-text-body font-flohh-font-medium color-flohh-neutral-20"
          >Personal Details
        </label>
      </div>
      <form
        @submit.prevent="onSubmit"
        autocomplete="off"
        class="w-full py-10 md:px-8 lg:px-8 pb-0"
      >
        <div class="flex flex-col lg:flex-row gap-4 mb-4">
          <div
            class="col-span-1 w-full flex justify-start flex-col items-start"
          >
            <p class="font-semibold text-sm mb-1">First Name</p>
            <FormTextInput
              type="text"
              v-model="form.firstName"
              placeholder="Enter First Name"
              :invalid="errMsg.firstName ? true : false"
              name="firstName"
            />
            <ErrorSpan v-if="errMsg.firstName">
              {{ errMsg.firstName }}
            </ErrorSpan>
          </div>
          <div
            class="col-span-1 w-full flex justify-start flex-col items-start"
          >
            <p class="font-semibold text-sm mb-1">Last Name</p>
            <FormTextInput
              type="text"
              v-model="form.lastName"
              placeholder="Enter Last Name"
              :invalid="errMsg.lastName ? true : false"
              name="lastName"
            />
            <ErrorSpan v-if="errMsg.lastName">
              {{ errMsg.lastName }}
            </ErrorSpan>
          </div>
        </div>

        <div class="flex flex-col lg:flex-row gap-4 mb-4">
          <div class="w-full flex justify-start flex-col items-start">
            <p class="font-semibold text-sm mb-1">Email</p>
            <FormTextInput
              type="email"
              v-model="form.email"
              placeholder="Enter Email"
              :invalid="errMsg.email ? true : false"
              name="email"
            />
            <ErrorSpan v-if="errMsg.email">
              {{ errMsg.email }}
            </ErrorSpan>
          </div>
        </div>

        <div class="flex flex-col lg:flex-row gap-4 mb-4">
          <div
            class="col-span-1 w-full flex justify-start flex-col items-start"
          >
            <p class="font-semibold text-sm mb-1">Password</p>
            <FormTextInput
              type="password"
              v-model="form.password"
              placeholder="Enter Password"
              :invalid="errMsg.password ? true : false"
              name="password"
              iconRight="eyeActive"
            />
            <ErrorSpan v-if="errMsg.password">
              {{ errMsg.password }}
            </ErrorSpan>
          </div>
          <div
            class="col-span-1 w-full flex justify-start flex-col items-start"
          >
            <p class="font-semibold text-sm mb-1">Confirm Password</p>
            <FormTextInput
              type="password"
              v-model="form.confirmPassword"
              placeholder="Confirm Password"
              :invalid="errMsg.confirmPassword ? true : false"
              name="confirmPassword"
              iconRight="eyeActive"
            />
            <ErrorSpan v-if="errMsg.confirmPassword">
              {{ errMsg.confirmPassword }}
            </ErrorSpan>
          </div>
        </div>

        <div class="flex justify-center items-center mt-6 mb-10 w-full">
          <Checkbox
            v-model="isAgree"
            inputId="isAgree"
            name="isAgree"
            value="true"
            :pt="{
              input: {
                class: '!border-flohh-neutral-85 !border-solid !border-2',
              },
            }"
          />
          <label
            class="ml-2 text-flohh-text-caption font-medium color-flohh-neutral-20"
            for="isAgree"
            >I agree to
            <a
              href="https://www.flohh.com.au/website-terms/"
              target="_blank"
              class="underline text-flohh-primary-pink"
              >Flohh’s Terms & Conditions</a
            ></label
          >
        </div>

        <!-- <div class="flex items-center justify-center mb-10">
          <img src="@/assets/captcha.png" />
        </div> -->
        <div class="flex items-center justify-center mb-10">
          <CaptchaCheckbox theme="light" v-model="isVerified" />
        </div>

        <RegistrationActionComponent
          :handleView="handleView"
          :isLoading="isLoading"
          :isVerified="isVerified"
        />
      </form>
    </div>

    <template v-if="showLoader">
      <LoaderComponent :route="route" />
    </template>

    <ActivationModalComponent
      v-model="visible"
      :onClose="closeModal"
      modalTitle="Teacher Activation"
      :verificationError="verificationError"
      :inputs="inputs"
      :handlePaste="handlePaste"
      :handleResend="handleResend"
      :handleVerify="handleVerify"
      :isVerifying="isVerifying"
    />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Ref, Vue, Watch } from "vue-facing-decorator";
// import axios, { AxiosResponse } from "axios";
import RegistrationFormComponent from "@/components/Authentication/Registration/RegistrationFormComponent.vue";
import RegistrationActionComponent from "@/components/Authentication/Registration/RegistrationActionComponent.vue";
import LoaderComponent from "@/components/Loader/LoaderComponent.vue";
import { IRegistrationInfo, TRegistrationInfo } from "./types";
import FormTextInput from "@/components/Layout/Forms/FormTextInput.vue";
import ErrorSpan from "@/components/utilities/ErrorSpan.vue";
import AppButton from "@/components/Layout/Buttons/AppButton.vue";
import { validateEmail, validatePassword } from "@/utils/helper";
import Checkbox from "primevue/checkbox";
import Divider from "primevue/divider";
import { icons as AppIcons } from "@/utils/icons";
import axios, { AxiosResponse } from "axios";
import { useToast } from "primevue/usetoast";
import ModalUtility from "@/components/utilities/ModalUtility.vue";
import InputTextComponent from "@/components/utilities/InputTextComponent.vue";
import { ref } from "vue";
import ActivationModalComponent from "./ActivationModalComponent.vue";
import { Checkbox as CaptchaCheckbox } from "vue-recaptcha";
import { TELEMETRY_HEAD } from "@/utils/telemetry/data";
import { event } from "vue-gtag";
interface IFormData extends IErrorMessage {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirmPassword: string;
}

interface IErrorMessage {
  [key: string]: string;
}

interface ClipboardEvent extends Event {
  clipboardData: DataTransfer;
}

@Component({
  components: {
    RegistrationFormComponent,
    RegistrationActionComponent,
    LoaderComponent,
    FormTextInput,
    ErrorSpan,
    Checkbox,
    CaptchaCheckbox,
    Divider,
    AppButton,
    ModalUtility,
    InputTextComponent,
    ActivationModalComponent,
  },
})
export default class RegistrationComponent extends Vue {
  @Prop({
    type: Function,
    required: true,
  })
  private updateView!: () => void;

  toast = useToast();

  isAgree = false;
  showLoader = false;
  route = "/register/onboarding";

  visible = false;

  form: IRegistrationInfo = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
  };

  validationMessage!: TRegistrationInfo;

  errMsg: IFormData = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
  };

  icon = AppIcons;

  validationTimeout!: number;

  registrationResponse!: any;

  @Ref() readonly inputFields!: HTMLInputElement[];

  inputs: { value: string }[] = [
    { value: "" },
    { value: "" },
    { value: "" },
    { value: "" },
    { value: "" },
    { value: "" },
  ];

  verificationError = {
    show: false,
    message: "",
  };

  isLoading = false;
  isVerifying = false;
  isVerified = false;
  otpVerified = false;

  mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams) {
      const urlParams = new URLSearchParams(window.location.search);
      this.form.firstName = urlParams.get("firstName") || "";
      this.form.lastName = urlParams.get("lastName") || "";
      this.form.email = urlParams.get("email")
        ? decodeURIComponent(urlParams.get("email") || "")
        : "";
    }
  }

  handleInput(index: number) {
    const nextIndex = index + 1;
    if (nextIndex < this.inputs.length && this.inputs[index].value !== "") {
      const field: any = this.inputFields[nextIndex];
      field.$el.focus();
    }
  }

  handlePaste(event: Event) {
    const codes = this.splitAndDisplay(event);
    if (codes && codes.length) {
      codes.slice(0, 6).forEach((item, index) => {
        this.inputs[index].value = item;
      });
    }
  }

  splitAndDisplay(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const clipboardData = (event as ClipboardEvent).clipboardData;
    const pastedText = clipboardData.getData("text");

    const characters = pastedText.split("");

    event.preventDefault();
    return characters;
  }

  async handleVerify() {
    this.isVerifying = true;
    try {
      //
      const code = this.inputs.map((item) => item.value).join("");
      ///authentication/otp/verify
      const allValuesNotEmpty = this.inputs.every((item) =>
        Boolean(item.value)
      );
      if (allValuesNotEmpty) {
        const payload = {
          code: code,
          type: "teacher_activation",
          token: this.registrationResponse.data.data.accessToken,
        };
        const response: any = await axios
          .post("/authentication/otp/verify", payload, {
            headers: TELEMETRY_HEAD.accountVerification,
          })
          .catch((error) => {
            console.error(error);
            this.isVerifying = false;
            // this.showError(error.message);
            this.verificationError.show = true;
            this.verificationError.message = error.response.data.details.info;
          });

        this.isVerifying = false;
        if (response.data.ok) {
          this.otpVerified = true;
          this.visible = false;
          localStorage.setItem("auth", JSON.stringify(response.data.data));
          const authorization = `Bearer ${response.data.data.accessToken}`;
          axios.defaults.headers.common["Authorization"] = authorization;
          localStorage.setItem(
            "authenticatedAs",
            response.data.data.authenticatedAs
          );

          const gaStorage = localStorage.getItem("GA_SESSION");
          const gaSession = gaStorage ? JSON.parse(gaStorage) : null;
          const userId = response.data.data.data.uuid;
          const gaData = {
            user_id: gaSession
              ? gaSession.id
                ? gaSession.id
                : userId
              : userId,
            source: gaSession ? gaSession.source : window.location.href,
            landing_page: window.location.href,
            sign_up_date: new Date().toISOString(),
            timestamp: new Date().toISOString(),
          };
          event("sign_up_form_submit", gaData);
          localStorage.removeItem("GA_SESSION");
          setTimeout(() => {
            this.showLoader = true;
          }, 100);
        }
      } else {
        this.isVerifying = false;
        this.verificationError.show = true;
        this.verificationError.message = "Invalid code";
      }
    } catch (e) {
      //
      // this.visible = false;
      this.isVerifying = false;
    } finally {
      this.isVerifying = false;
      // this.visible = false;
      //
    }
  }

  async handleResend() {
    const payload = {
      type: "teacher_activation",
      token: this.registrationResponse.data.data.accessToken,
    };

    const response: any = await axios
      .post("/authentication/otp/resend", payload)
      .catch((error) => {
        console.error(error);
        if (error.response.status === 422) {
          this.showError(error.message);
        }
      });
    if (response.data.ok) {
      this.toast.add({
        severity: "success",
        detail: "Resent code in your email",
        life: 3000,
      });
    }
  }

  handleValidation(data: TRegistrationInfo) {
    this.validationMessage = data;
  }

  areAllValuesEmpty<T extends Record<string, string>>(obj: T): boolean {
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key) && obj[key] !== "") {
        return false;
      }
    }
    return true;
  }

  // @Watch("form", { deep: true })
  // formWatcher(newValue: any) {
  //   if (newValue) {
  //     for (const key in newValue) {
  //       if (Object.prototype.hasOwnProperty.call(newValue, key)) {
  //         const value = newValue[key];
  //         this.onInput(key, value);
  //       }
  //     }
  //   }
  // }
  @Watch("visible")
  visibleWatcher(newValue: any) {
    if (this.registrationResponse && !newValue && !this.otpVerified) {
      window.location.href = "/login";
    }
  }
  @Watch("form.firstName")
  formFirstNameWatcher(newValue: any) {
    this.onInput("firstName", newValue);
  }
  @Watch("form.lastName")
  formLastNameWatcher(newValue: any) {
    this.onInput("lastName", newValue);
  }
  @Watch("form.email")
  formEmailWatcher(newValue: any) {
    this.onInput("email", newValue);
  }
  @Watch("form.password")
  formPasswordWatcher(newValue: any) {
    this.onInput("password", newValue);
  }
  @Watch("form.confirmPassword")
  formConfirmPasswordWatcher(newValue: any) {
    this.onInput("confirmPassword", newValue);
  }
  onInput(name: string, value: string) {
    if (name) {
      if (value) {
        this.errMsg[name] = "";

        if (name.toLocaleLowerCase().search("email") > -1) {
          this.errMsg[name] = validateEmail(value);
        } else if (name.toLocaleLowerCase().search("password") > -1) {
          this.errMsg[name] = validatePassword(value);
        } else {
          //
        }

        if (this.form.password && this.form.confirmPassword) {
          const validPassword =
            validatePassword(this.form.password) &&
            validatePassword(this.form.confirmPassword);
          const doPasswordsMatch =
            this.form.password === this.form.confirmPassword;
          if (!validPassword && !doPasswordsMatch) {
            this.errMsg.confirmPassword =
              "Password and Confirm Password should match";
          } else {
            this.errMsg.confirmPassword = validPassword;
          }
        }
      } else {
        this.errMsg[name] = "Field is required";
      }
    }
    this.handleValidation(this.errMsg);
    // }, 200);
  }

  /**
   * onSubmit
   */
  public async onSubmit() {
    this.isLoading = true;
    const {
      firstName,
      lastName,
      email,
      password,
      confirmPassword,
    }: IRegistrationInfo = this.form;

    const payload = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      password: password,
      workEmail: email,
      schoolName: "",
    };

    try {
      const allValid: boolean = this.areAllValuesEmpty(this.validationMessage);
      const allEmpty: boolean = this.areAllValuesEmpty(payload);
      const isChecked = Array.isArray(this.isAgree) ? this.isAgree[0] : false;

      if (allValid && !allEmpty && isChecked) {
        if (this.isVerified) {
          const response: any = await axios
            .post("/teachers", payload, {
              headers: TELEMETRY_HEAD.accountRegistration,
            })
            .catch((error) => {
              console.error(error);
              if (error.response.status === 422) {
                this.showError(error.message);
              }
            });
          this.isLoading = false;
          if (response.data.ok) {
            this.verificationError.show = false;
            this.verificationError.message = "";
            this.registrationResponse = response;
            this.visible = true;
            this.inputs = [
              { value: "" },
              { value: "" },
              { value: "" },
              { value: "" },
              { value: "" },
              { value: "" },
            ];
          } else {
            console.error(response.data.message);
            this.showError(response.data.message);
          }
        } else {
          this.showError("Please verify that you're not a robot.");
        }
      } else {
        this.showError("Please complete the fields.");
      }
    } catch (error) {
      if (error instanceof ReferenceError) {
        console.error(error.message);
      } else {
        // throw error;
      }
      this.isLoading = false;
    } finally {
      this.isLoading = false;
    }
  }

  public handleView() {
    // You can perform some logic here and then call the parent function
    // this.updateView();
    this.$router.push("/register");
  }

  private showError(message: string) {
    this.toast.add({
      severity: "error",
      detail: message,
      life: 3000,
    });

    this.isLoading = false;
  }

  closeModal() {
    this.visible = false;
    this.isLoading = false;
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
$browser-context: 16; // Default
@function em($pixels, $context: $browser-context) {
  @return #{$pixels/$context}em;
}

.registration-container {
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
}
.registration-wrap {
  background-color: #f4d5de;
  border-radius: 4px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 926px;
  margin-bottom: 80px;
  h4 {
    font-size: 22px;
    font-weight: 700;
    line-height: 1.6;
    margin-bottom: 20px;
  }
  form {
    width: 100%;
  }
}
.verification-form {
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }
}
</style>
