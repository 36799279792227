import { EventDetail, EventName } from "../type";

const gradeSheetEvents = (eventName: EventName): EventDetail[] => [
  {
    id: "CS001",
    name: eventName.button,
    description: "Open edit criteria sheet name modal",
  },
  {
    id: "CS002",
    name: eventName.button,
    description: "Open edit criteria sheet total score modal",
  },
  {
    id: "CS003",
    name: eventName.button,
    description: "Open edit criteria sheet grade modal",
  },
  {
    id: "CS004",
    name: eventName.button,
    description: "Save criteria sheet name",
  },
  {
    id: "CS005",
    name: eventName.button,
    description: "Save criteria sheet total score",
  },
  {
    id: "CS006",
    name: eventName.button,
    description: "Add criteria sheet grade row",
  },
  {
    id: "CS007",
    name: eventName.button,
    description: "Save criteria sheet grade",
  },
  {
    id: "CS008",
    name: eventName.button,
    description: "Remove criteria sheet grade row",
  },
  {
    id: "CS009",
    name: eventName.button,
    description: "Save criteria sheet",
  },
  {
    id: "CS010",
    name: eventName.button,
    description: "Edit criteria sheet cell",
  },
  {
    id: "CS011",
    name: eventName.button,
    description: "Save criteria sheet cell",
  },
  {
    id: "CS012",
    name: eventName.button,
    description: "Add criteria sheet level / criteria",
  },
  {
    id: "CS013",
    name: eventName.button,
    description: "Change criteria sheet view",
  },
  {
    id: "CS014",
    name: eventName.button,
    description: "Undo criteria sheet",
  },
  {
    id: "CS015",
    name: eventName.button,
    description: "Redo criteria sheet",
  },
  {
    id: "CS016",
    name: eventName.button,
    description: "Delete criteria sheet",
  },
];

export default gradeSheetEvents;
