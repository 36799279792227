import { EventDetail, EventName } from "../type";

const classPageEvents = (eventName: EventName): EventDetail[] => [
  {
    id: "CP001",
    name: eventName.button,
    description: "Open bulk upload selection modal",
  },
  {
    id: "CP002",
    name: eventName.button,
    description: "Download coversheet",
  },
  {
    id: "CP003",
    name: eventName.page,
    description: "Visit edit assignment page",
  },
  {
    id: "CP004",
    name: eventName.menu,
    description: "Copy assignment link",
  },
  {
    id: "CP005",
    name: eventName.menu,
    description: "Select return all feedback",
  },
  {
    id: "CP006",
    name: eventName.menu,
    description: "Download marked submissions",
  },
  {
    id: "CP007",
    name: eventName.menu,
    description: "Delete assignment",
  },
  {
    id: "CP008",
    name: eventName.menu,
    description: "Table row, submit assignment",
  },
  {
    id: "CP009",
    name: eventName.menu,
    description: "Table row, view submission",
  },
  {
    id: "CP010",
    name: eventName.menu,
    description: "Table row, download submission",
  },
  {
    id: "CP011",
    name: eventName.menu,
    description: "Table row, mark submission",
  },
  {
    id: "CP012",
    name: eventName.menu,
    description: "Table row, mark submission",
  },
  {
    id: "CP013",
    name: eventName.menu,
    description: "Table row, approve learning goal",
  },
  {
    id: "CP014",
    name: eventName.menu,
    description: "Table row, return feedback",
  },
  {
    id: "CP015",
    name: eventName.menu,
    description: "Remove student from class",
  },
  {
    id: "CP016",
    name: eventName.menu,
    description: "Download markbook as excel",
  },
  {
    id: "CP017",
    name: eventName.menu,
    description: "Download markbook as CSV",
  },
  {
    id: "CP018",
    name: eventName.button,
    description: "Approve / reject learning goal",
  },
  {
    id: "CP019",
    name: eventName.menu,
    description: "Edit student",
  },
  {
    id: "CP020",
    name: eventName.menu,
    description: "Table row, review learning goal",
  },
  {
    id: "CP021",
    name: eventName.menu,
    description: "Table row, assign to class",
  },
];

export default classPageEvents;
