import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex w-full" }
const _hoisted_2 = { class: "bg-white rounded-l-lg my-3 pl-[40px] pr-[33px] py-[47px] relative w-[75%] min-h-[100vh]" }
const _hoisted_3 = { class: "bg-white w-[25%] min-h-[calc(100vh_-_80px)] sticky top-[80px] border-x border-solid border-flohh-neutral-85 my-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DigitalSubmission = _resolveComponent("DigitalSubmission")!
  const _component_PaperSubmission = _resolveComponent("PaperSubmission")!
  const _component_BulkUploadSidePanel = _resolveComponent("BulkUploadSidePanel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.bulkUploadType === 'digital')
        ? (_openBlock(), _createBlock(_component_DigitalSubmission, {
            key: 0,
            uploadingFiles: _ctx.uploadingFiles,
            filesSucceeded: _ctx.filesSucceeded,
            filesFailed: _ctx.filesFailed,
            uploadProgress: _ctx.uploadProgress,
            digitalFirstSubmissionUuid: _ctx.digitalFirstSubmissionUuid,
            onOnSubmit: _ctx.handleUpload,
            onOnFinish: _ctx.handleFinishUpload
          }, null, 8, ["uploadingFiles", "filesSucceeded", "filesFailed", "uploadProgress", "digitalFirstSubmissionUuid", "onOnSubmit", "onOnFinish"]))
        : _createCommentVNode("", true),
      (_ctx.bulkUploadType === 'paper')
        ? (_openBlock(), _createBlock(_component_PaperSubmission, {
            key: 1,
            hasStudents: _ctx.studentsCount > 0,
            downloadingCoversheets: _ctx.downloadingCoversheets,
            submitting: _ctx.uploadingAndSplitting,
            onOnDownloadCoversheets: _ctx.handleDownloadCoversheets,
            onOnUploadAndSplit: _ctx.handleUploadAndSplit,
            onOnTabChange: _ctx.handlePaperTabChange
          }, null, 8, ["hasStudents", "downloadingCoversheets", "submitting", "onOnDownloadCoversheets", "onOnUploadAndSplit", "onOnTabChange"]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_BulkUploadSidePanel, {
        onOnClassSelect: _ctx.handleClassSelect,
        onOnAssignmentSelect: _ctx.handleAssignmentSelect,
        canCreate: _ctx.paperActiveTab === 0,
        error: _ctx.error
      }, null, 8, ["onOnClassSelect", "onOnAssignmentSelect", "canCreate", "error"])
    ])
  ]))
}