<template>
  <div class="colored-container p-3">
    <div class="bg-white rounded-lg h-full flex p-6 items-center gap-6">
      <div class="flex items-center gap-3">
        <img src="@/assets/duck2copy.png" class="w-auto h-[67px]" />

        <div>
          <h6
            class="text-flohh-h6 leading-[1.5] text-flohh-neutral-20 font-bold"
          >
            Hi {{ firstName }}!
          </h6>
          <p
            class="text-flohh-text-title font-flohh-font-bold text-flohh-neutral-20"
          >
            Welcome to Flohh
          </p>
        </div>
        <button
          v-if="showWelcomeVideoToggle"
          @click="isWelcomeModalOpen = true"
        >
          <span v-html="icons.infoCircleBlack" />
        </button>
      </div>
      <div class="grid gap-5 justify-center grid-cols-4">
        <AppCardButton
          class=""
          label="Upload Submissions"
          sublabel="For paper or digital files"
          variant="bd"
          type="subtitle"
          @click="
            () => {
              // handleRedirect('/bulk-upload/digital');
              isOpen = !isOpen;
            }
          "
        />
        <AppCardButton
          class=""
          label="Share submission folder"
          sublabel="For students to upload their work"
          variant="bd"
          color="yellow"
          type="subtitle"
          @click="
            () => {
              if (handleSubmissionModal) {
                handleSubmissionModal();
              }
            }
          "
        />
        <AppCardButton
          class=""
          label="Create a class"
          sublabel="To organise your student lists"
          variant="bd"
          color="purple"
          type="subtitle"
          @click="toggleCreateClass"
        />
        <AppCardButton
          class=""
          label="Create an assignment"
          sublabel="To organise your submissions"
          variant="bd"
          color="green"
          type="subtitle"
          @click="
            () => {
              handleRedirect('/assignments/create');
            }
          "
        />
      </div>
    </div>
  </div>
  <CreateClassPopup v-model="isAddClassOpen" :isEdit="false" />

  <UploadSubmissionModal v-model="isOpen" />

  <WelcomeModal v-model="isWelcomeModalOpen" :firstName="firstName" />
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-facing-decorator";
import { icons } from "@/utils/icons";
import AppCardButton from "@/components/Layout/Buttons/AppCardButton.vue";
import ModalUtility from "../utilities/ModalUtility.vue";
import CreateClassPopup from "../CreateClass/CreateClassPopup.vue";
import UploadSubmissionModal from "../utilities/UploadSubmissionModal.vue";
import WelcomeModal from "./WelcomeModal.vue";

@Component({
  components: {
    AppCardButton,
    ModalUtility,
    CreateClassPopup,
    UploadSubmissionModal,
    WelcomeModal,
  },
})
export default class DashboardHeaderComponent extends Vue {
  icons = icons;

  @Prop({
    type: String,
    default: false,
  })
  firstName!: string;

  @Prop({
    type: Boolean,
    default: false,
  })
  showWelcomeVideoToggle!: boolean;

  @Prop({
    type: Function,
  })
  handleSubmissionModal!: () => void;

  isOpen = false;
  isAddClassOpen = false;
  isWelcomeModalOpen = false;

  async mounted() {
    //
  }

  toggleCreateClass() {
    this.isAddClassOpen = !this.isAddClassOpen;
  }

  createAssignment() {
    //assignments/create
    this.$router.push({
      path: "/assignments/create",
    });
  }

  handleRedirect(path: string) {
    this.$router.push({
      path: path,
    });
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.colored-container {
  background-image: url("../../assets/rainbow-bg.svg");
  background-repeat: no-repeat;
  background-size: cover;
  // min-height: 315px;
  width: 100%;
  border-radius: 8px;
}
</style>
