<script lang="ts">
import { Vue, Component, Watch } from "vue-facing-decorator";
import {
  Navigation,
  navigation as navigationList,
  superAdminNavigation,
} from "@/utils/navigations";
import { icons as AppIcons } from "@/utils/icons";
import { RouteRecord } from "vue-router";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import SidePanelNavigation from "./Partials/SidePanelNavigation.vue";
import TopNavigation from "./Partials/TopNavigation.vue";
import TeacherService from "@/services/TeacherService";
import StudentService from "@/services/StudentService";
import DashboardService from "@/services/DashboardService";
import {
  getAuthMe,
  setTeacherDispatch,
  setStudentDispatch,
} from "@/store/auth/auth-dispatch";
import emitter from "@/config/emitter";
import { getTeacherUuid } from "@/config/auth";
import { setClassess } from "@/store/class/class-dispatch";
import { ProfileDetails } from "@/config/auth";
import Banners from "./Partials/Banners.vue";
import { setPendingActions } from "@/store/dashboard/dashboard-dispatch";
import { PendingActions } from "@/store/dashboard/dashboardTypes";
import AccountCreditsComponent from "../AccountCredits/AccountCreditsComponent.vue";
import UpgradePlanComponent from "../UpgradePlan/UpgradePlanComponent.vue";
import { ActivePlan, Plan } from "../UpgradePlan/type";
import { event } from "vue-gtag";

@Component({
  components: {
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    SidePanelNavigation,
    TopNavigation,
    Banners,
    AccountCreditsComponent,
    UpgradePlanComponent,
  },
})
export default class AppLayout extends Vue {
  private teacherService: TeacherService = new TeacherService();
  private studentService: StudentService = new StudentService();
  private dashboardService: DashboardService = new DashboardService();

  sidebarOpen = false;
  navigation: Navigation[] = [];
  hideNavigation = false;
  icons = AppIcons;
  isSuperAdmin = false;
  eventBus = emitter;
  profile!: ProfileDetails;
  userNavigation = [
    { name: "Your profile", href: "#" },
    { name: "Sign out", href: "#" },
  ];

  toggleAccountCredit = false;
  toggleUpgradePlan = false;

  plans!: Plan[];
  activePlan!: ActivePlan | undefined;

  hideClose = false;
  isRedirectBack = "";

  pendingActions!: PendingActions[];

  async onGetUserProfile(role: string) {
    const service =
      role === "student"
        ? this.studentService.getStudent()
        : this.teacherService.getTeacher();

    try {
      const response = await service;
      if (response.data.ok) {
        const profile = response.data.data;
        this.profile = profile;

        if (role === "student") {
          localStorage.setItem(
            "student",
            profile.schoolAddress
              ? JSON.stringify(profile)
              : JSON.stringify({
                  ...profile,
                  schoolAddress: { country: "", state: "" },
                  schoolName: "",
                })
          );
        } else {
          localStorage.setItem("teacher", JSON.stringify(profile));
        }
        if (profile && role !== "super admin") {
          this.$store.dispatch(
            role === "student" ? setStudentDispatch : setTeacherDispatch,
            profile
          );
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  async loadClasses() {
    try {
      const params = {
        teacher: getTeacherUuid(),
      };
      await this.$store.dispatch(setClassess, params);
    } catch (error) {
      console.error(error);
    }
  }

  getAuthProfile() {
    this.$store.dispatch(getAuthMe);
  }

  async mounted() {
    const auth = localStorage.getItem("auth");
    const authenticatedAs = localStorage.getItem("authenticatedAs");

    if (auth) {
      const authData = JSON.parse(auth).data;
      this.isSuperAdmin = authData.roles.includes("super admin");

      this.getAuthProfile();
      this.navigation = this.isSuperAdmin
        ? superAdminNavigation
        : navigationList;

      this.getPendingActions();

      if (authenticatedAs) {
        const userType = authenticatedAs;
        console.log(userType);
        switch (userType) {
          case "teacher":
            this.onGetUserProfile("teacher");
            this.loadClasses();
            await this.getPlans();
            await this.getActivePlan();

            this.eventBus.on("REFRESH_ACTIVE_PLAN", () => {
              this.getActivePlan();
            });
            break;
          case "super admin":
            //
            console.log("authData");
            console.log(authData);
            this.profile = {
              ...authData.roleDetails["super admin"],
              email: authData.email,
              workEmail: authData.email,
              profile: {
                uuid: authData.roleDetails["super admin"].profile,
                firstName: "Super",
                lastName: "Admin",
                user: authData.roleDetails["super admin"].user,
              },
            };
            localStorage.setItem("superadmin", JSON.stringify(this.profile));
            break;
          case "student":
            this.onGetUserProfile("student");
            // this.handleFilteredRoutesForStudent();

            this.eventBus.on("UPDATE_MY_ACCOUNT", () => {
              this.onGetUserProfile("student");
            });
            break;
          default:
            break;
        }
      }
    }

    this.eventBus.on("LOAD_CLASSES", () => {
      this.loadClasses();
    });

    this.eventBus.on("ACCOUNT_CREDIT_MODAL", (flag: boolean) => {
      this.toggleAccountCredit = flag;
    });

    this.eventBus.on("MODAL_REDIRECT_BACK", (link) => {
      this.isRedirectBack = link;
    });

    this.eventBus.on("UPGRADE_PLAN_MODAL", (flag: boolean) => {
      this.toggleUpgradePlan = flag;
    });

    this.eventBus.on("REFRESH_PENDING_ACTIONS", () => {
      this.getPendingActions();
    });

    this.eventBus.on("GTAG_EVENT", (data) => {
      if (data) {
        const params = {
          ...data.params,
          user_id: this.profile.profile.user,
          timestamp: new Date().toISOString(),
        };
        event(data.event, params);
      }
    });
  }

  async handleRemoveOnboardingTask() {
    try {
      await this.$store.dispatch(setPendingActions);
      const state = this.$store.getters["dashboard/getPendingActions"];
      const pendingActions = state.pendingActions;

      if (pendingActions && pendingActions.length > 0) {
        const completedTasks = pendingActions.filter(
          (item: PendingActions) =>
            (item.status === "fulfilled" && !item.data) ||
            (item.status === "fulfilled" && item.data && !item.data.gtagMs)
        );

        if (completedTasks.length > 0) {
          completedTasks.forEach(async (item: PendingActions) => {
            if (item.type === "onboardingDemo") {
              // GTAG DEMO SUBMISSION MARKED
            } else if (item.type === "onboardingUploadSubmission") {
              // GTAG SUBMISSION SUBMITTED
            } else if (item.type === "onboardingMarkSubmission") {
              // GTAG OTHER SUBMISSION MARKED
            } else if (item.type === "onboardingReturnSubmission") {
              // GTAG SUBMISSION RETURNED FEEDBACK
            }

            await this.dashboardService.addGTag(item.uuid, {
              gtagMs: Date.now(),
            });
          });
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  beforeUnmount() {
    this.eventBus.off("LOAD_CLASSES");
    this.eventBus.off("ACCOUNT_CREDIT_MODAL");
    this.eventBus.off("UPGRADE_PLAN_MODAL");
    this.eventBus.off("UPGRADE_PLAN_MODAL");
    this.eventBus.off("GTAG_EVENT");
  }
  async getPlans() {
    //
    const res = await this.teacherService.getPlans();
    console.log("getPlans");
    console.log(res);
    if (res.data.ok) {
      //

      this.plans = res.data.data;
    }
  }

  async getActivePlan() {
    //
    const res = await this.teacherService.getActivePlan();
    console.log("getActivePlan");
    console.log(res);

    if (res.data.ok) {
      this.activePlan = res.data.data;

      localStorage.setItem(
        "CREDITS_AVAILABLE",
        String(res.data.data.credits.available || 0)
      );
    }
  }

  async getPendingActions() {
    const path = window.location.pathname;
    if (!path.includes("/superadmin")) {
      await this.$store.dispatch(setPendingActions);
      const data = this.$store.getters["dashboard/getPendingActions"];
      this.pendingActions = data.pendingActions;
    }
  }

  @Watch("$route", { immediate: true, deep: true })
  routeWatcher(value: RouteRecord) {
    if (!value) return;

    this.navigation.map((nav) => {
      if (nav.route === value.name) {
        nav.current = true;
      } else {
        nav.current = false;
      }
    });
  }

  handleNavToggle(value: boolean) {
    const main = document.getElementById("main-content");
    if (main) {
      if (value) {
        main.classList.add("extended-nav");
      } else {
        main.classList.remove("extended-nav");
      }
    }
  }

  toggleUpgradePlanModal(flag: boolean) {
    this.toggleUpgradePlan = flag;
  }
}
</script>
<template>
  <div>
    <SidePanelNavigation
      :navigation="navigation"
      @onNavToggle="handleNavToggle"
    />
    <div class="main-content" id="main-content">
      <TopNavigation
        v-model:sidebarOpen="sidebarOpen"
        v-model:hideNavigation="hideNavigation"
        :isSuperAdmin="isSuperAdmin"
        :profile="profile"
        :pendingActions="pendingActions"
      />

      <main>
        <div class="gap-4">
          <Banners v-if="pendingActions" :pendingActions="pendingActions" />
          <div id="custom-overlay-menu"></div>
          <!-- Your content -->
          <slot />
        </div>
      </main>
    </div>
  </div>

  <AccountCreditsComponent
    v-model="toggleAccountCredit"
    :activePlan="activePlan"
    @toggleUpgradePlanModal="toggleUpgradePlanModal"
    :isRedirectBack="isRedirectBack"
  />
  <UpgradePlanComponent
    v-model="toggleUpgradePlan"
    :plans="plans"
    :activePlan="activePlan"
    v-if="plans && activePlan"
    :isRedirectBack="isRedirectBack"
  />
</template>
<style lang="scss" scoped>
.main-content {
  padding-left: 64px;
  transition: all 0.5s ease;
  height: 100vh;
  overflow: auto;
}
.extended-nav {
  padding-left: 244px !important;
}
</style>
