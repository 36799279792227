<template>
  <div class="flex relative flex-col">
    <div class="h-full w-full rounded-lg p-5 bordered">
      <ProgressLoader
        v-if="loadingSummary"
        label="Loading"
        labelVariant="md"
        labelType="subtitle"
      />
      <ClassesTableComponent
        v-else
        :classUuid="classUuid"
        :isFull="isFull"
        :hideHeader="hideHeader"
        :data="searchResult"
        :sortDefault="sortDefault"
        :tabType="'member'"
        @onSort="handleSort"
        @onSearch="handleSearch"
      >
        <Column style="width: 20px">
          <template #body="{ data }"
            ><PersonAvatar
              :firstName="data.profile.firstName"
              :lastName="data.profile.lastName"
              size="s" /></template
        ></Column>
        <Column
          header="Student Name"
          :style="{
            minWidth: '140px',
            maxWidth: '140px',
          }"
          ><template #body="{ data }">{{
            `${data.profile.firstName} ${data.profile.lastName}`
          }}</template></Column
        >
        <Column
          header="Email"
          :style="{
            minWidth: '200px',
            maxWidth: '200px',
          }"
        >
          <template #body="props">
            <CustomTooltip
              :tooltip="props.data.profile.email"
              :ellipsis="true"
              position="right"
              ><template #body
                ><span>{{ props.data.profile.email }}</span></template
              ></CustomTooltip
            >
          </template>
        </Column>
        <!-- TODO: Make dynamic value -->
        <Column field="" :style="{ width: '120px', textAlign: 'center' }">
          <template #header> <div class="mx-auto">User Type</div></template
          ><template #body>Student</template>
        </Column>
        <Column style="width: 55px; position: relative; padding: 0px"
          ><template #header
            ><div class="actions-cell bg-flohh-secondary-orange">
              <div class="action-icon-container">
                <span v-html="icons.menuBarSmallBlack"></span>
              </div></div
          ></template>
          <template #body="{ data }">
            <div
              class="flex items-center justify-center actions-cell actions-body"
              v-tooltip.top="{
                value: 'More Options',
                showDelay: 500,
              }"
            >
              <AppDropdownButton
                size="sm"
                @clickMenu="(action: string) => {
                handleClickMenu(action, data)
              }"
                :menus="moreOptions"
                type="default"
                text
                width="w-40"
                dropdownIcon="ellepsisVerticalBlack"
              />
            </div>
          </template>
        </Column>
        <template #empty> No Students found. </template>
      </ClassesTableComponent>
    </div>
  </div>
  <Dialog
    :visible="deleteModalVisible"
    modal
    :style="{ width: '410px' }"
    :closable="false"
    :pt="{
      header: { class: '!py-4 !pl-6' },
      content: { class: '!p-0' },
      footer: { class: '!p-0' },
    }"
  >
    <template #header>
      <div class="">
        <h5
          class="text-default text-neutral-gray-500 flex font-bold items-center justify-start"
        >
          <i v-html="eventIcons['ICON_TRASH']" class="mr-2"></i>Remove Student
          from Class
        </h5>
      </div>
    </template>
    <div class="border-t border-solid border-neutral-gray-500 py-4 px-6">
      <p class="text-sm text-neutral-gray-500">
        You are about to delete this student from the assignment. Any
        submissions submitted by this student will also be deleted. Are you sure
        you want to remove this student from the assignment?
      </p>
      <br />
      <p class="text-sm text-neutral-gray-500 font-bold">
        {{ selectedDeleteStudent.profile.firstName }}
        {{ selectedDeleteStudent.profile.lastName }},
        {{ selectedDeleteStudent.profile.email }}
      </p>
    </div>
    <template #footer>
      <div class="py-2 px-6 flex items-center justify-end">
        <Button
          label="Cancel"
          @click="handleHideModal"
          class="border border-solid border-neutral-gray-70 bg-transparent text-sm text-neutral-gray-70 rounded-[8px] h-[45px] font-medium btn-medium py-3 px-5"
        />
        <Button
          label="Remove Student"
          @click="handleDelete"
          class="border border-solid border-neutral-gray-500 bg-neutral-gray-85 text-sm text-neutral-gray-500 rounded-[8px] h-[45px] font-medium btn-medium py-3 px-5 !mr-0"
          :disabled="loadingDeleteStudent"
          :loading="loadingDeleteStudent"
          :class="[loadingDeleteStudent ? 'cursor-progress' : '']"
        />
      </div>
    </template>
  </Dialog>
  <EditStudentModal
    v-model="editModalVisible"
    :studentData="selectedEditStudent"
    :classUuid="classUuid"
  />
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from "vue-facing-decorator";
import Column from "primevue/column";
import ClassesTableComponent from "./ClassesTableComponent.vue";
import { IData } from "./types";
import { EVENT_ICONS, STUDENT_DATA } from "./data";
import FormTextInput from "../Layout/Forms/FormTextInput.vue";
import emitter from "@/config/emitter";
import Dialog from "primevue/dialog";
import Menu from "primevue/menu";
import Button from "primevue/button";
import { deleteStudentPerClass } from "@/store/class/class-dispatch";
import { DeleteStudentParams } from "@/store/class/actions/DeleteStudentPerClassAction";
import FormInputDropdown from "../Layout/Forms/FormInputDropdown.vue";
import { sortMembersTable } from "@/utils/sort";
import Ellipsis from "@/components/utilities/Ellipsis.vue";
import { icons as AppIcons } from "@/utils/icons";
import CustomTooltip from "../utilities/CustomTooltip.vue";
import PersonAvatar from "@/components/utilities/PersonAvatar.vue";
import EditStudentModal from "./utilities/EditStudentModal.vue";
import AppDropdownButton from "@/components/Layout/Buttons/AppDropdownButton.vue";
import ProgressLoader from "@/components/utilities/ProgressLoader.vue";
import AppTypographyText from "../Layout/Typhography/AppTypographyText.vue";
import { ClassStudentsDetails, ClassData } from "@/store/class/classTypes";

@Component({
  components: {
    ClassesTableComponent,
    Column,
    FormTextInput,
    Dialog,
    Menu,
    Button,
    FormInputDropdown,
    Ellipsis,
    CustomTooltip,
    PersonAvatar,
    EditStudentModal,
    AppDropdownButton,
    ProgressLoader,
    AppTypographyText,
  },
})
export default class ClassesPeopleComponent extends Vue {
  @Prop({
    type: String,
    required: true,
  })
  classUuid!: string;

  @Prop({
    type: Object,
    required: true,
  })
  classInfo!: ClassData;

  @Prop({
    type: Boolean,
    required: true,
  })
  loadingSummary!: boolean;

  @Watch("classInfo")
  classInfoWatcher(value: ClassData) {
    this.getStudents(value);
  }

  @Watch("loadingSummary")
  loadingSummaryWatcher(value: ClassData) {
    if (!value && this.$store.state.teacherClass.classInformation) {
      this.getStudents(this.$store.state.teacherClass.classInformation);
    }
  }

  getStudents(data: ClassData) {
    this.classInformation = data;
    this.searchResult = structuredClone(data.students);
    this.filteredResult = structuredClone(data.students);
  }

  classInformation!: ClassData;

  eventIcons: Record<string, string> = EVENT_ICONS;
  isFull = true;
  hideHeader = true;
  data: IData[] = STUDENT_DATA;
  searchResult: ClassStudentsDetails[] = [];
  filteredResult: ClassStudentsDetails[] = [];
  loadingDeleteStudent = false;
  eventBus = emitter;
  sortDefault = {
    sortBy: "last_alphabetical",
    sortData: [
      { label: "First Name: Alphabetical", value: "first_alphabetical" },
      { label: "Last Name: Alphabetical", value: "last_alphabetical" },
      { label: "Email: Alphabetical", value: "email_alphabetical" },
      { label: "User Type", value: "user_type" },
    ],
  };
  isEllipsis = false;
  icons = AppIcons;

  selectedEditStudent!: ClassStudentsDetails;
  editModalVisible = false;
  selectedDeleteStudent!: ClassStudentsDetails;
  deleteModalVisible = false;

  moreOptions = [
    { name: "Edit Student", icon: "pencilBlack" },
    {
      name: "Remove From Class",
      icon: "trashSmallDarkRedIcon",
    },
  ];

  handleClickMenu(action: string, data: ClassStudentsDetails) {
    if (action === "Edit Student") {
      this.handleEditStudent(data);
    } else {
      this.handleDeleteStudent(data);
    }
  }

  handleMouseEnter(e: any) {
    try {
      if (e.target) {
        var tolerance = 1;
        const isEllipsis =
          e.target.offsetWidth + tolerance < e.target.scrollWidth;
        if (isEllipsis) {
          this.isEllipsis = true;
        }
      }
    } catch (err) {
      console.error(err);
    }
  }

  handleEditStudent(data: ClassStudentsDetails) {
    this.eventBus.emit("EVENT_TRIGGER", "CP019");
    this.selectedEditStudent = data;
    this.editModalVisible = true;
  }

  handleDeleteStudent(data: ClassStudentsDetails) {
    this.selectedDeleteStudent = data;
    this.deleteModalVisible = true;
  }

  handleHideModal() {
    this.deleteModalVisible = false;
    this.editModalVisible = false;
  }

  async handleDelete() {
    this.loadingDeleteStudent = true;
    const params: DeleteStudentParams = {
      classUuid: this.classUuid,
      studentUuid: this.selectedDeleteStudent.uuid,
    };
    await this.$store.dispatch(deleteStudentPerClass, params);
    this.loadingDeleteStudent = false;
    this.eventBus.emit("EVENT_TRIGGER", "CP015");
    this.eventBus.emit("REFRESH_ASSIGNMENT");
    this.handleHideModal();
  }

  handleSort(sort: string) {
    const searchResultClone = structuredClone(this.searchResult);
    this.searchResult = sortMembersTable(sort, searchResultClone);
  }

  handleSearch(searchText: string) {
    if (this.filteredResult && this.filteredResult.length > 0) {
      if (searchText) {
        let searchDataClone: ClassStudentsDetails[] = [];
        if (this.classInformation) {
          searchDataClone = structuredClone(this.classInformation.students);
        } else {
          searchDataClone = structuredClone(this.filteredResult);
        }
        this.searchResult = structuredClone(
          searchDataClone.filter((item: ClassStudentsDetails) => {
            return (
              item.profile.lastName
                .toLowerCase()
                .includes(searchText.toLowerCase()) ||
              item.profile.firstName
                .toLowerCase()
                .includes(searchText.toLowerCase()) ||
              (item.profile.email &&
                item.profile.email
                  .toLowerCase()
                  .includes(searchText.toLowerCase()))
            );
          })
        );
      } else {
        if (this.classInformation) {
          this.searchResult = structuredClone(this.classInformation.students);
        } else {
          this.searchResult = structuredClone(this.filteredResult);
        }
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.bordered {
  border: 1px solid #d9d9d9;
}
.actions {
  &-cell {
    left: 0;
    top: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    padding: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &-body {
    background-color: #ffc9ad;
  }
}
.action-icon-container {
  max-height: 24px;
  max-width: 24px;
  margin: 0 auto;
}
</style>
