import { EventDetail, EventName } from "../type";

const teacherDashboardEvents = (eventName: EventName): EventDetail[] => [
  {
    id: "TD001",
    name: eventName.page,
    description: "Visit create assignment page",
  },
  {
    id: "TD002",
    name: eventName.button,
    description: "Open create class modal",
  },
  {
    id: "TD003",
    name: eventName.page,
    description: "View class page assignment",
  },
  {
    id: "TD004",
    name: eventName.button,
    description: "Hide welcome video modal",
  },
  {
    id: "TD005",
    name: eventName.view,
    description: "Welcome video watched",
  },
];

export default teacherDashboardEvents;
