<template>
  <div
    v-show="selected === 2"
    class="flex items-center justify-center gap-4 bg-[#EAE5FF] rounded-lg h-full flex-col px-20"
  >
    <p class="text-flohh-text-body leading-[1.5] font-medium">
      Upload handwritten or digital submissions in bulk to get started with
      marking and make the most of Flohh’s time-saving tools.
      <strong>Hint:</strong> upload at least 2 submissions to check off this
      task.
    </p>
    <AppButton
      class="!bg-[#CEC3FF] hover:brightness-105 w-full"
      @click="handleUploadSubmission"
      >Upload submissions</AppButton
    >
  </div>

  <UploadSubmissionModal v-model="uploadSubmissionModalOpen" />
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-facing-decorator";
import AppButton from "../../Layout/Buttons/AppButton.vue";

import UploadSubmissionModal from "../../utilities/UploadSubmissionModal.vue";

@Component({
  components: {
    AppButton,
    UploadSubmissionModal,
  },
})
export default class TaskUploadSubmission extends Vue {
  @Prop({
    type: Number,
    default: 1,
  })
  selected!: number;

  uploadSubmissionModalOpen = false;

  handleUploadSubmission() {
    this.uploadSubmissionModalOpen = true;
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
