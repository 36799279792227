import { resolveComponent as _resolveComponent, createVNode as _createVNode, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppTypographyText = _resolveComponent("AppTypographyText")!

  return (_ctx.firstName && _ctx.lastName)
    ? (_openBlock(), _createElementBlock("div", _mergeProps({
        key: 0,
        class: "avatar",
        style: {
      width: _ctx.width,
      backgroundColor: _ctx.getBgColor(),
    }
      }, _ctx.$attrs), [
        _createVNode(_component_AppTypographyText, {
          label: _ctx.generateInitials(_ctx.firstName, _ctx.lastName),
          type: _ctx.variant
        }, null, 8, ["label", "type"])
      ], 16))
    : _createCommentVNode("", true)
}