<template>
  <ModalUtility :title="title" v-model="isVisible">
    <template #content>
      <div class="bg-white w-full px-4 py-2">
        <form
          @submit.prevent="onSubmit"
          autocomplete="off"
          class="w-full py-10 md:px-8 lg:px-8 pb-0"
        >
          <div class="flex flex-col lg:flex-row lg:gap-5">
            <div
              class="col-span-1 w-full flex justify-start flex-col items-start mb-4"
            >
              <p class="font-semibold text-sm mb-1">Email</p>
              <FormTextInput
                type="email"
                v-model="form.email"
                placeholder="Enter Email"
                :invalid="errMsg.email ? true : false"
                name="email"
                :disabled="isEdit"
              />
              <ErrorSpan v-if="errMsg.email">
                {{ errMsg.email }}
              </ErrorSpan>
            </div>
          </div>
          <div class="flex flex-col lg:flex-row lg:gap-5">
            <div
              class="col-span-1 w-full flex justify-start flex-col items-start mb-4"
            >
              <p class="font-semibold text-sm mb-1">First Name</p>
              <FormTextInput
                type="text"
                v-model="form.firstName"
                placeholder="Enter First Name"
                :invalid="errMsg.firstName ? true : false"
                name="firstName"
              />
              <ErrorSpan v-if="errMsg.firstName">
                {{ errMsg.firstName }}
              </ErrorSpan>
            </div>
            <div
              class="col-span-1 w-full flex justify-start flex-col items-start mb-4"
            >
              <p class="font-semibold text-sm mb-1">Last Name</p>
              <FormTextInput
                type="text"
                v-model="form.lastName"
                placeholder="Enter Last Name"
                :invalid="errMsg.lastName ? true : false"
                name="lastName"
              />
              <ErrorSpan v-if="errMsg.lastName">
                {{ errMsg.lastName }}
              </ErrorSpan>
            </div>
          </div>

          <div v-if="isEdit" class="flex flex-col lg:flex-row lg:gap-5 mb-10">
            <div
              class="col-span-1 w-full flex justify-start flex-col items-start mb-4"
            >
              <p class="font-semibold text-sm mb-1">Password</p>
              <FormTextInput
                type="password"
                v-model="form.password"
                placeholder="Enter Password"
                :invalid="errMsg.password ? true : false"
                name="password"
              />
              <ErrorSpan v-if="errMsg.password">
                {{ errMsg.password }}
              </ErrorSpan>
            </div>
            <div
              class="col-span-1 w-full flex justify-start flex-col items-start mb-4"
            >
              <p class="font-semibold text-sm mb-1">Confirm Password</p>
              <FormTextInput
                type="password"
                v-model="form.confirmPassword"
                placeholder="Confirm Password"
                :invalid="errMsg.confirmPassword ? true : false"
                name="confirmPassword"
              />
              <ErrorSpan v-if="errMsg.confirmPassword">
                {{ errMsg.confirmPassword }}
              </ErrorSpan>
            </div>
          </div>

          <div
            v-if="!fetchingSubscription && isEdit && userType === 'teacher'"
            class="flex flex-col gap-5"
          >
            <div class="flex">
              <div class="flex-1">
                <p class="font-semibold text-sm mb-1">Submission Credits</p>
                <h6 class="font-bold text-flohh-h6 mb-1">
                  {{ availableCredits }}
                </h6>
              </div>
              <div class="flex-1">
                <p class="font-semibold text-sm mb-1">Plan</p>
                <h6 class="font-bold text-flohh-h6 mb-1">{{ planName }}</h6>
              </div>
            </div>
            <div
              class="flex gap-[20px] w-full items-end"
              v-if="planSlug === 'free'"
            >
              <div class="flex flex-col w-full">
                <p class="font-semibold text-sm mb-1">Allocate credits</p>
                <FormTextInput
                  type="number"
                  v-model="allocateCredits"
                  placeholder="Enter the number of submission credits you’d like to assign to this user"
                  :invalid="errMsg.lastName ? true : false"
                  name="allocateCredits"
                  :min="0"
                />
                <ErrorSpan v-if="errMsg.lastName">
                  {{ errMsg.lastName }}
                </ErrorSpan>
              </div>
              <AppButton
                type="submit"
                :loading="isAllocating"
                @click="
                (e: any) => {
                  e.preventDefault();
                  handleAllocateCredits()
                }
              "
              >
                <template #icon_left v-if="!isAllocating">
                  <span v-html="icon.checkBlack"></span>
                </template>
                Allocate
              </AppButton>
            </div>
          </div>
          <ProgressLoader
            v-if="fetchingSubscription && isEdit && userType === 'teacher'"
            label="Loading..."
          />

          <Divider />
          <div class="w-full flex justify-end items-center pb-4">
            <AppButton type="submit" :loading="isLoading">
              <template #icon_left>
                <span v-html="icon.checkBlack"></span>
              </template>
              Save
            </AppButton>
          </div>
        </form>
      </div>
    </template>
  </ModalUtility>
</template>

<script lang="ts">
import { ref } from "vue";
import { Component, Prop, Vue, Model, Watch } from "vue-facing-decorator";
import { IAutoCompleteCompleteEvent, IMenuRef } from "./types";
import { useToast } from "primevue/usetoast";
import AppButton from "../Layout/Buttons/AppButton.vue";
import { icons as AppIcons } from "@/utils/icons";
import { IData, UserData } from "./types";
import ModalUtility from "../utilities/ModalUtility.vue";
import ErrorSpan from "@/components/utilities/ErrorSpan.vue";
import {
  IRegistrationInfo,
  TRegistrationInfo,
} from "../Authentication/Registration/types";
import FormTextInput from "../Layout/Forms/FormTextInput.vue";
import TeacherService from "@/services/TeacherService";
import SuperAdminService from "@/services/SuperAdminService";
import AuthService from "@/services/AuthService";
import { validateEmail, validatePassword } from "@/utils/helper";
import Divider from "primevue/divider";
import axios, { AxiosResponse } from "axios";
import ProgressLoader from "../utilities/ProgressLoader.vue";

interface IItem {
  label: string;
  value: string;
}

interface IFormData extends IErrorMessage {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirmPassword: string;
}

interface IErrorMessage {
  [key: string]: string;
}

@Component({
  components: {
    AppButton,
    ModalUtility,
    FormTextInput,
    ErrorSpan,
    Divider,
    ProgressLoader,
  },
})
export default class SuperAdminUserModal extends Vue {
  private teacherService: TeacherService = new TeacherService();
  private superAdminService: SuperAdminService = new SuperAdminService();
  private authService: AuthService = new AuthService();

  @Model({
    default: false,
    required: false,
  })
  isVisible!: false;

  @Prop({
    default: null,
    required: false,
  })
  userData!: UserData;

  @Prop({
    default: "",
    required: false,
  })
  title!: string;

  @Prop({
    default: false,
    required: false,
  })
  isEdit!: boolean;

  @Prop({
    default: "teacher",
    required: true,
  })
  userType!: "student" | "teacher";

  @Prop({
    type: Array,
    required: true,
  })
  students!: UserData[];

  @Prop({
    type: Function,
    required: false,
  })
  private onUpdateDetails!: (uuid: string, updated: any) => void;

  icon = AppIcons;
  toast = useToast();

  isAllocating = false;

  form: IRegistrationInfo = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
  };

  validationMessage!: TRegistrationInfo;

  errMsg: IFormData = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
  };
  isLoading = false;

  allocateCredits!: number;

  fetchingSubscription = true;
  availableCredits = 0;
  planName = "";
  planSlug = "";
  subscriptionUuid = "";

  async getTeacherSubscription() {
    if (
      this.isVisible &&
      this.userData.userUuid &&
      this.userType === "teacher"
    ) {
      try {
        this.fetchingSubscription = true;
        const response =
          await this.superAdminService.getTeacherActiveSubscription(
            this.userData.userUuid
          );

        if (response.data.ok) {
          const data = response.data.data;
          this.availableCredits = data.credits.available;
          this.planName = data.subscription.plan.name;
          this.planSlug = data.subscription.plan.slug;
          this.subscriptionUuid = data.subscription.uuid;
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.fetchingSubscription = false;
      }
    }
  }

  mounted() {
    //
    if (this.userData) {
      this.form.firstName = this.userData.firstName;
      this.form.lastName = this.userData.lastName;
      this.form.email = this.userData.workEmail;
      this.getTeacherSubscription();
    }
  }

  showToast(
    severity: "success" | "info" | "warn" | "error" | undefined,
    message: string
  ) {
    this.toast.add({
      severity: severity,
      detail: message,
      life: 3000,
    });
  }

  areAllValuesEmpty<T extends Record<string, string>>(obj: T): boolean {
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key) && obj[key] !== "") {
        return false;
      }
    }
    return true;
  }

  handleValidation(data: TRegistrationInfo) {
    this.validationMessage = data;
  }

  handleSave(data: any) {
    //
  }

  async handleAllocateCredits() {
    //

    if (this.allocateCredits && this.userData.uuid) {
      if (this.allocateCredits > 0) {
        this.isAllocating = true;
        const resCredits = await this.teacherService.postAllocateCredits(
          this.userData.uuid,
          {
            numberOfCredits: Number(this.allocateCredits),
            subscriptionUuid: this.subscriptionUuid,
          }
        );
        this.isAllocating = false;

        if (resCredits.data.ok) {
          this.$emit("onUpdateCredits", {
            uuid: this.userData.uuid,
            numberOfCredits: Number(this.allocateCredits),
            subscriptionUuid: this.subscriptionUuid,
          });
          this.resetState();

          this.showToast("success", "Teacher credits has been allocated.");
        }
      } else {
        this.showToast("error", "Please enter a number greater than 0.");
      }
    } else {
      this.showToast("error", "Please enter a number of submission credits.");
    }
  }

  @Watch("userData")
  userDataWatcher() {
    this.form.firstName = this.userData.firstName;
    this.form.lastName = this.userData.lastName;
    this.form.email = this.userData.workEmail;
  }
  @Watch("form.firstName")
  formFirstNameWatcher(newValue: string) {
    this.onInput("firstName", newValue);
  }
  @Watch("form.lastName")
  formLastNameWatcher(newValue: string) {
    this.onInput("lastName", newValue);
  }
  @Watch("form.email")
  formEmailWatcher(newValue: string) {
    this.onInput("email", newValue);
  }

  @Watch("form.password")
  formPasswordWatcher(newValue: string) {
    this.onInput("password", newValue);
  }
  @Watch("form.confirmPassword")
  formConfirmPasswordWatcher(newValue: string) {
    this.onInput("confirmPassword", newValue);
  }
  onInput(name: string, value: string) {
    if (name) {
      if (value) {
        this.errMsg[name] = "";

        if (name.toLocaleLowerCase().search("email") > -1) {
          this.errMsg[name] = validateEmail(value);
        } else if (name.toLocaleLowerCase().search("password") > -1) {
          this.errMsg[name] = validatePassword(value);
        } else {
          //
        }

        if (this.form.password && this.form.confirmPassword) {
          const validPassword =
            validatePassword(this.form.password) &&
            validatePassword(this.form.confirmPassword);
          const doPasswordsMatch =
            this.form.password === this.form.confirmPassword;
          if (!validPassword && !doPasswordsMatch) {
            this.errMsg.confirmPassword =
              "Password and Confirm Password should match";
          } else {
            this.errMsg.confirmPassword = validPassword;
          }
        }
      } else {
        this.errMsg[name] = "Field is required";
      }
    }
    this.handleValidation(this.errMsg);
    // }, 200);
  }

  editUserValidation(payload: IRegistrationInfo) {
    if (payload.email !== this.userData.workEmail) return false;
    if (payload.password && !payload.confirmPassword) return false;
    return true;
  }

  onSubmit() {
    if (this.userType === "teacher") {
      this.onSubmitTeacher();
    } else {
      this.onSubmitStudent();
    }
  }

  public async onSubmitTeacher() {
    this.isLoading = true;
    const {
      firstName,
      lastName,
      email,
      password,
      confirmPassword,
    }: IRegistrationInfo = this.form;

    const payload = {
      firstName,
      lastName,
      email,
      password,
      confirmPassword,
    };

    try {
      if (this.isEdit) {
        const editPayload = {
          firstName,
          lastName,
          email,
          password,
          confirmPassword,
          teacherUuid: this.userData.uuid,
        };
        const isValid: boolean = this.editUserValidation(editPayload);
        if (isValid) {
          // if (this.allocateCredits && this.userData.uuid) {
          //   const resCredits = await this.teacherService.postAllocateCredits(
          //     this.userData.uuid,
          //     {
          //       numberOfCredits: Number(this.allocateCredits),
          //       subscriptionUuid: this.userData?.plan?.uuid || "",
          //     }
          //   );
          //   if (resCredits.data.ok) {
          //     this.$emit("onUpdateCredits", {
          //       uuid: this.userData.uuid,
          //       numberOfCredits: Number(this.allocateCredits),
          //       subscriptionUuid: this.userData?.plan?.uuid || "",
          //     });
          //   }
          // }

          if (password && confirmPassword && password === confirmPassword) {
            await this.authService.superAdminUpdatePassword({
              userUuid: this.userData.userUuid,
              newPassword: confirmPassword,
            });
          }

          const response = await this.teacherService.putTeacher(editPayload);
          if (response.data.ok) {
            this.onUpdateDetails(this.userData.uuid, editPayload);
            this.resetState();
            this.showToast("success", "Teacher details updated successfully");
          } else {
            console.error(response.data.message);
            this.showToast("error", response.data.message);
          }
        } else {
          this.showToast("error", "Please complete the fields.");
        }
      } else {
        const allValid: boolean = this.areAllValuesEmpty(
          this.validationMessage
        );
        const allEmpty: boolean = this.areAllValuesEmpty(payload);
        if (allValid && !allEmpty) {
          const payload = {
            firstName: this.form.firstName,
            lastName: this.form.lastName,
            email: this.form.email,
            password: "",
            workEmail: this.form.email,
            schoolName: "",
          };
          const response: any = await axios
            .post("/super-admin/teachers", payload)
            .catch((error) => {
              if (error.response.status === 422) {
                this.showToast("error", error.message);
              }
            });
          if (response.data.ok) {
            this.resetState();
            this.showToast(
              "success",
              "An email has been sent to the email address of the teacher"
            );
          } else {
            console.error(response.data.message);
            this.showToast("error", response.data.message);
          }
        } else {
          this.showToast("error", "Please complete the fields.");
        }
      }
    } catch (error) {
      this.isLoading = false;
      if (error instanceof ReferenceError) {
        console.error(error.message);
      } else {
        // throw error;
      }
    }
    this.isLoading = false;
  }

  public async onSubmitStudent() {
    this.isLoading = true;
    const {
      firstName,
      lastName,
      email,
      confirmEmail,
      password,
      confirmPassword,
    }: IRegistrationInfo = this.form;

    // const payload = {
    //   firstName,
    //   lastName,
    //   email,
    //   confirmEmail,
    //   password,
    //   confirmPassword,
    // };

    try {
      if (this.isEdit) {
        const editPayload = {
          firstName,
          lastName,
          email,
          confirmEmail,
          password,
          confirmPassword,
        };
        const isValid: boolean = this.editUserValidation(editPayload);
        if (isValid) {
          if (password && confirmPassword && password === confirmPassword) {
            await this.authService.superAdminUpdatePassword({
              userUuid: this.userData.userUuid,
              newPassword: confirmPassword,
            });
          }
          const response = await this.superAdminService.putStudent(
            editPayload,
            this.userData.uuid
          );
          if (response.data.ok) {
            this.onUpdateDetails(this.userData.uuid, editPayload);
            this.resetState();
            this.showToast("success", "Student details updated successfully");
          } else {
            console.error(response.data.message);
            this.showToast("error", response.data.message);
          }
        } else {
          this.showToast("error", "Please complete the fields.");
        }
      } else {
        // Add Student
      }
    } catch (error) {
      this.isLoading = false;
      if (error instanceof ReferenceError) {
        console.error(error.message);
      } else {
        // throw error;
      }
    }
    this.isLoading = false;
  }

  resetState() {
    this.form.firstName = "";
    this.form.lastName = "";
    this.form.email = "";
    this.form.confirmEmail = "";
    this.form.password = "";
    this.form.confirmPassword = "";
    this.isVisible = false;
  }

  toggleMenu(event: Event, index: number) {
    const ref = this.$refs["menu-" + index] as IMenuRef | undefined;
    if (ref) {
      ref.toggle(event);
    }
  }
}
</script>
