<script lang="ts">
import { Vue, Component, Model, Emit, Watch, Prop } from "vue-facing-decorator";
import FormField from "../Layout/Forms/FormField.vue";
import ClassInformation from "./ClassInformation.vue";
import ModalUtility from "../utilities/ModalUtility.vue";
import AppTypographyText from "../Layout/Typhography/AppTypographyText.vue";
import FileUploader from "../utilities/FileUploader.vue";
import { Icon, icons } from "@/utils/icons";
import AppButton from "../Layout/Buttons/AppButton.vue";
import ClassService from "@/services/ClassService";
import { ClassInfo } from "@/store/createClass/types";
import emitter from "@/config/emitter";
import { useToast } from "primevue/usetoast";
import { AxiosResponse } from "axios";
import * as XLSX from "xlsx";

interface FailedEmails {
  error: string;
  name: string;
  email: string;
}

@Component({
  components: {
    FormField,
    ClassInformation,
    ModalUtility,
    AppTypographyText,
    FileUploader,
    AppButton,
  },
})
export default class CreateClassUploadPopup extends Vue {
  @Model({
    type: Boolean,
  })
  openDialog!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  isEdit!: boolean;

  private classService = new ClassService();

  selectedFileModel: File[] = [];
  icon: Icon = icons;
  loadingImport = false;
  eventBus = emitter;
  duplicateEmails: string[] = [];
  hasDuplicates = false;
  toast = useToast();

  failedEmails: FailedEmails[] = [];
  failedReason =
    "Please make sure that all columns in your Excel/CSV file has no empty fields";

  handleCloseCreateClass() {
    this.$store.dispatch("createClassPopup/resetClass");
  }

  handleRemoveFile() {
    this.$store.dispatch("createClassPopup/removeFile");
    this.duplicateEmails = [];
  }

  @Emit("onSkipOrGoBack")
  handleClickSkipOrGoBack(index = 0) {
    return index;
  }

  @Watch("openDialog")
  openDialogWatcher(value: boolean) {
    if (!value) {
      this.failedEmails = [];
    }
  }

  @Emit("onSaveFileUploadStudent")
  handleOnSaveFileUploadStudent() {
    const classInfo: ClassInfo =
      this.$store.getters["createClassPopup/classInformation"];
    if (classInfo && classInfo.uuid && !this.isEdit) {
      if (this.$route.query && this.$route.query.assignment) {
        this.$router.push({
          name: "Classes",
          params: { id: classInfo.uuid },
          query: { assignment: this.$route.query.assignment },
        });
        return;
      }
      this.$router.push({
        name: "Classes",
        params: { id: classInfo.uuid },
      });
    }
    if (this.isEdit) {
      this.eventBus.emit("LOAD_CLASSES");
    }
    return;
  }

  async handleClickSave() {
    if (this.selectedFileModel.length === 0) {
      this.toast.add({
        severity: "error",
        summary: "Csv file not found",
        detail: "Attach your list of student CSV file",
        life: 3000,
      });
      return;
    }

    if (
      this.selectedFileModel[0].type ===
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      await this.checkForDuplicateEmails(this.selectedFileModel[0]);
    } else if (
      this.selectedFileModel[0].type === "text/csv" ||
      this.selectedFileModel[0].type === "application/vnd.ms-excel"
    ) {
      const content = await this.readCSV(this.selectedFileModel[0]);
      const parseStatus = this.parseCSV(content);
      if (!parseStatus) {
        return;
      }
    } else {
      this.toast.add({
        severity: "error",
        summary: "File Error",
        detail: "Please select CSV or Excel file",
        life: 3000,
      });
      return;
    }

    if (this.duplicateEmails.length) {
      this.toast.add({
        severity: "error",
        summary: "Duplicate Email",
        detail: "The system found a duplicate email",
        life: 3000,
      });
      return;
    }
    this.$store.dispatch("createClassPopup/setFile", this.selectedFileModel);
    await this.onSaveFileUploadStudent();
  }

  async onSaveFileUploadStudent() {
    try {
      this.loadingImport = true;
      const classInfo: ClassInfo = structuredClone(
        this.$store.getters["createClassPopup/classInformation"]
      );
      const formData = new FormData();
      const file = structuredClone(this.selectedFileModel[0]);
      formData.append("file", file);

      const response: AxiosResponse =
        await this.classService.postFileImportStudents(
          classInfo.uuid as string,
          formData
        );

      if (response.data.ok) {
        const failedEmails = response.data.data.filter(
          (item: Record<string, unknown>) => item.ok !== undefined && !item.ok
        );

        if (failedEmails.length > 0) {
          const failedEmailsArray: FailedEmails[] = [];
          failedEmails.forEach((item: Record<string, any>) => {
            if (item.message.includes("already in class")) {
              this.failedReason = "Students already in class";
            }
            failedEmailsArray.push({
              error: item.code,
              name: `${item.info.info.firstName} ${item.info.info.lastName}`,
              email: item.info.info.email,
            });
          });
          this.failedEmails = failedEmailsArray;
        } else {
          this.openDialog = false;
        }
      }
      this.eventBus.emit("REFRESH_SELECTED_CLASS");
      this.handleOnSaveFileUploadStudent();
    } catch (error) {
      console.error(error);
    } finally {
      this.loadingImport = false;
    }
  }

  downloadTemplate() {
    const url = "/template/student-template-csv.csv";
    const fileName = "Flohh Class List";

    // Create a link element
    const link = document.createElement("a");
    link.href = url;
    link.download = fileName;

    // Append the link to the document and trigger a click
    document.body.appendChild(link);
    link.click();

    // Remove the link from the document
    document.body.removeChild(link);
  }

  readCSV(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (e) => {
        if (e.target) {
          const content: string = e.target.result as string;
          resolve(content);
        }
      };

      reader.onerror = (e) => {
        reject(e);
      };

      reader.readAsText(file);
    });
  }

  parseCSV(content: string) {
    const rows = content.split("\n");
    const emails: string[] = [];

    // Extract emails assuming they are in the first column

    try {
      const invalidColumnsError =
        "Please make sure you have the following columns in order: firstName, lastName, studentId, email";
      rows.forEach((row, index) => {
        if (row !== "") {
          const columns = row.split(",");
          if (columns.length > 3) {
            if (index === 0) {
              if (!columns.includes("lastName")) {
                throw new Error("Missing column lastName");
              } else if (!columns.includes("firstName")) {
                throw new Error("Missing column firstName");
              } else if (!columns.includes("studentId")) {
                throw new Error("Missing column studentId");
              } else if (!columns[3].includes("email")) {
                throw new Error("Missing column email");
              }
              if (
                columns[0] !== "firstName" ||
                columns[1] !== "lastName" ||
                columns[2] !== "studentId" ||
                !columns[3].includes("email")
              ) {
                throw new Error(invalidColumnsError);
              }
            }
            const email = columns[3].trim();
            emails.push(email);
          } else {
            throw new Error(invalidColumnsError);
          }
        }
      });
    } catch (error: any) {
      this.showMessage("error", "Failed", "Invalid column");
      return false;
    }

    // Check for duplicates
    const uniqueEmails: Set<string> = new Set();
    const duplicates: Set<string> = new Set();

    emails.forEach((email) => {
      if (uniqueEmails.has(email)) {
        duplicates.add(email);
      } else {
        uniqueEmails.add(email);
      }
    });

    // Update the component state with duplicate emails
    this.duplicateEmails = Array.from(duplicates);
    return true;
  }

  private readFileAsync(file: File): Promise<ArrayBuffer> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (e: ProgressEvent<FileReader>) => {
        resolve(e.target?.result as ArrayBuffer);
      };

      reader.onerror = (e) => {
        reject(e);
      };

      reader.readAsArrayBuffer(file);
    });
  }

  private async checkForDuplicateEmails(file: File): Promise<void> {
    try {
      const data = await this.readFileAsync(file);
      const workbook = XLSX.read(data, { type: "array" });

      // Assuming the first sheet is your data
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];

      // Convert the sheet to an array of rows
      const rows: any = XLSX.utils.sheet_to_json(sheet, { header: 1 });

      const emails: string[] = [];
      // Check for duplicates
      const uniqueEmails: Set<string> = new Set();
      const duplicates: Set<string> = new Set();

      for (let i = 1; i < rows.length; i++) {
        const email = rows[i][3] as string;
        emails.push(email);
      }

      emails.forEach((email) => {
        if (uniqueEmails.has(email)) {
          duplicates.add(email);
        } else {
          uniqueEmails.add(email);
        }
      });

      // Update the component state with duplicate emails
      this.duplicateEmails = Array.from(duplicates);
    } catch (error) {
      console.error("Error reading file:", error);
      // Handle the error as needed
    }
  }

  mounted() {
    if (this.$store.state.createClassPopup.file) {
      this.selectedFileModel = structuredClone(
        this.$store.state.createClassPopup.file
      );
    }
  }

  showMessage(type: "success" | "error", title: string, message: string) {
    this.toast.add({
      severity: type,
      summary: title,
      detail: message,
      life: 5000,
    });
  }
}
</script>
<template>
  <ModalUtility
    v-model="openDialog"
    width="45vw"
    title="Add your students"
    @onClose="handleCloseCreateClass"
  >
    <template #content>
      <div v-if="failedEmails.length > 0" class="px-8 py-4">
        <AppTypographyText
          label="Unable to import the following students"
          variant="md"
          type="body"
          class="mb-2"
        />
        <AppTypographyText
          :label="failedReason"
          variant="md"
          type="caption"
          class="mb-5"
        />
        <AppTypographyText
          v-for="(account, index) in failedEmails"
          :key="index"
          :label="`• ${account.name} (${account.email})`"
          variant="rg"
          type="caption"
          class="pl-2"
        />
      </div>
      <div v-else class="px-4">
        <div class="py-2 mt-5">
          <FormField label="Class Information">
            <ClassInformation />
          </FormField>
        </div>
        <div class="w-full mt-5">
          <AppTypographyText
            variant="bd"
            type="title"
            label="Upload your class list"
            class="my-[8px]"
          />
          <div class="w-full mt-3">
            <AppTypographyText variant="md" type="subtitle">
              Your class list should have the following columns: First name,
              Last name, student ID, and email. You can download a template.
            </AppTypographyText>
            <a
              class="flex justify-center items-center gap-x-[8px] rounded-[8px] font-flohh-font-medium px-[16px] py-[8px] bg-flohh-secondary-green hover:bg-flohh-secondary-green-dark text-flohh-text-caption h-[34px] mt-2 w-64"
              href="/template/student-template-csv.csv"
              download="Flohh Class List.csv"
              type="csv"
            >
              Click here to start the download.
            </a>
            <div class="my-2 mb-5">
              <FileUploader
                v-model:selectedFiles="selectedFileModel"
                :multiple="false"
                :showFileList="true"
                acceptedFiles=".csv, .xlsx"
                supportedFileTypes="Supported file types: Microsoft excel & CSV"
                :hideGoogleDrive="true"
                @onRemoveFile="handleRemoveFile"
              />
            </div>
            <div
              class="mb-2 gap-x-2 flex flex-row"
              v-if="duplicateEmails.length"
            >
              <AppTypographyText
                label="Duplicate Emails:"
                type="caption"
                variant="bd"
              />
              <AppTypographyText
                v-for="(email, index) in duplicateEmails"
                :key="index"
                :label="email"
                variant="md"
                :isError="true"
                type="caption"
              />
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <div
        v-if="failedEmails.length > 0"
        class="flex flex-row pt-5 border-t border-solid border-flohh-neutral-85 justify-end"
      >
        <AppButton type="primary" label="Close" @click="openDialog = false">
          <template #icon_left>
            <span v-html="icon.closeBlack"></span>
          </template>
        </AppButton>
      </div>
      <div
        v-else
        class="flex flex-row pt-5 border-t border-solid border-flohh-neutral-85"
      >
        <div class="flex-1 flex justify-start items-center">
          <AppButton text @click="handleClickSkipOrGoBack()">
            Go Back
          </AppButton>
        </div>
        <div class="flex-1 flex justify-end items-center gap-x-4">
          <AppButton text @click="handleClickSkipOrGoBack(1)" v-if="!isEdit">
            Skip this step
          </AppButton>
          <AppButton
            type="submit"
            :loading="loadingImport"
            label="Save"
            @click="handleClickSave"
          >
            <template #icon_left>
              <span v-html="icon.checkBlack"></span>
            </template>
          </AppButton>
        </div>
      </div>
    </template>
  </ModalUtility>
</template>
