import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "text-flohh-text-small" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", _mergeProps(_ctx.$attrs, {
    class: [
      {
        'bg-flohh-tertiary-light-blue': _ctx.color === 'blue',
        'border-flohh-tertiary-blue': _ctx.color === 'blue',
        'bg-flohh-tertiary-light-yellow': _ctx.color === 'yellow',
        'border-flohh-tertiary-yellow': _ctx.color === 'yellow',
        'bg-flohh-tertiary-light-purple': _ctx.color === 'purple',
        'border-flohh-tertiary-purple': _ctx.color === 'purple',
        'bg-flohh-tertiary-light-green': _ctx.color === 'green',
        'border-flohh-tertiary-green': _ctx.color === 'green',
      },
      'border-solid rounded-lg cursor-pointer border-[10px] py-2 px-[1.9em] hover:brightness-105',
    ]
  }), [
    _createElementVNode("p", {
      class: _normalizeClass([
        {
          'text-flohh-text-title': _ctx.type === 'title',
          'text-flohh-text-body': _ctx.type === 'body',
          'text-flohh-text-subtitle': _ctx.type === 'subtitle',
          'text-flohh-text-caption': _ctx.type === 'caption',
          'text-flohh-text-small': _ctx.type === 'small',
          'font-flohh-font-bold': _ctx.variant === 'bd',
          'font-flohh-font-medium': _ctx.variant === 'md',
          'font-flohh-font-regular': _ctx.variant === 'rg',
        },
      ])
    }, _toDisplayString(_ctx.label), 3),
    _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.sublabel), 1)
  ], 16))
}