<template>
  <div
    v-show="selected === 4"
    class="flex items-center justify-center gap-4 bg-[#EAE5FF] rounded-lg h-full flex-col px-20"
  >
    <p class="text-flohh-text-body leading-[1.5] font-medium">
      Download marked submissions as PDFs or send feedback to students via email
      to complete your feedback tasks.
    </p>
    <AppButton
      class="!bg-[#CEC3FF] hover:brightness-105 w-full"
      @click="handleReturnFeedback"
      :disabled="loading"
      :loading="loading"
      >Select an assignment folder to return</AppButton
    >
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-facing-decorator";
import AppButton from "../../Layout/Buttons/AppButton.vue";
import { AssignmentDashboard } from "@/store/dashboard/dashboardTypes";
import { PendingActions } from "@/store/dashboard/dashboardTypes";

@Component({
  components: {
    AppButton,
  },
})
export default class TaskReturnFeedback extends Vue {
  @Prop({
    type: Number,
    default: 1,
  })
  selected!: number;

  @Prop({
    type: Array,
    required: true,
  })
  assignments!: AssignmentDashboard[];

  @Prop({
    type: Array,
    required: true,
  })
  onboardingChecklist: PendingActions[] = [];

  loading = false;
  classUuid = "";
  assignmentUuid = "";

  mounted() {
    this.getAssignmentUuid();
  }

  getAssignmentUuid() {
    const task = this.onboardingChecklist.find(
      (item: PendingActions) => item.type === "onboardingReturnSubmission"
    );

    if (task && task.data) {
      const markedAssignments = task.data.markedAssignments;
      if (markedAssignments) {
        let assignmentUuid = "";
        if (markedAssignments.length === 1)
          assignmentUuid = markedAssignments[0];
        if (markedAssignments.length > 1) assignmentUuid = markedAssignments[1];
        this.getAssignmentData(assignmentUuid);
      }
    }
  }

  async getAssignmentData(assignmentUuid: string) {
    const assignments = this.assignments;

    const selectedAssignment = assignments.find(
      (assignment: AssignmentDashboard) => assignment.uuid === assignmentUuid
    );

    if (selectedAssignment) {
      this.classUuid = selectedAssignment.class.uuid;
      this.assignmentUuid = assignmentUuid;
    }
  }

  handleReturnFeedback() {
    this.$router.push({
      name: "Classes",
      params: { id: this.classUuid },
      query: { assignment: this.assignmentUuid },
    });
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
