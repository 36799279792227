<script lang="ts">
import { Vue, Component, Prop } from "vue-facing-decorator";
import AppButton from "../Layout/Buttons/AppButton.vue";
import { icons as AppIcons } from "@/utils/icons";
import CreateClassPopup from "@/components/CreateClass/CreateClassPopup.vue";
import Toast from "primevue/toast";
import { useToast } from "primevue/usetoast";
import emitter from "@/config/emitter";

@Component({
  components: {
    AppButton,
    CreateClassPopup,
    Toast,
  },
})
export default class CreateClassButton extends Vue {
  @Prop({
    type: String,
    default: "submit",
  })
  type!: string;

  @Prop({
    type: String,
    default: "plusBlack",
  })
  icon!: string;

  @Prop({
    type: Boolean,
    default: false,
  })
  isIcon!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  isEdit!: boolean;

  eventBus = emitter;
  openClassDialog = false;
  icons = AppIcons;
  toast = useToast();

  handleOnCreateClass() {
    this.openClassDialog = false;
    if (this.isEdit) {
      // Edit event
    }
    this.$emit("onSaveCreateClass");
  }

  resetStore() {
    localStorage.removeItem("ClassInfo");
    this.$store.dispatch("createClassPopup/resetClass");
  }

  handleClickClass() {
    this.eventBus.emit("EVENT_TRIGGER", "TD002");
    this.openClassDialog = true;
    this.resetStore();
  }
}
</script>
<template>
  <slot :handleClickClass="handleClickClass">
    <AppButton
      :type="type"
      @click="handleClickClass"
      v-tooltip.top="{
        value: 'Create a new class',
        showDelay: 500,
      }"
    >
      <span v-html="icons[icon]"></span>
      <template v-if="!isIcon">Class</template>
    </AppButton>
  </slot>
  <CreateClassPopup
    v-model="openClassDialog"
    @onCreateClass="handleOnCreateClass"
    :isEdit="isEdit"
  />
</template>
