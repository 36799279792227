<template>
  <div v-if="!routesExcluded.includes(routeName)" class="flex flex-col">
    <div
      v-if="getStartedBannerShow"
      class="flex items-center justify-between px-4 py-4 mx-4 mt-4 rounded-lg bg-flohh-secondary-blue"
    >
      <div class="flex items-center gap-2">
        <span v-html="icons.infoCircleBlack" />
        <p class="text-flohh-text-subtitle">
          New around here? Learn how to get the most out of Flohh with our
          <button
            class="underline hover:text-black"
            @click="handleOpenQuickStartGuide"
          >
            quick-start guide</button
          >.
        </p>
      </div>
      <button @click="handleHideGetStartedBanner">
        <span v-html="icons.closeBlack" />
      </button>
    </div>

    <div
      v-for="(item, index) in bulkUploads"
      :key="index"
      v-show="!getBulkUploadBanner(item.data.assignment)"
      class="flex items-center justify-between px-4 py-4 mx-4 mt-4 rounded-lg bg-flohh-secondary-blue"
    >
      <div class="flex items-center gap-2">
        <span v-html="icons.infoCircleBlack" />
        <p
          v-if="item.data.status === 'pending'"
          class="text-flohh-text-subtitle"
        >
          You have pending bulk upload for
          <button
            class="underline hover:text-black"
            @click="() => handleGoToBulkUpload(item.data.assignment)"
          >
            {{ item.data.assignmentName }}</button
          >.
        </p>
        <p v-else class="text-flohh-text-subtitle">
          Processing bulk upload for
          <button
            class="underline hover:text-black"
            @click="() => handleGoToBulkUpload(item.data.assignment)"
          >
            {{ item.data.assignmentName }}</button
          >.
        </p>
      </div>
      <button @click="handleHideBulkUploadBanner(item.data.assignment)">
        <span v-html="icons.closeBlack" />
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Watch, Prop } from "vue-facing-decorator";
import { PendingActions } from "@/store/dashboard/dashboardTypes";
import { icons } from "@/utils/icons";

@Component({ components: {} })
export default class Banners extends Vue {
  icons = icons;
  getStartedBannerShow = false;
  bulkUploadBannerShow = false;

  @Prop({
    type: Array,
    required: true,
  })
  pendingActions!: PendingActions[];

  bulkUploads: PendingActions[] = [];

  routeName = "";
  routesExcluded = ["Marking"];

  async mounted() {
    await this.getPendingActions();
    this.getBulkUploads();

    this.routeName = this.$route.name as string;
  }

  @Watch("pendingActions")
  async pendingActionsWatcher() {
    await this.getPendingActions();
    this.getBulkUploads();

    this.routeName = this.$route.name as string;
  }

  @Watch("$route.name")
  routeNameChanged(value: string) {
    this.routeName = value as string;
  }

  async getPendingActions() {
    const pendingActions = this.pendingActions;

    let getStartedBannerShow = false;

    const getStartedBannerClosed = localStorage.getItem(
      "getStartedBannerClosed"
    );

    const checklistExist = pendingActions.some(
      (item: PendingActions) =>
        item.type === "onboardingDemo" ||
        item.type === "onboardingUploadSubmission" ||
        item.type === "onboardingMarkSubmission" ||
        item.type === "onboardingReturnSubmission"
    );

    if (checklistExist && !getStartedBannerClosed) {
      getStartedBannerShow = true;
    } else {
      getStartedBannerShow = false;
    }

    this.getStartedBannerShow = getStartedBannerShow;
  }

  getBulkUploadBanner(uuid: string) {
    return localStorage.getItem(`${uuid}-banner`);
  }

  getBulkUploads() {
    const pendingActions = this.pendingActions;
    const bulkUploads = pendingActions.filter(
      (item: PendingActions) => item.type === "bulkUpload"
    );

    this.bulkUploads = bulkUploads;
  }

  handleGoToBulkUpload(uuid: string) {
    this.$router.push({
      name: "BulkUploadSplit",
      params: { type: "split", id: uuid },
    });
  }

  handleOpenQuickStartGuide() {
    const publicSiteURL = process.env.VUE_APP_PUBLIC_SITE_URL;
    window.open(`${publicSiteURL}/docs-category/getting-started/`, "_blank");
  }

  handleHideGetStartedBanner() {
    localStorage.setItem("getStartedBannerClosed", "true");
    this.getStartedBannerShow = false;
  }

  handleHideBulkUploadBanner(uuid: string) {
    localStorage.setItem(`${uuid}-banner`, "true");
    const updatedBulkUploads = this.bulkUploads.filter(
      (item: PendingActions) => item.data.assignment !== uuid
    );
    this.bulkUploads = updatedBulkUploads;
  }
}
</script>
