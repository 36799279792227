import { RootState } from "..";
import { Module } from "vuex";
import * as mutation from "./mutations";
import * as mutationType from "./mutation-types";
import * as action from "./actions";
import * as actionName from "./action-names";
import * as getters from "./getters";
import state from "./dashboardState";
import { DashboardState } from "@/store/dashboard/dashboardTypes";
import * as key from "./getter-keys";

const dashboard: Module<DashboardState, RootState> = {
  namespaced: true,
  state,
  actions: {
    [actionName.SET_STUDENT_DASHBOARD]: action.SetStudentDashboardAction,
    [actionName.SET_TEACHER_DASHBOARD]: action.SetTeacherDashboardAction,
    [actionName.SET_PENDING_ACTIONS]: action.SetPendingActionsAction,
  },
  getters: {
    [key.GET_TEACHER_ASSIGNMENTS]: getters.getTeacherAssignments,
    [key.GET_STUDENT_ASSIGNMENTS]: getters.getStudentAssignments,
    [key.GET_PENDING_ACTIONS]: getters.getPendingActions,
  },
  mutations: {
    [mutationType.SET_STUDENT_DASHBOARD]: mutation.SetStudentDashboard,
    [mutationType.SET_TEACHER_DASHBOARD]: mutation.SetTeacherDashboard,
    [mutationType.SET_PENDING_ACTIONS]: mutation.SetPendingActions,
  },
};

export default dashboard;
