<template>
  <div>
    <template v-if="!loadingAssignments && selectedAssignmentFilter">
      <div class="space-y-2" v-if="assignments && assignments.length">
        <div class="flex justify-between">
          <div :class="loadingSummary ? 'disable w-60' : 'w-60'">
            <FormInputDropdown
              v-model="selectedAssignmentFilter"
              :options="assignments"
              option-value="uuid"
              optionLabel="title"
              :class="loadingSummary ? 'pointer-events-none' : ''"
              :background="false"
            />
          </div>
          <div v-if="tabType === 'assignment'" class="flex gap-2 justify-end">
            <AppButton
              type="submit"
              @click="toggleBulkUpload"
              :disabled="loadingSummary"
              :class="loadingSummary ? 'disable' : ''"
            >
              <template #icon_left>
                <span v-html="icon.uploadBlack"></span>
                Upload Submissions
              </template>
            </AppButton>
            <AppDropdownButton
              class="h-[98%]"
              size="sm"
              @clickMenu="handleClickMenu"
              :menus="menus"
              type="submit"
              width="w-35"
              dropdownIcon="iconView"
              v-tooltip.top="{
                value: 'Assignment Actions Menu',
                showDelay: 500,
              }"
            />
          </div>
        </div>
      </div>
    </template>
    <div class="w-full flex flex-col px-2 gap-y-3" v-else>
      <div class="w-full p-4 animate-pulse bg-flohh-neutral-95 rounded-[8px]" />
    </div>
  </div>

  <UploadSubmissionModal
    v-if="selectedAssignment"
    v-model="isBulkUploadOpen"
    :defaultClass="selectedAssignment.class.uuid"
    :defaultAssignment="selectedAssignment.uuid"
    :checkStatus="true"
  />

  <Dialog
    :visible="deleteAssignmentModal"
    modal
    :closable="false"
    :close-on-escape="true"
    :style="{ width: '410px' }"
    :pt="{
      header: { class: '!py-4 !pl-6' },
      content: { class: '!p-0' },
      footer: { class: '!p-0' },
    }"
  >
    <template #header>
      <div class="">
        <h5
          class="text-default text-neutral-gray-500 flex font-bold items-center justify-start"
        >
          <i v-html="icon.trashBlack" class="mr-2"></i>Delete Assignment
        </h5>
      </div>
    </template>
    <div class="border-t border-solid border-neutral-gray-500 py-4 px-6">
      <p class="text-sm text-neutral-gray-500">
        You are about to delete this assignment
      </p>
      <br />
      <p class="text-sm text-neutral-gray-500 font-bold">
        {{ selectedAssignmentData.title }}
      </p>
    </div>
    <template #footer>
      <div class="py-2 px-6 flex items-center justify-end">
        <Button
          label="Cancel"
          @click="onClickHideDeleteAssignment"
          class="border border-solid border-neutral-gray-70 bg-transparent text-sm text-neutral-gray-70 rounded-[8px] h-[45px] font-medium btn-medium py-3 px-5"
        />
        <Button
          label="Delete"
          @click="onClickDeleteAssignment"
          class="border border-solid border-neutral-gray-500 bg-neutral-gray-85 text-sm text-neutral-gray-500 rounded-[8px] h-[45px] font-medium btn-medium py-3 px-5 !mr-0"
          :disabled="loadingDeleteAssignment"
          :loading="loadingDeleteAssignment"
          :class="[loadingDeleteAssignment ? 'cursor-progress' : '']"
        />
      </div>
    </template>
  </Dialog>

  <AddEmailForStudentModal
    v-if="studentsWithoutEmail && studentsWithoutEmail.length > 0"
    v-model="addEmailModalOpen"
    :studentData="studentsWithoutEmail"
    :classUuid="classUuid"
  />
</template>

<script lang="ts">
import { Component, Prop, Vue, Model, Watch } from "vue-facing-decorator";
import { ref } from "vue";
import {
  AssignmentData,
  AssigmentSubmissionSummaryData,
  AssignmentList,
  AssignmentSubmissionSummaryStudent,
  AssignmentSubmissionSummaryStudents,
} from "@/store/assignment/assignmentTypes";
import AppTypographyText from "../Layout/Typhography/AppTypographyText.vue";
import FormInputDropdown from "../Layout/Forms/FormInputDropdown.vue";
import { icons as AppIcons } from "@/utils/icons";
import { AxiosResponse } from "axios";
import AssignmentService from "@/services/AssignmentService";
import BulkUploadService from "@/services/BulkUploadService";
import SubmissionService from "@/services/SubmissionService";
import { IMenuRef } from "./types";
import { useToast } from "primevue/usetoast";
import emitter from "@/config/emitter";
import AppButton from "../Layout/Buttons/AppButton.vue";
import ModalUtility from "../utilities/ModalUtility.vue";
import { deleteAssignment } from "@/store/assignment/assignment-dispatch";
import Dialog from "primevue/dialog";
import Button from "primevue/button";
import AppCardButton from "../Layout/Buttons/AppCardButton.vue";
import AppDropdownButton from "@/components/Layout/Buttons/AppDropdownButton.vue";
import AddEmailForStudentModal from "./utilities/AddEmailForStudentModal.vue";
import UploadSubmissionModal from "@/components/utilities/UploadSubmissionModal.vue";

@Component({
  components: {
    AppTypographyText,
    FormInputDropdown,
    AppButton,
    ModalUtility,
    Dialog,
    Button,
    AppCardButton,
    AppDropdownButton,
    AddEmailForStudentModal,
    UploadSubmissionModal,
  },
})
export default class ClassesTabHeaderComponent extends Vue {
  assignmentService = new AssignmentService();
  bulkUploadService = new BulkUploadService();
  submissionService = new SubmissionService();
  icon = AppIcons;
  toast = useToast();

  @Prop({
    type: Array,
    required: true,
  })
  assignments!: AssignmentList[];

  @Prop({
    type: Object,
    required: false,
  })
  selectedAssignment!: AssignmentData;

  @Model({
    type: String,
    required: false,
  })
  selectedAssignmentFilter!: string;

  @Prop({
    type: Boolean,
    required: true,
  })
  loadingAssignments!: boolean;

  @Prop({
    type: Boolean,
    required: true,
  })
  loadingSummary!: boolean;

  @Prop({
    type: Object,
    required: false,
  })
  assignmentSummary!: AssigmentSubmissionSummaryData;

  @Prop({
    type: Boolean,
    default: false,
  })
  hasMarkedStatus!: boolean;

  @Prop({
    type: String,
    required: false,
  })
  tabType!: string;

  classUuid = "";

  eventBus = emitter;
  selectedItem = ref();
  addEmailModalOpen = false;

  menus: Record<string, any>[] = [];
  originalMenus = [
    { name: "Edit Assignment", icon: "pencilBlack", class: "" },
    { name: "Copy Submission Link", icon: "iconLink", class: "" },
    { name: "Return All Feedback", icon: "iconAssignment", class: "" },
    { name: "Download Marked Submissions", icon: "iconDownload", class: "" },
    {
      name: "Delete Assignment",
      icon: "trashSmallRedIcon",
      class: "!text-[#be1d17]",
    },
  ];

  isBulkUploadOpen = false;
  deleteAssignmentModal = false;
  link = window.location.href;
  loadingDeleteAssignment = false;
  studentsWithoutEmail!: AssignmentSubmissionSummaryStudent[];

  selectedAssignmentData!: AssignmentData;

  @Watch("selectedAssignment")
  watchSelectedAssignmentData(assignment: AssignmentData) {
    this.selectedAssignmentData = assignment;
    this.handleFilterMenu();
  }

  mounted() {
    this.classUuid = this.$route.params.id as string;
    this.selectedAssignmentData = this.selectedAssignment;
    if (!this.hasMarkedStatus) {
      this.handleFilterMenu();
      return;
    } else {
      this.menus = this.originalMenus;
    }
  }

  handleFilterMenu() {
    if (this.selectedAssignmentData) {
      if (this.selectedAssignmentData.visible) {
        this.menus = this.originalMenus.filter(
          (menu) => menu.name.toLowerCase() !== "return all feedback"
        );
      } else {
        this.menus = this.originalMenus.filter(
          (menu) =>
            menu.name.toLowerCase() !== "return all feedback" &&
            menu.name.toLocaleLowerCase() !== "copy submission link"
        );
      }
    } else {
      this.menus = this.originalMenus.filter(
        (menu) => menu.name.toLowerCase() !== "return all feedback"
      );
    }
  }

  toggleBulkUpload() {
    this.eventBus.emit("EVENT_TRIGGER", "CP001");
    this.isBulkUploadOpen = !this.isBulkUploadOpen;
  }

  showToast(
    message: string,
    severity: "success" | "info" | "warn" | "error" | undefined
  ) {
    this.toast.add({
      severity: severity,
      detail: message,
      life: 3000,
    });
  }

  toggleHeadingMenu(event: Event) {
    const ref = this.$refs["heading-menu"] as IMenuRef | undefined;
    if (ref) {
      ref.toggle(event);
    }
  }

  async handleFlattenDocuments() {
    try {
      const response: AxiosResponse =
        await this.assignmentService.postReturnAllFeedback(
          this.selectedAssignmentData.uuid
        );
      if (response.data.ok) {
        this.showToast("Submissions are being processed.", "success");
        this.eventBus.emit("REFRESH_ASSIGNMENT");
      }
    } catch (e) {
      console.error(e);
    }
  }

  async handleDownloadSubmissions() {
    try {
      if (this.hasMarkedStatus) {
        const response: AxiosResponse =
          await this.submissionService.getSubmissionsFiles(
            this.selectedAssignmentData.uuid
          );
        const blob = new Blob([response.data], { type: "application/zip" });
        const el = document.createElement("a");
        const href = URL.createObjectURL(blob);
        el.setAttribute(
          "download",
          `${this.selectedAssignmentData.title} Submissions`
        );
        el.href = href;
        el.setAttribute("target", "_blank");
        // document.body.appendChild(aElement);
        el.click();
        URL.revokeObjectURL(href);
      } else {
        this.showToast("There are no flattened documents to download", "error");
      }
    } catch (err) {
      this.showToast("There are no flattened documents to download", "error");
    }
  }

  async handleCopyLink() {
    try {
      if (navigator.clipboard) {
        const payload = {
          type: "assignment",
          uuid: this.selectedAssignmentData.uuid,
        };
        let code = "";
        const res = await this.assignmentService.generateAssignmentShareCode(
          payload
        );

        if (res.data.ok) {
          code = res.data.data.code;
          const link = `${window.location.protocol}//${window.location.host}/student/shared-assignment?share-code=${code}`;

          navigator.clipboard
            .writeText(link)
            .then(() => {
              this.eventBus.emit("EVENT_TRIGGER", "CP004");
              this.showToast(
                "The assignment shared link is copied to your clipboard.",
                "success"
              );
            })
            .catch((err) => {
              console.error("Unable to copy text: ", err);
            });
        }
      }
    } catch (e) {
      // this.showToast(
      //     "The assignment shared link is copied to your clipboard.",
      //     "success"
      //   );
      //
    } finally {
      //
    }
  }

  onClickHideDeleteAssignment() {
    this.deleteAssignmentModal = false;
  }

  async onClickDeleteAssignment() {
    //
    this.loadingDeleteAssignment = true;
    await this.$store.dispatch(
      deleteAssignment,
      this.selectedAssignmentData.uuid
    );
    this.loadingDeleteAssignment = false;
    this.deleteAssignmentModal = false;
    this.eventBus.emit("EVENT_TRIGGER", "CP007");
    this.showToast("Assignment deleted successfully", "success");
    this.$router.push({
      name: "Classes",
      params: { id: this.assignmentSummary.assignment.class.uuid },
    });
    this.eventBus.emit("REFRESH_SELECTED_CLASS");
    return this.selectedAssignment.uuid;
  }

  handleClickMenu(menuName: string) {
    if (menuName === "Edit Assignment") {
      this.eventBus.emit("EVENT_TRIGGER", "CP003");
      this.$router.push({
        path: `/assignments/${this.selectedAssignment.uuid}`,
      });
    }
    if (menuName === "Return All Feedback") {
      this.eventBus.emit("EVENT_TRIGGER", "CP005");
      if (
        this.assignmentSummary &&
        this.assignmentSummary.students &&
        this.assignmentSummary.students.length > 0
      ) {
        const studentsWithoutEmail = this.assignmentSummary.students.filter(
          (item: AssignmentSubmissionSummaryStudents) => !item.student.email
        );
        if (studentsWithoutEmail.length > 0) {
          const students = [];
          for (let i = 0; i < studentsWithoutEmail.length; i++) {
            students.push(studentsWithoutEmail[i].student);
          }
          this.studentsWithoutEmail = students;
          this.addEmailModalOpen = true;
        } else {
          this.handleFlattenDocuments();
        }
      }
    }
    if (menuName === "Copy Submission Link") {
      this.handleCopyLink();
    }
    if (menuName === "Download Marked Submissions") {
      this.eventBus.emit("EVENT_TRIGGER", "CP006");
      this.handleDownloadSubmissions();
    }
    if (menuName === "Delete Assignment") {
      this.deleteAssignmentModal = true;
    }
  }

  handleEditAssignment() {
    this.$router.push({
      name: "EditAssignment",
      params: { id: this.selectedAssignmentData.uuid },
    });
  }

  @Watch("hasMarkedStatus")
  hasMarkedStatusWatcher(value: boolean) {
    if (!value) {
      this.menus = this.originalMenus.filter(
        (menu) => menu.name.toLowerCase() !== "return all feedback"
      );
      return;
    }

    this.menus = this.originalMenus;
  }

  @Watch("selectedAssignmentFilter")
  selectedAssignmentFilterWatcher(value: string) {
    if (value) {
      this.eventBus.emit("EVENT_TRIGGER", "G002");
      const selected = this.assignments.find(
        (assignment) => assignment.uuid === value
      );
      if (selected) {
        this.$emit("onSelectAssignment", selected);
      }
    }
  }
}
</script>

<style scoped lang="scss">
.disable {
  cursor: wait;
}
</style>
