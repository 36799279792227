<template>
  <div>
    <div class="flex justify-between items-center px-2 py-7">
      <AppTypographyText
        variant="md"
        type="title"
        :label="data.class.code"
        class="hover:underline cursor-pointer"
        @click="handleRedirectToClassPage()"
      />
      <AppDropdownButton
        size="xs"
        @clickMenu="handleMenuSelection"
        :menus="menus"
        type="default"
        width="w-40"
        text
        dropdownIcon="moreOptionBlack"
      />
    </div>
    <div v-if="data.assignments.length > 0">
      <table class="assignment-table">
        <tbody>
          <tr
            v-for="item in data.assignments"
            :key="item.uuid"
            @click="handleRedirectToClassPage(item.uuid)"
          >
            <td class="w-[50px] !pr-0">
              <span
                v-if="item.visible"
                v-html="icon.assignmentIconActive"
              ></span
              ><span v-else v-html="icon.assignmentIconInactive"></span>
            </td>
            <td class="relative">
              <AppTypographyText
                variant="md"
                type="small"
                class="translate-y-[-50%]"
              >
                <Ellipsis width="300px" :content="item.title"
              /></AppTypographyText>
            </td>
            <td>
              <AppTypographyText
                class="translate-y-[-50%]"
                variant="md"
                type="small"
                :label="item.visible ? formatDueDate(item.dueDate) : 'Draft'"
              />
            </td>
            <td>
              <AppTypographyText
                class="translate-y-[-50%]"
                variant="md"
                type="small"
                :label="computeMarked(item.submissionCounts)"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-else class="empty-class">
      <AppTypographyText
        variant="md"
        type="small"
        label="You’ll see class assignments here once you have created them."
      />
    </div>

    <CreateClassPopup v-model="editClassModalOpen" :isEdit="true" />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-facing-decorator";
import AppTypographyText from "@/components/Layout/Typhography/AppTypographyText.vue";
import AppDropdownButton from "@/components/Layout/Buttons/AppDropdownButton.vue";
import Ellipsis from "@/components/utilities/Ellipsis.vue";
import { useToast } from "primevue/usetoast";
// import ModalUtility from "@/components/utilities/ModalUtility.vue";
import { icons } from "@/utils/icons";
import { AssignmentTableData } from "../../type";
import { formatDate } from "@/utils/formatter";
import { DashboardSubmission } from "@/store/dashboard/dashboardTypes";
import CreateClassPopup from "@/components/CreateClass/CreateClassPopup.vue";
import { setClassInformation } from "@/store/class/class-dispatch";
import emitter from "@/config/emitter";

@Component({
  components: {
    AppTypographyText,
    AppDropdownButton,
    Ellipsis,
    CreateClassPopup,
  },
})
export default class AssignmentListComponent extends Vue {
  toast = useToast();
  icon = icons;
  eventBus = emitter;

  @Prop({
    type: Array,
    required: true,
  })
  data!: AssignmentTableData;

  menus = [
    {
      icon: "plusBlack",
      name: "Create Assignment",
    },
    {
      icon: "pencilBlack",
      name: "Edit Class",
    },
  ];

  editClassModalOpen = false;

  formatDueDate(dueDate: string) {
    return `Due ${formatDate(dueDate, "DD MMMM YYYY")} at ${formatDate(
      dueDate,
      "h:mm A"
    )}`;
  }

  computeMarked(data: DashboardSubmission) {
    const lgSubmitted = data.learningGoalsToReview;
    const lgRequested = data.learningGoalsRequested;
    const done = data.completedSubmissions;
    const marked = data.markedSubmissions;
    const total = data.totalSubmissions;

    const markedNumerator = done + marked + lgRequested + lgSubmitted;

    return `${markedNumerator} out of ${total} marked`;
  }

  computeSubmitted(data: DashboardSubmission) {
    const total = data.totalSubmissions;

    return `${total} Submissions Submitted`;
  }

  handleRedirectToClassPage(uuid = "") {
    this.eventBus.emit("EVENT_TRIGGER", "TD003");
    const defaultRoute = {
      name: "Classes",
      params: {
        id: this.data.class.uuid,
      },
    };
    if (uuid) {
      this.$router.push({
        ...defaultRoute,
        query: {
          assignment: uuid,
          tab: "assignments",
        },
      });
    } else {
      this.$router.push(defaultRoute);
    }
  }

  async handleMenuSelection(action: string) {
    const classUuid = this.data.class.uuid;
    if (action === "Create Assignment") {
      this.$router.push({
        name: "CreateAssignment",
        query: { class: classUuid },
      });
    } else if (action === "Edit Class") {
      await this.$store.dispatch(setClassInformation, classUuid);
      this.editClassModalOpen = true;
    }
  }

  mounted() {
    //
  }
}
</script>

<style scoped lang="scss">
.assignment {
  &-table {
    width: 100%;
  }
  &-table > tbody > tr {
    border-top: 1px solid #d9d9d9;
    cursor: pointer;

    &:hover {
      color: #ffffff;
      background-color: #f4cddc;
    }
  }
  &-table > tbody > tr > td {
    padding: 21px 1em;
    max-width: 100px;
  }
}
.empty-class {
  padding: 21px 1em;
  border-top: 1px solid #d9d9d9;
}
</style>
