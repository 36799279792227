<template>
  <div class="relative w-full p-4 overflow-h">
    <div class="comment-area">
      <textarea
        v-model="finalComment"
        @mouseup="onMouseUp"
        @mousedown="onMouseDown"
        @input="handleFinalComment"
        class="w-full rounded-lg border border-solid border-flohh-neutral-85 h-32 resize-none py-4 px-6 text-flohh-text-caption color-flohh-neutral-70"
        placeholder="Add a summary of your final comment here"
      ></textarea>
      <div class="flex justify-end mt-1.5">
        <AppButton
          type="secondary"
          class="mr-2 w-[56px]"
          @click="
            () => {
              toggleContentBox(!isContentBoxOpen);
            }
          "
          v-tooltip.left="{
            value: 'Your comment library',
            showDelay: 500,
            pt: {
              text: 'w-[200px] left-[-8px] relative',
            },
          }"
        >
          <span v-html="icons.folderIcon" v-if="!isContentBoxOpen"></span>
          <span v-html="icons.folderOpenIcon" v-if="isContentBoxOpen"></span>
        </AppButton>
        <Teleport to="#top_navigation_buttons_teleport">
          <div v-if="studentId" class="flex items-center justify-end">
            <Dropdown
              v-model="selectedStatus"
              :options="markingStatuses"
              optionLabel="name"
              class="student-dropdown h-[48px] mr-[22px] rounded-[8px] !border-none"
              :pt="{
                root: { class: 'max-w-[400px]' },
                input: { class: 'text-flohh-neutral-5' },
              }"
              @change="handleChange"
            >
              <template #value="slotProps">
                <div class="flex items-center justify-start">
                  <span
                    v-if="slotProps.value"
                    v-html="icons[slotProps.value.icon]"
                    class="[&>svg]:w-full w-[20px] h-[20px] rounded-[20px] flex items-center justify-center p-[4px]"
                    :class="
                      slotProps.value
                        ? slotProps.value.value === 'mark'
                          ? 'bg-[#81BD9E]'
                          : 'bg-[#B3B3B3]'
                        : 'bg-[#B3B3B3]'
                    "
                  ></span>
                  <span
                    class="block items-center text-[14px] font-medium text-[#0D0D0D] ml-[12px] truncate"
                  >
                    {{ slotProps.value?.name }}</span
                  >
                </div>
              </template>
              <template #option="slotProps">
                <div class="flex items-center gap-[12px]">
                  <span
                    v-if="slotProps.option"
                    v-html="icons[slotProps.option.icon]"
                    class="[&>svg]:w-full w-[20px] h-[20px] rounded-[20px] flex items-center justify-center p-[4px]"
                    :class="
                      slotProps.option
                        ? slotProps.option.value === 'mark'
                          ? 'bg-[#81BD9E]'
                          : 'bg-[#B3B3B3]'
                        : 'bg-[#B3B3B3]'
                    "
                  ></span>
                  <div class="flex items-center">
                    {{ slotProps.option.name }}
                  </div>
                </div>
              </template>
            </Dropdown>
            <AppButton
              type="primary"
              @click="
                () => {
                  eventBus.emit('EVENT_TRIGGER', 'MS012');

                  handleSaveAndClose(this.selectedStatus.value);
                  isContentBoxOpen = false;
                }
              "
            >
              <span v-html="icons.checkBlack"></span>
              Save
            </AppButton>
          </div>
        </Teleport>
      </div>
      <div class="comment-highlight" v-if="highlightedText">
        <div class="filter-container">
          <p
            class="flex w-full items-center py-1 text-flohh-text-body justify-between border-b border-solid border-flohh-neutral-85 mb-2"
          >
            Allocate Tag
          </p>
          <div class="filter-wrap p-0">
            <div class="filter-menu">
              <template v-for="(item, key) in commentTags" :key="key">
                <button
                  class="text-flohh-text-small color-flohh-neutral-20 rounded-lg py-1.5 px-3.5 font-medium mr-1 mb-1"
                  :class="{
                    '!outline-2 !outline-flohh-neutral-20 !outline-offset-[-2px] !outline':
                      selectedTag && selectedTag === item.title,
                  }"
                  v-if="item.color"
                  :style="{
                    'background-color':
                      'rgb(' +
                      item.color.r +
                      ',' +
                      item.color.g +
                      ',' +
                      item.color.b +
                      ')',
                  }"
                  @click="allocateTag(item)"
                >
                  {{ item.localization.defaultMessage }}
                </button>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Teleport to="body">
      <div
        class="absolute z-[6] z-[99] right-[300px] top-[157px]"
        v-if="isContentBoxOpen"
      >
        <div
          class="relative overflow-hidden h-[502px] px-0 w-64 border border-solid border-flohh-neutral-85 rounded-lg bg-flohh-neutral-95"
          ref="commentPopup"
        >
          <div class="px-4">
            <div
              class="flex w-full items-center pt-5 pb-4 justify-between border-b border-solid border-flohh-neutral-85"
            >
              <p class="text-flohh-text-body color-flohh-neutral-5 font-medium">
                Comment Library
              </p>
              <button
                @click="toggleContentBox(false)"
                class="w-6 h-6 flex justify-center items-center"
              >
                <span
                  v-html="icons.closeBlack"
                  class="[&>svg]:w-3.5 [&>svg]:h-3.5"
                ></span>
              </button>
            </div>
          </div>
          <div class="h-[435px] overflow-auto w-full pt-4 pb-[85px]">
            <div class="px-4">
              <div class="filter-menu mb-6">
                <template v-for="(item, key) in commentTags" :key="key">
                  <button
                    class="text-flohh-text-small color-flohh-neutral-20 rounded-lg py-1.5 px-3.5 font-medium mr-1 mb-1"
                    :class="{
                      '!outline-2 !outline-flohh-neutral-20 !outline-offset-[-2px] !outline':
                        selectedTag && selectedTag === item.title,
                    }"
                    v-if="item.color"
                    :style="{
                      'background-color':
                        'rgb(' +
                        item.color.r +
                        ',' +
                        item.color.g +
                        ',' +
                        item.color.b +
                        ')',
                    }"
                    @click="selectFilter(item.localization.defaultMessage)"
                  >
                    {{ item.localization.defaultMessage }}
                  </button>
                </template>
              </div>
              <p
                v-if="commentLibrary"
                class="text-flohh-text-caption font-flohh-font-regular color-flohh-neutral-20 mb-4"
              >
                Choose a comment from the list below to add into your final
                feedback:
              </p>
              <p
                v-else
                class="text-flohh-text-caption font-flohh-font-regular color-flohh-neutral-20 mb-4"
              >
                No existing comments <br /><br />
                You can manually add comments to your bank as your are typing
                student feedback by highlighting typed text from the summary
                comment box and selecting a tag.
                <br /><br />
                Or
                <br /><br />
                Use the button below to attach a comment bank from your
                resources.
              </p>
              <div v-if="commentLibrary">
                <template v-for="(item, key) in commentLibrary" :key="key">
                  <div class="flex align-items-center mb-3">
                    <Checkbox
                      v-model="selectedComments"
                      :inputId="'comment' + key"
                      name="comments"
                      :value="item.comment"
                      :pt="{
                        input: {
                          class:
                            '!border-flohh-neutral-85 !border-solid !border-2',
                        },
                      }"
                      @click="
                        () => {
                          onChangeComment(item.comment);
                        }
                      "
                    />
                    <label
                      class="ml-2 text-flohh-text-caption font-medium color-flohh-neutral-20"
                      :for="'comment' + key"
                      >{{ item.comment }}</label
                    >
                  </div>
                </template>
              </div>
            </div>
          </div>
          <div class="absolute bottom-0 w-full">
            <div
              class="flex justify-end pt-4 mt-4 border-t border-solid border-flohh-neutral-85 bg-flohh-neutral-95 z-1 p-4 left-0"
            >
              <AppButton
                type="submit"
                v-if="commentLibrary"
                @click="showCommentBankOpen = true"
              >
                <span v-html="icons.plusBlack"></span>
                Add to bank
              </AppButton>
              <AppButton type="submit" v-else @click="visible = true">
                <span v-html="icons.plusBlack"></span>
                Add a comment bank
              </AppButton>
            </div>
          </div>
        </div>
      </div>
    </Teleport>
  </div>

  <ModalUtility
    v-model="visible"
    width="614px"
    title="Select a Comment Bank"
    @onClose="visible = false"
    :draggable="false"
  >
    <template #content>
      <div class="px-[28px] py-[30px] h-[364px] overflow-auto">
        <div v-if="!listLoading" class="flex flex-col">
          <div
            class="flex gap-2 items-center w-full px-4 py-4 hover:bg-flohh-neutral-95 cursor-pointer"
            v-for="(item, index) in list"
            :key="index"
            :class="[
              {
                'bg-flohh-neutral-95': item.uuid === selectedCommentBank,
              },
            ]"
            @click="handleCommentBankClick(item.uuid)"
          >
            <FormRadioButton
              v-model="selectedCommentBank"
              :name="index.toString()"
              :inputId="index.toString()"
              :value="item.uuid"
            />
            <label
              :for="item.name"
              class="font-flohh-font-medium text-flohh-text-body cursor-pointer"
              >{{ item.name }}</label
            >
          </div>
        </div>
        <div v-else>
          <ProgressLoader />
        </div>
      </div>
    </template>
    <template #footer>
      <div class="flex pt-5 border-t border-solid border-flohh-neutral-85">
        <div class="flex-1 flex justify-start items-center">
          <AppButton @click="handleManageCommentBank" text blackLabel>
            Manage Comment Banks
          </AppButton>
        </div>
        <div class="flex-1 flex justify-end items-center gap-x-4">
          <AppButton
            type="submit"
            @click="handleClickSelect"
            :disabled="!selectedCommentBank"
            :class="!selectedCommentBank ? 'opacity-[0.5]' : ''"
          >
            <template #icon_left>
              <span v-html="icons.checkBlack"></span>
            </template>
            Select
          </AppButton>
        </div>
      </div>
    </template>
  </ModalUtility>

  <CommentABankPopUp
    v-if="showCommentBankOpen"
    v-model="showCommentBankOpen"
    :commentDetails="commentDetails"
    :commentBankList="commentBankList"
    :isEdit="commentBankEdit"
    :handleSaveCommentBank="handleSelectedCommentBank"
  />
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from "vue-facing-decorator";
import ButtonComponent from "@/components/Button/ButtonComponent.vue";
import {
  IComment,
  ICommentTag,
  TCommentBank,
  TCommentTags,
  Win,
} from "./types";
import { StorageDataKey } from "@/enums/enums";
import { COMMENT_TAGS, COMMENT_BANK } from "./data";
import CommentService from "@/services/CommentService";
import { AxiosResponse } from "axios";
import { icons as AppIcons } from "@/utils/icons";
import AppButton from "@/components/Layout/Buttons/AppButton.vue";
import FormCheckBox from "@/components/Layout/Forms/FormCheckBox.vue";
import Checkbox from "primevue/checkbox";
import { ref, Teleport } from "vue";
import { useToast } from "primevue/usetoast";
import emitter from "@/config/emitter";
import ModalUtility from "../utilities/ModalUtility.vue";
import { CommentBankList as CommentBanks } from "@/store/comments/types";
import FormRadioButton from "@/components/Layout/Forms/FormRadioButton.vue";
import ProgressLoader from "@/components/utilities/ProgressLoader.vue";
import { IAttachedResource } from "@/models/CriteriaSheet";
import CommentABankPopUp from "../CommentABank/CommentABankPopUp.vue";
import { CommentBankList as CBList } from "@/store/comments/types";
import Dropdown from "primevue/dropdown";
interface ICommentBankList {
  uuid: string;
  name: string;
}
interface IDList {
  name: string;
  value: "mark" | "unmark";
  icon: string;
}
@Component({
  components: {
    ButtonComponent,
    AppButton,
    FormCheckBox,
    Checkbox,
    Teleport,
    ModalUtility,
    FormRadioButton,
    ProgressLoader,
    CommentABankPopUp,
    Dropdown,
  },
})
export default class CommentComponent extends Vue {
  toast = useToast();
  @Prop({
    type: Array,
    required: false,
  })
  assignmentId!: string;

  @Prop({
    type: String,
    required: false,
  })
  assignmentName!: string;

  @Prop({
    type: Boolean,
    required: true,
  })
  public handleSaveAndClose!: (selectedStatus: "mark" | "unmark") => void;

  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  isMarkingScreen!: boolean;

  @Prop({ type: String, required: false })
  submissionStatus!: string;

  @Prop({ type: String, required: false })
  studentId!: string;

  private commentService = new CommentService();
  eventBus = emitter;
  pizza = ref();
  isContentBoxOpen = false;
  commentTags: TCommentTags = COMMENT_TAGS;

  selectedTag!: string | undefined;

  highlightedText!: string;
  commentLibrary!: TCommentBank;
  commentLibraryClone: TCommentBank = [];
  allocateComment!: IComment;
  commentBank: any;

  finalComment = "";

  icons = AppIcons;

  selectedComments: any = [];

  commentBankLibrary = [];
  // comments: TCommentBank = [];

  visible = false;
  listLoading = true;
  list: ICommentBankList[] = [];
  selectedCommentBank = "";
  selectedCommentBankName = "";

  isSavingComment = false;

  showCommentBankOpen = false;
  commentDetails!: any;
  commentBankList: CBList[] = [];
  commentBankEdit = true;

  selectedStatus!: IDList;
  markingStatuses: IDList[] = [
    { name: "Marking in progress", value: "unmark", icon: "pencilBlack" },
    { name: "Marked", value: "mark", icon: "checkBlack" },
  ];

  handleCommentBankClick(uuid: string) {
    this.selectedCommentBank = uuid;
  }

  handleManageCommentBank() {
    if (this.isMarkingScreen) {
      this.visible = false;
      this.eventBus.emit("SAVE_MARKING");
    }
    this.$router.push({ path: "/comment-bank" });
  }

  handleChange() {
    console.log("handleChange");
    console.log(this.selectedStatus);
    this.handleSaveAndClose(this.selectedStatus.value);
  }

  async handleClickSelect() {
    if (this.selectedCommentBank) {
      this.isSavingComment = true;
      const data: any = this.list.find(
        (o: any) => o.uuid === this.selectedCommentBank
      );

      await this.handleAttachCommentBank(
        this.assignmentId,
        this.selectedCommentBank
      );
      this.isSavingComment = false;
      this.visible = false;
      this.onGetCommentBank();
    } else {
      this.showMessage("Please select comment bank first", "error");
    }
  }
  async fetchCommentBankList(assignmentUuid = "") {
    try {
      this.listLoading = true;
      const res = await this.commentService.getCommentBank(assignmentUuid);
      this.list = [];
      if (res.data.ok) {
        const comments = res.data.data;
        // this.comments = comments;
        comments.map((item: CommentBanks) => {
          const obj: ICommentBankList = {
            uuid: item.uuid,
            name: item.name,
          };

          this.list.push(obj);
        });

        this.listLoading = false;
      } else {
        throw new Error();
      }
    } catch (error) {
      console.error(error);
    }
  }

  // isChecked(comment: string) {
  //   return this.finalComment.replace("/\r?\n|\r/", "").includes(item.trim()).some(item => item.comment === comment);
  // }

  async handleAttachCommentBank(
    assignmentUuid: string,
    commentBankUuid: string
  ) {
    try {
      const commentBankAttachPayload: IAttachedResource = {
        targetType: "assignment",
        targetId: assignmentUuid,
        resourceType: "comment bank",
        resourceId: commentBankUuid,
      };
      const response = await this.commentService.attachCommentBankAssignment(
        commentBankAttachPayload
      );
      if (response.data.ok) {
        return true;
      } else {
        throw new Error();
      }
    } catch (err) {
      console.error(err);
      return false;
    }
  }

  async handleSelectedCommentBank(uuid: string, name: string) {
    this.onGetCommentBank();
    //
  }

  handleGetCommentList(comments: TCommentBank[]) {
    const transformedStructure: TCommentBank = comments.map(
      (item: any, index) => ({
        id: index,
        comment: item.content,
        title: item.metadata.tag,
        tag: item.metadata.tag,
      })
    );
    this.commentLibrary = transformedStructure;
    // localStorage.setItem(
    //   StorageDataKey.CommentLibrary,
    //   JSON.stringify(this.commentLibrary)
    // );
  }

  @Watch("commentLibrary")
  commentLibraryWatcher(value: TCommentBank) {
    if (!this.commentLibraryClone) {
      this.commentLibraryClone = value;
    } else {
      if (value.length > this.commentLibraryClone.length) {
        this.commentLibraryClone = value;
      }
    }
  }

  created() {
    // this.onGetCommentBank();
  }

  mounted() {
    this.onGetCommentBank();
    this.fetchCommentBankList(this.assignmentId);

    const unmarkedStatus = ["unmarked", "marking in progress"];
    if (unmarkedStatus.includes(this.submissionStatus)) {
      this.selectedStatus = this.markingStatuses[0];
    } else {
      this.selectedStatus = this.markingStatuses[1];
    }

    console.log("this.selectedStatus");
    console.log(this.selectedStatus);

    const fc = localStorage.getItem(StorageDataKey.FinalComment);
    if (fc) {
      this.finalComment = fc;
    }
  }

  /**
   * name
   */
  public async onGetCommentBank() {
    try {
      const response: AxiosResponse =
        await this.commentService.getCommentBankByAssignment(this.assignmentId);
      if (response.data.ok) {
        const comments = response.data.data;
        const length = comments.length;
        if (length > 0) {
          this.handleGetCommentList(comments[length - 1].comments);
          this.commentBank = comments[length - 1];
          this.commentDetails = this.commentBank;
        }
      } else {
        console.error(response.data.message);
      }
    } catch (error) {
      if (error instanceof ReferenceError) {
        console.error(error.message);
      } else {
        // throw error;
      }
    }
  }

  postUpdateCommentLibrary(data: any) {
    const newCommentLibrary: any = [];
    data.forEach((comment: any, index: number) =>
      newCommentLibrary.push({
        comment: comment.content,
        id: index,
        tag: comment.metadata.tag,
        title: comment.metadata.tag,
      })
    );
    this.commentLibrary = newCommentLibrary;

    this.onGetCommentBank();
    this.fetchCommentBankList(this.assignmentId);
  }

  /**
   * onPutCommentBank
   */
  public async onPostNewComment() {
    console.log("onPostNewComment");
    console.log(this.commentBank);
    if (this.commentBank) {
      const payload = {
        comments: [
          {
            content: this.removeStringWhiteSpace(this.highlightedText),
            metadata: { tag: this.allocateComment.tag },
          },
        ],
      };
      try {
        const response: AxiosResponse =
          await this.commentService.addNewCommentOnBank(
            payload,
            this.commentBank.uuid
          );
        if (response.data.ok) {
          this.closeAllocateTagPopup();
          this.toggleContentBox(true);
          this.postUpdateCommentLibrary(response.data.data.comments);
        } else {
          console.error(response.data.message);
        }
      } catch (error) {
        if (error instanceof ReferenceError) {
          console.error(error.message);
        } else {
          // throw error;
        }
      }
    } else {
      const payload = {
        assignment: this.assignmentId,
        name: `${this.assignmentName} Comment Bank`,
        comments: [
          {
            content: this.removeStringWhiteSpace(this.highlightedText),
            metadata: { tag: this.allocateComment.tag },
          },
        ],
      };
      try {
        const response: AxiosResponse =
          await this.commentService.postCommentBank(payload);
        if (response.data.ok) {
          this.commentBank = response.data.data.comments;
          this.selectedCommentBank = response.data.data.uuid;

          await this.handleAttachCommentBank(
            this.assignmentId,
            this.selectedCommentBank
          );
          this.closeAllocateTagPopup();
          this.toggleContentBox(true);
          this.postUpdateCommentLibrary(response.data.data.comments);
        } else {
          console.error(response.data.message);
        }
      } catch (error) {
        if (error instanceof ReferenceError) {
          console.error(error.message);
        } else {
          // throw error;
        }
      }
    }
  }

  /**
   * onPostCommentBank
   */
  public async onPostCommentBank(comments: []) {
    // try {
    //   const response: AxiosResponse = await this.commentService.postCommentBank(
    //     comments
    //   );
    //   if (response.data.ok) {
    //     //
    //   } else {
    //     console.error(response.data.message);
    //   }
    // } catch (error) {
    //   if (error instanceof ReferenceError) {
    //     console.error(error.message);
    //   } else {
    //     // throw error;
    //   }
    // }
  }

  handleCommentLibrary = () => {
    const storageCommentLibrary: string | null = localStorage.getItem(
      StorageDataKey.CommentLibrary
    );
    if (storageCommentLibrary) {
      let commentLibrary = JSON.parse(storageCommentLibrary);
      return commentLibrary;
    } else {
      localStorage.setItem(
        StorageDataKey.CommentLibrary,
        JSON.stringify(COMMENT_BANK)
      );
    }
  };

  selectFilter(tag: string | undefined) {
    if (!this.commentLibrary) {
      return;
    }
    if (this.selectedTag !== tag) {
      this.eventBus.emit("EVENT_TRIGGER", "MS009");
      this.selectedTag = tag;
    } else {
      this.selectedTag = "";
    }
    console.log(this.selectedTag);
    // const library = this.handleCommentLibrary();
    const library = this.commentLibraryClone;

    this.commentLibrary = this.filterCommentLibrary(
      library,
      this.selectedTag || ""
    );
  }

  toggleContentBox(status: boolean) {
    this.eventBus.emit("EVENT_TRIGGER", "MS008");
    this.isContentBoxOpen = status;
    this.selectFilter("");
    if (status) {
      this.selectedComments = this.finalComment
        .replace("/\r?\n|\r/", "")
        .split(" ");
    }
  }

  handleFinalComment() {
    localStorage.setItem(
      StorageDataKey.FinalComment,
      this.finalComment ? this.finalComment : ""
    );
    this.selectedComments = this.finalComment
      .replace("/\r?\n|\r/", "")
      .split(" ");
  }

  onMouseDown() {
    this.closeAllocateTagPopup();
  }

  onMouseUp() {
    const activeTextarea: HTMLTextAreaElement | null =
      document.activeElement as HTMLTextAreaElement;

    if (activeTextarea.value) {
      const selection: string = activeTextarea.value.substring(
        activeTextarea.selectionStart,
        activeTextarea.selectionEnd
      );
      if (selection) {
        this.highlightedText = selection;
      }
    }
  }

  closeAllocateTagPopup() {
    const win: Win = window;
    if (win && win.getSelection) {
      const select = win.getSelection();
      if (select) {
        select.removeAllRanges();
      }
    }
    this.highlightedText = "";
  }

  allocateTag(itemTag: ICommentTag) {
    this.eventBus.emit("EVENT_TRIGGER", "MS010");
    this.allocateComment = {
      id: this.commentLibrary ? this.commentLibrary.length + 1 : 1,
      title: itemTag.localization.defaultMessage,
      comment: this.removeStringWhiteSpace(this.highlightedText),
      tag: itemTag.localization.defaultMessage,
    };
    this.selectedComments.push(this.allocateComment.comment);
    this.saveToLibrary();
  }

  saveToLibrary() {
    // let library = this.handleCommentLibrary();
    let library = this.commentLibrary;
    let searchText = null;
    if (library) {
      searchText = library.find((o: IComment) => {
        return String(o.comment) === String(this.allocateComment.comment);
      });
    } else {
      library = [];
    }

    if (
      this.allocateComment &&
      this.allocateComment.comment &&
      this.allocateComment.tag
    ) {
      if (searchText) {
        this.showMessage("Comment existing in library", "warn");
      } else {
        // library.unshift(this.allocateComment);
        // localStorage.setItem(
        //   StorageDataKey.CommentLibrary,
        //   JSON.stringify(library)
        // );
        this.commentLibrary = library;

        // this.closeAllocateTagPopup();
        // this.toggleContentBox(true);

        // const storageCommentLibrary: string | null = localStorage.getItem(
        //   StorageDataKey.CommentLibrary
        // );

        this.onPostNewComment();
      }
    } else {
      alert("Add Comment and Select a Tag first");
    }
  }

  removeStringWhiteSpace(str: string) {
    let leftString = str.replace(/^\s+/g, "");
    return leftString.replace(/\s+$/g, "");
  }

  filterCommentLibrary(library: TCommentBank, tag: string) {
    if (tag === "") return library;

    let filter = library.filter((o: IComment) => o.tag === tag);
    return filter;
  }

  onChangeComment(item: string) {
    this.handleCommentSuggest(item);
  }

  checkIfExist(item: string) {
    if (!this.finalComment) return;
    console.log(item);
    console.log(
      this.finalComment.replace("/\r?\n|\r/", "").includes(item.trim())
    );
    return this.finalComment.replace("/\r?\n|\r/", "").includes(item.trim());
  }

  handleCommentSuggest(item: string) {
    let fc = "";
    if (this.checkIfExist(item)) {
      //existing
      fc = this.removeStringWhiteSpace(this.finalComment.replace(item, ""));
    } else {
      // new
      fc =
        this.finalComment === ""
          ? item + this.removeStringWhiteSpace(this.finalComment)
          : this.removeStringWhiteSpace(this.finalComment) + " " + item;
    }
    this.finalComment = fc;
    this.handleFinalComment();
  }
  private showMessage(
    message: string,
    severity: "success" | "info" | "warn" | "error" | undefined
  ) {
    this.toast.add({
      severity: severity,
      detail: message,
      life: 3000,
    });
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
//

.comment-area {
  position: relative;
  .comment-highlight {
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    z-index: 2;
    background-color: #fff;
    border-radius: 0.357em;
    width: 230px;
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 5px 5px;
    .filter-container {
      padding-top: 0;
    }
  }
}
</style>
