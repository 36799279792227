// START
// ########################################################
import PSPDFKit, { Instance, Annotation, Color } from "pspdfkit";
import { StorageDataKey } from "@/enums/enums";
import { TColorPreset, IColorPreset, IOption } from "./types";
import { escapeRegExp, generateGuid } from "@/utils/helper";
import { ANNOTATION_BANK, SHAPE_ICONS } from "./data";
import Emitter from "@/config/emitter";

interface Inspector {
  children?: any[]; // Define the children property as an array of any type or adjust as needed
}

interface ILibraryItem {
  customData: {
    libraryId: string;
    filterTag?: string;
  };
  color: Color;
  icon: string; // Assuming 'icon' is a string representing the icon identifier
  text: {
    value: string;
  };
}

interface IShape {
  name: string;
  icon: string; // Assuming that 'icon' is a string representing the SVG icon
  selected: boolean;
}

const COLLAPSE_BY_DEFAULT_HEIGHT = 850;
const COLLAPSE_BY_DEFAULT_WIDTH = 600;
const selectedShapes: IShape[] = [
  {
    name: "COMMENT",
    icon: SHAPE_ICONS.COMMENT,
    selected: false,
  },
  {
    name: "CHECK",
    icon: SHAPE_ICONS.CHECK,
    selected: false,
  },
  {
    name: "CIRCLE",
    icon: SHAPE_ICONS.CIRCLE,
    selected: false,
  },
  {
    name: "CROSS",
    icon: SHAPE_ICONS.CROSS,
    selected: false,
  },
  {
    name: "HELP",
    icon: SHAPE_ICONS.HELP,
    selected: false,
  },
  {
    name: "STAR",
    icon: SHAPE_ICONS.STAR,
    selected: false,
  },
];
let instance: any;
let presetColors: TColorPreset;
let annotation: Annotation;
let addAnnotationFunc: any;
let updateAnnotation: any;
let checkAnnotation: any;
let library: any;

export const AnnotationInspectorComponent = (
  _instance: Instance,
  _presetColors: TColorPreset,
  _addAnnotation: any,
  _updateAnnotation: any,
  _checkAnnotation: any
  // _library: any
) => {
  instance = _instance;
  presetColors = _presetColors;
  addAnnotationFunc = _addAnnotation;
  updateAnnotation = _updateAnnotation;
  checkAnnotation = _checkAnnotation;

  handleAnnotationFocus(instance, presetColors);
  handlePagePress(instance);
};
let inspectors: any = {};

export const getAnnotationInspector = (_annotation: Annotation): any => {
  if (instance) {
    const close = instance.contentDocument.querySelector(
      ".PSPDFKit-Icon-Close"
    );

    if (close) {
      close.addEventListener("click", () => removeColorPickerOverlay(instance));
    }

    annotation = _annotation;
    if (annotation instanceof PSPDFKit.Annotations.NoteAnnotation) {
      let container;
      let sectionContainer;
      if (
        !inspectors[annotation.id] ||
        inspectors[annotation.id].children.length === 0
      ) {
        container = document.createElement("div");
        container.className = "annotation-inspector";
        ButtonUI(container);
        DeleteButtonUI(container);
        sectionContainer = document.createElement("div");
        sectionContainer.className = "section-container";
        ShapesAndColorsUI(container);
        FilterUI(container);
        AnnotationItems(container);

        inspectors[annotation.id] = container;
      }

      container = inspectors[annotation.id];
      return [
        {
          type: "custom",
          id: "annotation-tooltip",
          className: "annotation-tooltip-container",
          node: container,
        },
      ];
    } else {
      return [];
    }
  }
};

const handlePagePress = (instance: Instance) => {
  instance.addEventListener("page.press", () => {
    if (
      instance.contentDocument.querySelector(
        ".PSPDFKit-Popover-Note-Annotation"
      )
    ) {
      instance.setViewState((viewState) =>
        viewState.set("interactionMode", PSPDFKit.InteractionMode.NOTE)
      );
      instance.setViewState((viewState) =>
        viewState.set("interactionMode", null)
      );
    }
    instance.setViewState((viewState) =>
      viewState.set("interactionMode", null)
    );
    removeColorPickerOverlay(instance);
  });
  const viewport = instance.contentDocument.querySelector(
    ".PSPDFKit-ejvfc31fwjv43pf6dxbymn8xw"
  );
  viewport?.addEventListener("click", (event: Event) => {
    const elZoom: HTMLElement | null =
      instance.contentDocument.querySelector(".PSPDFKit-Zoom");
    const elViewport: HTMLElement | null =
      instance.contentDocument.querySelector(
        ".PSPDFKit-ejvfc31fwjv43pf6dxbymn8xw"
      );
    if (elZoom) {
      if (elZoom.parentNode) {
        if (event.target === elZoom.parentNode) {
          removeColorPickerOverlay(instance);
        }
      }
    }
    if (elViewport) {
      if (event.target === elViewport) {
        removeColorPickerOverlay(instance);
      }
    }
  });
};

const handleAnnotationSelectionChange = (
  instance: Instance,
  presetColors: TColorPreset
) => {
  instance.addEventListener("annotations.willChange", (event) => {
    // if (event.reason === "MOVE_START") {
    //   removeColorPickerOverlay(instance);
    // }
    // if (event.reason === "TEXT_EDIT_END") {
    //   removeColorPickerOverlay(instance);
    // }
    // if (event.reason === "MOVE_END") {
    //
    // }
  });
};

const handleAddToLibrary = (
  event: Event,
  annotation: Annotation,
  isEdit: boolean
) => {
  const contentEditor = instance.contentDocument.querySelector(
    ".PSPDFKit-c8up8r9n155axqjtb8dat8e5t"
  );
  annotation.text.value = contentEditor?.innerHTML.trim();
  addAnnotationFunc(
    event,
    annotation,
    instance,
    isEdit,
    refreshAnnotationLibrary
  );
  updateAnnotation();
};

function ButtonUI(container: any) {
  let title = "Add to annotation library";
  let isEdit = false;
  if (checkAnnotation(annotation)) {
    isEdit = true;
    title = "Update library";
  }

  const button = document.createElement("button");
  button.textContent = title;
  button.className = "TooltipItem-AddToLibrary";
  button.style.background = "#fff";
  button.style.paddingTop = "11px";
  button.style.paddingBottom = "11px";
  button.style.border = "0";
  button.style.width = "275px";

  button.addEventListener("click", (event) => {
    handleAddToLibrary(event, annotation, isEdit);
  });
  container.appendChild(button);
}

const handleDeleteAnnotation = (event: Event, annotation: Annotation) => {
  instance.delete(annotation);
  updateAnnotation();
};

function DeleteButtonUI(container: any) {
  const icon = `<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" focusable="false"><path d="M18 20.12L19 8.5H5l1 11.62a1.51 1.51 0 0 0 1.46 1.38h9.04a1.51 1.51 0 0 0 1.5-1.38zM8.5 5.5V4A1.5 1.5 0 0 1 10 2.5h4A1.5 1.5 0 0 1 15.5 4v1.5h-7z" fill="currentColor" fill-opacity=".01" stroke="currentColor" stroke-miterlimit="10"></path><path d="M20 8.5H4l.66-2a1.49 1.49 0 0 1 1.42-1h11.84a1.49 1.49 0 0 1 1.42 1l.66 2z" fill="currentColor" fill-opacity=".01" stroke="currentColor" stroke-linejoin="round"></path><path opacity=".5" d="M12.5 11h-1a.5.5 0 0 0-.5.5v7a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.5-.5zm3.1 8h-.52a.5.5 0 0 1-.49-.53l.46-7a.5.5 0 0 1 .5-.47h.52a.49.49 0 0 1 .49.53l-.46 7a.5.5 0 0 1-.5.47zm-7.2 0h.52a.5.5 0 0 0 .49-.53l-.46-7a.5.5 0 0 0-.5-.47h-.52a.49.49 0 0 0-.49.53l.46 7a.5.5 0 0 0 .5.47z" fill="currentColor"></path></svg>`;

  const button = document.createElement("button");
  button.innerHTML = icon;
  button.className = "TooltipItem-Delete-Annotation";
  button.style.background = "transparent";
  button.style.cursor = "pointer";
  button.style.position = "absolute";
  button.style.top = "7px";
  button.style.right = "32px";
  button.style.paddingTop = "0px";
  button.style.paddingBottom = "0px";
  button.style.border = "0";
  button.style.width = "30px";

  button.addEventListener("click", (event) => {
    handleDeleteAnnotation(event, annotation);
  });
  container.appendChild(button);
}

function ShapesAndColorsUI(container: any) {
  let buttonShapes = "";
  selectedShapes.map((item: IShape) => {
    if (item) {
      buttonShapes += `<button class="btn-shape">${item.icon}</button>`;
    }
  });

  const shapesAndColorsContainer = document.createElement("div");
  shapesAndColorsContainer.className = "section-one";

  const shapes = document.createElement("div");
  shapes.className = "shape-picker";
  shapes.innerHTML = buttonShapes;

  const color = document.createElement("div");
  color.className = "color-picker";
  color.innerHTML = `<ul style="list-style-type:none; padding:1px 0 0px;margin:0; cursor:pointer;">
            ${presetColors.map((item: IColorPreset, key: number) => {
              if (item && item.color) {
                const color = `rgb(${item.color.r}, ${item.color.g}, ${item.color.b})`;
                return `<li 
                class="pick-color" 
                title="${item.localization.defaultMessage}" 
                key="${key}"
                style="width:14.8px;height:14.8px;display:inline-block;border-radius:12px; overflow:hidden; margin:0 2px; text-indent:-1000px; cursor:pointer; background-color: ${color};"
              $nbsp;</li>`;
              }
            })}</ul>`;

  shapesAndColorsContainer.appendChild(shapes);
  shapesAndColorsContainer.appendChild(color);

  container.appendChild(shapesAndColorsContainer);
}

function FilterUI(container: any) {
  let filterItem = "";
  presetColors.map((item: IColorPreset) => {
    if (item && item.color) {
      const bgColor = `rgb(${item.color.r}, ${item.color.g}, ${item.color.b})`;
      filterItem += `
      <button class="filter-item" filter="${item.tag}" style="background-color:${bgColor}">
        ${item.localization.defaultMessage}
      </button>
      `;
    }
  });

  const html = `<div class="bottom-wrap ">
        <div class="annotation-filter">
            ${filterItem}
        </div>
      </div>`;

  // return html;

  const filterSection = document.createElement("div");
  filterSection.className = "section-two";
  filterSection.innerHTML = html;

  container.appendChild(filterSection);

  Emitter.on("REFRESH_FILTER", () => {
    refreshFIlterUI();
  });
}

function AnnotationItems(container: any) {
  const itemsSection = document.createElement("div");
  itemsSection.className = "section-three bottom-wrap";

  const itemsContainer = document.createElement("div");
  itemsContainer.className = "annotation-bank";
  itemsContainer.innerHTML = handleAnnotationItemDisplay(instance, true);

  itemsSection.appendChild(itemsContainer);

  container.appendChild(itemsSection);

  Emitter.on("REFRESH_LIBRARY", () => {
    refreshAnnotationLibrary();
  });
}

function handleAnnotationItemDisplay(
  instance: Instance,
  isReturn: boolean,
  search?: string
): any {
  const storageAnnotationLibrary: string | null = localStorage.getItem(
    StorageDataKey.AnnotationLibrary
  );
  let annotationItem = "";
  const shape: Record<string, string> = SHAPE_ICONS;

  if (storageAnnotationLibrary) {
    const library: ILibraryItem[] = JSON.parse(storageAnnotationLibrary);
    library.map((item: ILibraryItem) => {
      const libraryId = item.customData
        ? item.customData?.libraryId
          ? item.customData?.libraryId
          : generateGuid()
        : generateGuid();

      if (item.customData) {
        item.customData["libraryId"] = libraryId;
      }

      if (item && item.color) {
        const bgColor = `rgb(${item.color.r}, ${item.color.g}, ${item.color.b})`;
        annotationItem += `
        <button class="annotation-item annotation-${libraryId}" title="${
          item.text.value
        }" annotation-id="${libraryId}" filter-tag="${
          item.customData
            ? item.customData.filterTag
              ? item.customData.filterTag
              : ""
            : ""
        }" style="background-color:${bgColor}" >
          <i>${shape[item.icon]}</i>
          <span>${item.text.value}</span>
        </button>
        `;
      }
    });

    localStorage.setItem(
      StorageDataKey.AnnotationLibrary,
      JSON.stringify(library)
    );
  }
  const el = instance.contentDocument.querySelector(
    ".annotation-inspector .annotation-bank"
  );
  if (isReturn) {
    return annotationItem;
  } else {
    if (el) {
      el.innerHTML = annotationItem;
    }
  }
}

const refreshAnnotationLibrary = () => {
  handleAnnotationItemDisplay(instance, false);
  const annotationItems =
    instance.contentDocument.querySelectorAll(".annotation-item");
  removeEventListeners(annotationItems, "click", annotationReferenceFunc);
  handleAnnotationItems(
    instance,
    instance.getSelectedAnnotation(),
    presetColors
  );
};

const refreshFIlterUI = () => {
  const preset = localStorage.getItem(StorageDataKey.PresetColors);
  const color = preset ? JSON.parse(preset) : presetColors;
  color.map((item: IColorPreset, key: number) => {
    const el: any = instance.contentDocument.querySelectorAll(
      ".section-two .filter-item"
    );
    if (el && el[key]) {
      el[key].innerText = item.localization.defaultMessage;
    }
  });
};

// * FROM COLOE PICKER COMPONENT
const handleAnnotationFocus = (
  instance: Instance,
  presetColors: TColorPreset
) => {
  instance.addEventListener("annotations.focus", (event) => {
    const currentAnnotation = instance.getSelectedAnnotation() as Annotation;
    instance.update(currentAnnotation).then(() => {
      handleColorPickerOverlay(currentAnnotation, instance, presetColors);
    });
  });
};

const handleColorPickerOverlay = (
  annotation: Annotation,
  instance: Instance,
  presetColors: TColorPreset
) => {
  if (annotation && annotation instanceof PSPDFKit.Annotations.NoteAnnotation) {
    createColorPickerOverlay(
      "annotations.focus",
      instance,
      annotation,
      presetColors
    );
  } else {
    removeColorPickerOverlay(instance);
  }
};

const getAnnotationById = (
  instance: Instance,
  pageIndex: number,
  annotationId: string
) => {
  const annotations = instance.getAnnotations(pageIndex ? pageIndex : 0);
  return annotations.then((annotation) => {
    const annotationsArray = annotation.toArray();
    if (annotationsArray) {
      return annotationsArray.find(function (obj: any) {
        return obj.id ? obj.id === annotationId : null;
      });
    } else {
      return null;
    }
  });
};

const handlePopOverColor = (instance: Instance, annotation: Annotation) => {
  if (!instance && !annotation) return;
  const element = instance.contentDocument.querySelector(
    "#NoteAnnotationContent-" + annotation.id
  );
  if (element) {
    element.setAttribute("data-color", annotation.color.toCSSValue());
  }
};

const createColorPickerOverlay = (
  event: string,
  instance: Instance,
  annotation: Annotation,
  presetColors: TColorPreset
) => {
  if (event === "annotations.focus") {
    getAnnotationById(instance, annotation.pageIndex, annotation.id).then(
      (res: any) => {
        handlePopOverColor(instance, res);
        const container: HTMLElement | null =
          instance.contentDocument.querySelector(".annotation-inspector");
        if (container) {
          const parentElement = container.parentNode;
          const grandparentElement = parentElement
            ? parentElement.parentNode
            : null;
          const greatGrandparentElement = grandparentElement
            ? (grandparentElement.parentNode as HTMLElement)
            : null;
          if (greatGrandparentElement) {
            // greatGrandparentElement.classList.add("not-clickable");
          }

          const listItem: NodeListOf<HTMLDivElement> =
            container.querySelectorAll<HTMLDivElement>(".pick-color");

          listItem.forEach((item: HTMLDivElement, index: number) =>
            item.addEventListener(
              "click",
              (colorsReferenceFunc = (e: Event) => {
                e.preventDefault();
                handleColorItemsListeners(
                  e,
                  instance,
                  presetColors,
                  res,
                  index
                );
              }),
              {
                once: false,
              }
            )
          );

          const shapeItem =
            container.querySelectorAll<HTMLButtonElement>(".btn-shape");
          shapeItem.forEach((item: HTMLButtonElement, index: number) =>
            item.addEventListener(
              "click",
              (shapesReferenceFunc = (e: Event) => {
                handleShapeItem(e, instance, index, annotation);
              }),
              {
                once: false,
              }
            )
          );

          const filterTags =
            container.querySelectorAll<HTMLButtonElement>(".filter-item");
          filterTags.forEach((item: HTMLButtonElement, index: number) =>
            item.addEventListener(
              "click",
              (filterReferenceFunc = (e: Event) =>
                handleFilterTagsListeners(e, item, index, filterTags)),
              {
                once: false,
              }
            )
          );
        }
        handleAnnotationItems(instance, annotation, presetColors);
        inputSt = setTimeout(() => {
          handleLibrarySearch(instance);
        }, 1);
      }
    );
  }
};

const handleColorItemsListeners = (
  e: Event,
  instance: Instance,
  presetColors: TColorPreset,
  res: any,
  index: number
) => {
  e.stopPropagation();
  const targetElement = e.target as HTMLElement;

  if (targetElement && targetElement.classList.contains("your-target-class")) {
    const clickedElement = targetElement;

    const parentElement = clickedElement.parentElement;
    if (parentElement) {
      const siblings = Array.from(parentElement.children) as HTMLElement[];
      for (const sibling of siblings) {
        if (sibling !== clickedElement) {
          sibling.classList.remove("active");
        }
      }
    }

    clickedElement.classList.add("active");
  }

  handleColorPick(e, index, presetColors, res, instance, () => {
    // callback
  });
};

const handleFilterTagsListeners = (
  e: Event,
  item: HTMLButtonElement,
  index: number,
  filterTags: any
) => {
  e.stopPropagation();
  if (item) {
    item.classList.remove("in-active");
    if (item.classList.contains("active")) {
      filterTags.forEach(function (element: any) {
        element.classList.remove("active");
        element.classList.remove("in-active");
      });
      handleFilterTags(instance, item, true);
    } else {
      filterTags.forEach(function (element: any, key: number) {
        if (key !== index) {
          element.classList.add("in-active");
          element.classList.remove("active");
        } else {
          element.classList.add("active");
        }
      });
      handleFilterTags(instance, item, false);
    }
  }
};

const handleFilterTags = (
  instance: Instance,
  item: HTMLButtonElement,
  showAll: boolean
) => {
  const filter: string | null = item.getAttribute("filter");
  if (filter) {
    hideElementsByFilterTag(instance, filter, showAll);
  }
};

const handleLibrarySearch = (instance: Instance) => {
  instance.contentDocument
    .querySelector(".PSPDFKit-c8up8r9n155axqjtb8dat8e5t")
    ?.addEventListener("input", (event: Event) => {
      handleSearch(event, instance);
    });
};

const hideElementsByFilterTag = (
  instance: Instance,
  targetValue: string,
  showAll: boolean
): void => {
  const elements: NodeListOf<Element> =
    instance.contentDocument.querySelectorAll("[filter-tag]");
  elements.forEach((element: Element) => {
    const filterTagValue: string | null = element.getAttribute("filter-tag");
    if (showAll) {
      activeTag = undefined;
      (element as HTMLElement).style.display = "block"; // Hide the element
    } else {
      activeTag = targetValue;
      if (filterTagValue === targetValue) {
        (element as HTMLElement).style.display = "block"; // Hide the element
      } else {
        (element as HTMLElement).style.display = "none"; // Hide the element
      }
    }
  });
};

const handleAnnotationItems = (
  instance: Instance,
  annotation: Annotation,
  presetColors: TColorPreset
) => {
  const container: HTMLElement | null = instance.contentDocument.querySelector(
    ".annotation-inspector"
  );
  if (container) {
    const annotationItem =
      container.querySelectorAll<HTMLButtonElement>(".annotation-item");
    annotationItem.forEach((item: HTMLButtonElement, index: number) =>
      item.addEventListener(
        "click",
        (annotationReferenceFunc = (e: Event) => {
          e.stopPropagation();
          handleAnnotationItem(instance, index, annotation, presetColors);
          // library = handleAnnotationLibrary();
        }),
        {
          once: false,
        }
      )
    );
  }
};

const handleAnnotationItem = (
  instance: Instance,
  index: number,
  selectedAnnotation: Annotation,
  presetColor: TColorPreset
) => {
  const storageAnnotationLibrary: string | null = localStorage.getItem(
    StorageDataKey.AnnotationLibrary
  );
  if (storageAnnotationLibrary) {
    const library = JSON.parse(storageAnnotationLibrary);
    const annotation = library[index];
    const color = new PSPDFKit.Color({
      r: annotation.color.r,
      g: annotation.color.g,
      b: annotation.color.b,
    });

    const text = annotation.text;
    const currentAnnotation: Annotation | null | undefined =
      instance.getSelectedAnnotation();

    const options: IOption = {
      filterTag: findColorTag(color, presetColor),
    };
    if (currentAnnotation) {
      if (currentAnnotation.customData) {
        options.annotationId = currentAnnotation.customData
          .annotationId as number;
      }

      const button = instance.contentDocument.querySelector(
        ".TooltipItem-AddToLibrary"
      );

      if (button) {
        button.textContent = "Update library";
      }

      const editedAnnotation = currentAnnotation
        .set("color", color)
        .set("text", text)
        .set("customData", {
          ...options,
          libraryId: annotation.customData.libraryId,
        })
        .set("icon", annotation.icon);
      // .set("boundingBox", selectedAnnotation.boundingBox);

      instance.update(editedAnnotation).then(() => {
        try {
          // const matchIndex = library.findIndex(
          //   (a: Annotation) =>
          //     a && a.text.value && a.text.value == editedAnnotation["text"].value
          // )
          //   ? library.findIndex(
          //       (a: Annotation) =>
          //         a &&
          //         a.text.value &&
          //         a.text.value == editedAnnotation["text"].value
          //     )
          //   : null;
        } catch (err) {
          console.error(err, "ERROR");
        }
      });
    }
  }
};

const findColorTag = (color: Color, presetColors: TColorPreset) => {
  const filter = presetColors.find((obj: IColorPreset) => {
    return obj.color &&
      color.r === obj.color.r &&
      color.g === obj.color.g &&
      color.b === obj.color.b
      ? obj
      : "";
  });
  return filter ? filter.tag : "";
};

const handleShapeItem = (
  e: Event,
  instance: Instance,
  index: number,
  annotation: Annotation
) => {
  e.stopPropagation();
  getAnnotationById(
    instance,
    annotation ? annotation.pageIndex : 0,
    annotation?.id
  ).then((res) => {
    if (res) {
      const color = new PSPDFKit.Color({
        r: res.color.r,
        g: res.color.g,
        b: res.color.b,
      });

      const editedAnnotation = res
        .set("color", color)
        .set("icon", selectedShapes[index].name)
        .set("text", res.text);

      instance.update(editedAnnotation);
    }
  });
};

const handleColorPick = (
  e: Event,
  index: number,
  presetColors: TColorPreset,
  annotation: Annotation | null, // Adjust type here, assuming it can be null
  instance: Instance,
  callback: any
): void => {
  e.preventDefault();
  e.stopPropagation();
  if (annotation) {
    getAnnotationById(
      instance,
      annotation ? annotation.pageIndex : 0,
      annotation?.id
    ).then((res) => {
      const item = presetColors[index];
      if (item.color) {
        const color = new PSPDFKit.Color({
          r: item.color.r,
          g: item.color.g,
          b: item.color.b,
        });
        setColorPreset(instance, color);

        if (res) {
          const options: any = {
            ...res.customData,
            filterTag: findColorTag(color, presetColors),
          };

          if (res.customData && typeof res.customData.annotationId === "number")
            options.annotationId = res.customData.annotationId;
          const editedAnnotation = res
            .set("color", color)
            .set("customData", options);

          if (callback) {
            callback(editedAnnotation, instance);
          }

          instance.update(editedAnnotation).then(() => {
            handlePopOverColor(instance, editedAnnotation);
          });
        }
      }
    });
  }
};
const setColorPreset = (instance: Instance, color: Color) => {
  const colorPreset = instance.annotationPresets;
  for (const property in colorPreset) {
    const item = colorPreset[property];
    if ("strokeColor" in item) {
      item.strokeColor = new PSPDFKit.Color(color);
    }
    if ("color" in item) {
      item.color = new PSPDFKit.Color(color);
    }
    if ("backgroundColor" in item) {
      item.backgroundColor = new PSPDFKit.Color(color);
    }
  }
  instance.setAnnotationPresets(colorPreset);
};

let activeTag!: string | undefined;
let inputSt!: any;
let shapesReferenceFunc: any;
let colorsReferenceFunc: any;
let filterReferenceFunc: any;
let annotationReferenceFunc: any;

const removeColorPickerOverlay = (instance: Instance) => {
  if (Object.keys(inspectors).length > 0) {
    inspectors = {};
    activeTag = undefined;
    clearTimeout(inputSt);
    instance.contentDocument
      .querySelector(".PSPDFKit-c8up8r9n155axqjtb8dat8e5t")
      ?.removeEventListener("input", (event: Event) => {
        handleSearch(event, instance);
      });
  }

  const filterTags =
    instance.contentDocument.querySelectorAll<HTMLButtonElement>(
      ".filter-item"
    );
  const shapeItems =
    instance.contentDocument.querySelectorAll<HTMLButtonElement>(".btn-shape");
  const colors =
    instance.contentDocument.querySelectorAll<HTMLDivElement>(".pick-color");
  const annotationItems =
    instance.contentDocument.querySelectorAll<HTMLButtonElement>(
      ".annotation-item"
    );

  removeEventListeners(filterTags, "click", filterReferenceFunc);
  removeEventListeners(shapeItems, "click", shapesReferenceFunc);
  removeEventListeners(colors, "click", colorsReferenceFunc);
  removeEventListeners(annotationItems, "click", annotationReferenceFunc);
};

const removeEventListeners = (
  elements: NodeListOf<HTMLElement>,
  eventType: string,
  listenerFunction: EventListener
) => {
  elements.forEach((item: HTMLElement, index: number) => {
    item.removeEventListener(eventType, listenerFunction);
  });
};

const handleSearch = (event: Event, instance: Instance) => {
  const evt = event.target as HTMLDivElement;
  const searchText = evt.innerHTML.trim();
  searchAnnotationBank(searchText, instance);
};

export const searchAnnotationBank = (search: string, instance: Instance) => {
  const library = handleAnnotationLibrary();
  const filteredData = filterAnnotationLibrary(library, activeTag) || library;

  const data = searchAnnotationLibrary(filteredData, search);

  if (search && search !== "") {
    const buttons =
      instance.contentDocument.querySelectorAll(".annotation-item");
    // console.log(buttons);
    buttons.forEach((o: Element) => {
      const element = o as HTMLElement;
      element.style.display = "none";
    });
    data.map((item: Annotation) => {
      if (item.customData) {
        const buttons: HTMLElement | null =
          instance.contentDocument.querySelector(
            ".annotation-" + item.customData.libraryId
          );
        if (buttons) {
          buttons.style.display = "block";
        }
      }
    });
  } else {
    if (activeTag) {
      //
      handleAnnotationButtons(filteredData, instance);
    } else {
      const buttons =
        instance.contentDocument.querySelectorAll(".annotation-item");
      if (buttons) {
        buttons.forEach((o) => {
          const button = o as HTMLElement;
          button.style.display = "block";
        });
      }
    }
  }
};

const handleAnnotationLibrary = () => {
  const storageAnnotationLibrary: string | null = localStorage.getItem(
    StorageDataKey.AnnotationLibrary
  );
  if (storageAnnotationLibrary) {
    const library = JSON.parse(storageAnnotationLibrary);
    return library;
  } else {
    localStorage.setItem(
      StorageDataKey.AnnotationLibrary,
      JSON.stringify(ANNOTATION_BANK)
    );
  }
};

// searchAnnotationLibrary = (
//   library: IAnnotationWithShowOption[],
//   text: string | undefined
// ) => {
//   if (text === "" || !text) return library;
//   return library.filter((o: IAnnotationWithShowOption) => {
//     if (o) {
//       return o.text.value
//         .toLowerCase()
//         .search(escapeRegExp(text.toLowerCase())) > -1
//         ? o
//         : null;
//     }
//   });
// };

const filterAnnotationLibrary = (
  library: Annotation[],
  tag: string | undefined
) => {
  if (tag) {
    const arr: Annotation[] = [];
    library.map((o: Annotation) => {
      if (o.customData && tag === o.customData.filterTag) {
        arr.push(o);
      }
    });
    return arr;
  } else {
    return library;
  }
};

const searchAnnotationLibrary = (
  library: Annotation[],
  text: string | undefined
) => {
  if (text === "" || !text) return library;
  return library.filter((o: Annotation) => {
    if (o) {
      return o.text.value
        .toLowerCase()
        .search(escapeRegExp(text.toLowerCase())) > -1
        ? o
        : null;
    }
  });
};

const handleAnnotationButtons = (data: Annotation[], instance: Instance) => {
  const buttons = instance.contentDocument.querySelectorAll(".annotation-item");
  buttons.forEach((o) => {
    const button = o as HTMLElement;
    button.style.display = "none";
  });
  data.map((item: Annotation) => {
    const buttons: HTMLElement | null = instance.contentDocument.querySelector(
      ".annotation-" + item.customtData?.libraryId
    );
    if (buttons) {
      buttons.style.display = "block";
    }
  });
};

// ########################################################
// END
