<template>
  <div class="flex w-full">
    <div
      class="bg-white rounded-l-lg my-3 pl-[40px] pr-[33px] py-[47px] relative w-[75%] min-h-[100vh]"
    >
      <DigitalSubmission
        v-if="bulkUploadType === 'digital'"
        :uploadingFiles="uploadingFiles"
        :filesSucceeded="filesSucceeded"
        :filesFailed="filesFailed"
        :uploadProgress="uploadProgress"
        :digitalFirstSubmissionUuid="digitalFirstSubmissionUuid"
        @onSubmit="handleUpload"
        @onFinish="handleFinishUpload"
      />
      <PaperSubmission
        v-if="bulkUploadType === 'paper'"
        :hasStudents="studentsCount > 0"
        :downloadingCoversheets="downloadingCoversheets"
        :submitting="uploadingAndSplitting"
        @onDownloadCoversheets="handleDownloadCoversheets"
        @onUploadAndSplit="handleUploadAndSplit"
        @onTabChange="handlePaperTabChange"
      />
    </div>
    <div
      class="bg-white w-[25%] min-h-[calc(100vh_-_80px)] sticky top-[80px] border-x border-solid border-flohh-neutral-85 my-3"
    >
      <BulkUploadSidePanel
        @onClassSelect="handleClassSelect"
        @onAssignmentSelect="handleAssignmentSelect"
        :canCreate="paperActiveTab === 0"
        :error="error"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-facing-decorator";
import AppButton from "@/components/Layout/Buttons/AppButton.vue";
import AppTypographyText from "@/components/Layout/Typhography/AppTypographyText.vue";
import ModalUtility from "@/components/utilities/ModalUtility.vue";
import { useToast } from "primevue/usetoast";
import DigitalSubmission from "./DigitalSubmission.vue";
import BulkUploadSidePanel from "./BulkUploadSidePanel.vue";
import PaperSubmission from "./PaperSubmission.vue";
import { ClassData } from "@/store/class/classTypes";
import { AssignmentDashboard } from "@/store/dashboard/dashboardTypes";
import BulkUploadService from "@/services/BulkUploadService";
import {
  CoversheetPayload,
  UploadMultipleFilesPayload,
  UploadSubmissions,
} from "@/models/BulkUpload";
import {
  AxiosResponse,
  AxiosRequestConfig,
  AxiosProgressEvent,
  AxiosError,
} from "axios";
import MediaService from "@/services/MediaService";
import { InitializeMedia } from "@/utils/initializer";
import emitter from "@/config/emitter";
import { arrayBufferToFile, fileToBase64 } from "@/utils/helper";
import PSPDFKit from "pspdfkit";
import { getPspdfkitLicenseKey } from "@/utils/EnvironmentUtils";
import { getGoogleDriveFile } from "@/utils/googleDrive";
import { UploadProgress } from "@/components/utilities/utilitiesTypes";
import { PendingActions } from "@/store/dashboard/dashboardTypes";
import { event } from "vue-gtag";

@Component({
  components: {
    AppButton,
    AppTypographyText,
    ModalUtility,
    DigitalSubmission,
    BulkUploadSidePanel,
    PaperSubmission,
  },
})
export default class BulkUploadComponent extends Vue {
  private bulkUploadService = new BulkUploadService();
  private mediaService: MediaService = new MediaService();
  eventBus = emitter;

  @Prop({
    type: String,
    required: true,
  })
  bulkUploadType!: string;

  toast = useToast();

  error = {
    className: "",
    assignmentName: "",
  };

  classObj!: ClassData;
  assignmentObj!: AssignmentDashboard;
  studentsCount = 0;

  paperActiveTab = 0;
  downloadingCoversheets = false;
  uploadingFiles = false;
  uploadingAndSplitting = false;

  filesSucceeded: File[] = [];
  filesFailed: File[] = [];
  uploadProgress: UploadProgress = {
    fileName: "",
    progress: 0,
  };

  digitalFirstSubmissionUuid = "";

  docTypes = [
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/msword",
  ];

  allowedFileTypes = [
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/msword",
    "application/pdf",
  ];
  PSPDFKIT_LICENSE_KEY = getPspdfkitLicenseKey();

  @Watch("classObj.code")
  classNameWatcher(newValue: string, oldValue: string) {
    if (newValue) {
      this.error.className = "";
    } else if (!newValue && oldValue) {
      this.error.className =
        "Please input a class name or select an existing class on the drop-down listt";
    }
  }

  @Watch("assignmentObj.title")
  assignmentNameWatcher(newValue: string, oldValue: string) {
    if (newValue) {
      this.error.assignmentName = "";
    } else if (!newValue && oldValue) {
      this.error.assignmentName =
        "Please input an assignment name or select an existing assignment on the drop-down list";
    }
  }

  handleClassSelect(data: ClassData) {
    if (data.uuid) {
      this.classObj = data;
      this.studentsCount = data.students ? data.students.length : 0;
    }
  }

  handleAssignmentSelect(data: AssignmentDashboard) {
    if (data.uuid) {
      this.assignmentObj = data;
    }
  }

  handlePaperTabChange(tabIndex: number) {
    this.paperActiveTab = tabIndex;
  }

  validateForm() {
    let isFormValid = true;
    if (
      this.classObj.uuid === "create" &&
      !this.classObj.code &&
      this.assignmentObj.uuid === "create" &&
      !this.assignmentObj.title
    ) {
      this.error.className =
        "Please input a class name or select an existing class on the drop-down list";
      this.error.assignmentName =
        "Please input an assignment name or select an existing assignment on the drop-down list";
      this.showToastMessage(
        "error",
        "Invalid Class Name & Assignment Name",
        "Please input a class name and an assignment name so we can file your submissions in the right place."
      );
      isFormValid = false;
    } else {
      if (this.classObj.uuid === "create" && !this.classObj.code) {
        this.error.className =
          "Please input a class name or select an existing class on the drop-down list";
        this.showToastMessage(
          "error",
          "Invalid Class Name",
          "Please input a class name so we can file your submissions in the right place."
        );
        isFormValid = false;
      }

      if (this.assignmentObj.uuid === "create" && !this.assignmentObj.title) {
        this.error.assignmentName =
          "Please input an assignment name or select an existing assignment on the drop-down list";
        this.showToastMessage(
          "error",
          "Invalid Assignment Name",
          "Please input an assignment name so we can file your submissions in the right place."
        );
        isFormValid = false;
      }
    }

    return isFormValid;
  }

  async handleDownloadCoversheets(studentCount: number) {
    try {
      this.eventBus.emit("EVENT_TRIGGER", "CP002");
      this.downloadingCoversheets = true;
      const isValid = this.validateForm();
      if (isValid) {
        const classUuid =
          this.classObj.uuid === "create" ? "" : this.classObj.uuid;
        const assignmentUuid =
          this.assignmentObj.uuid === "create" ? "" : this.assignmentObj.uuid;

        const payload: CoversheetPayload = {
          className: this.classObj.code,
          assignmentName: this.assignmentObj.title,
        };
        if (classUuid) {
          payload.classUuid = classUuid;
        }
        if (assignmentUuid) {
          payload.assignmentUuid = assignmentUuid;
        }
        if (studentCount > 0) {
          payload.numberOfStudents = studentCount;
        }

        const response: AxiosResponse =
          await this.bulkUploadService.getCoversheetWithoutStudents(payload);
        if (response.data.ok) {
          const responseData = response.data.data;
          const linkSource = `data:application/pdf;base64,${responseData.coversheet}`;
          const downloadLink = document.createElement("a");
          const fileName = `${payload.assignmentName} Coversheets.pdf`;

          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();

          if (!classUuid) {
            this.eventBus.emit("REFRESH_CLASSES_DROPDOWN", responseData.class);
            this.eventBus.emit("LOAD_CLASSES");
          }
          if (!assignmentUuid) {
            this.eventBus.emit(
              "REFRESH_ASSIGNMENTS_DROPDOWN",
              responseData.assignment
            );
          }
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.downloadingCoversheets = false;
    }
  }

  async handleUpload(files: File[] | string) {
    try {
      this.uploadingFiles = true;
      if (typeof files === "string") {
        const fileData = JSON.parse(files);

        const isValid = this.validateForm();

        if (fileData.length === 0) return;

        if (isValid) {
          const filesToken: string[] = await this.saveAllMediaFromGoogleDrive(
            fileData
          );
          await this.handleUploadMultipleSubmissions(filesToken);
        }
      } else {
        const isValid = this.validateForm();
        if (files.length < 1) return;

        if (isValid) {
          const pdfFiles = await this.convertToPDF(files);
          const filesToken: string[] = await this.saveAllMedia(pdfFiles);
          await this.handleUploadMultipleSubmissions(filesToken);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.uploadingFiles = false;
    }
  }

  async saveAllMediaFromGoogleDrive(gDriveFiles: Record<string, any>) {
    try {
      const filesToken: string[] = [];
      const filesSucceeded: File[] = [];
      const filesFailed: File[] = [];

      for (let i = 0; i < gDriveFiles.length; i++) {
        const gDriveFile = gDriveFiles[i];
        this.uploadProgress = {
          fileName: gDriveFile.name.split(".")[0],
          progress: 1,
        };

        const currentFile = await getGoogleDriveFile(gDriveFile);

        const fileArray: File[] = [];
        fileArray.push(currentFile);

        const pdfFile = await this.convertToPDF(fileArray);
        const file = pdfFile[0];

        const config: AxiosRequestConfig = {
          onUploadProgress: (progressEvent: AxiosProgressEvent) => {
            if (progressEvent.total !== undefined) {
              this.uploadProgress = {
                fileName: gDriveFile.name.split(".")[0],
                progress: Math.round(
                  (progressEvent.loaded / progressEvent.total) * 100
                ),
              };
            }
          },
        };
        const payload = InitializeMedia(file, "revision");

        const response: AxiosResponse = await this.mediaService.postMedia(
          payload,
          config
        );
        if (response.data.ok) {
          filesToken.push(response.data.data.accessToken);
          filesSucceeded.push(file);
        } else {
          filesFailed.push(file);
        }
      }

      this.filesSucceeded = filesSucceeded;
      this.filesFailed = filesFailed;
      return filesToken;
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  async saveAllMedia(files: File[]) {
    try {
      const filesToken: string[] = [];
      const filesSucceeded: File[] = [];
      const filesFailed: File[] = [];

      for (let i = 0; i < files.length; i++) {
        const currentFile = files[i];

        const config: AxiosRequestConfig = {
          onUploadProgress: (progressEvent: AxiosProgressEvent) => {
            if (progressEvent.total !== undefined) {
              this.uploadProgress = {
                fileName: currentFile.name.split(".")[0],
                progress: Math.round(
                  (progressEvent.loaded / progressEvent.total) * 100
                ),
              };
            }
          },
        };
        const payload = InitializeMedia(currentFile, "revision");

        const response: AxiosResponse = await this.mediaService.postMedia(
          payload,
          config
        );
        if (response.data.ok) {
          filesToken.push(response.data.data.accessToken);
          filesSucceeded.push(currentFile);
        } else {
          filesFailed.push(currentFile);
        }
      }

      this.filesSucceeded = filesSucceeded;
      this.filesFailed = filesFailed;
      return filesToken;
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  async handleUploadMultipleSubmissions(fileTokens: string[]) {
    try {
      const classUuid =
        this.classObj.uuid === "create" ? "" : this.classObj.uuid;
      const assignmentUuid =
        this.assignmentObj.uuid === "create" ? "" : this.assignmentObj.uuid;

      const payload: UploadMultipleFilesPayload = {
        components: fileTokens,
        className: this.classObj.code,
        assignmentName: this.assignmentObj.title,
      };

      if (classUuid) {
        payload.classUuid = classUuid;
      }
      if (assignmentUuid) {
        payload.assignmentUuid = assignmentUuid;
      }

      const response: AxiosResponse =
        await this.bulkUploadService.postBulkUploadMultipleDocuments(payload);
      if (response.data.ok) {
        this.digitalFirstSubmissionUuid = response.data.data[0].data.uuid;
        this.triggerGAEvent(response.data.data.length);
        this.showToastMessage(
          "success",
          "Upload Success",
          "Submissions uploaded successfully"
        );

        this.checkOnboardingTask();

        const gtagData: any = {
          event: "submission_uploaded",
          params: {
            submission_type: "bulk_upload",
          },
        };
        this.eventBus.emit("GTAG_EVENT", gtagData);
        if (!classUuid) {
          this.eventBus.emit("LOAD_CLASSES");
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.uploadProgress = {
        fileName: "",
        progress: 0,
      };
    }
  }

  async handleUploadAndSplit(files: File[]) {
    try {
      this.uploadingAndSplitting = true;
      const isValid = this.validateForm();
      if (files.length < 1) return;

      if (isValid) {
        const classUuid =
          this.classObj.uuid === "create" ? "" : this.classObj.uuid;
        const assignmentUuid =
          this.assignmentObj.uuid === "create" ? "" : this.assignmentObj.uuid;

        const pdfFiles = await this.convertToPDF(files);

        const payload: UploadSubmissions = {
          file: pdfFiles[0],
          assignmentName: this.assignmentObj.title,
          className: this.classObj.code,
        };
        if (classUuid) {
          payload.classUuid = classUuid;
        }
        if (assignmentUuid) {
          payload.assignmentUuid = assignmentUuid;
        }

        let response: AxiosResponse;
        if (assignmentUuid) {
          response = await this.bulkUploadService.postBulkUploadDocument({
            assignmentUuid: assignmentUuid,
            file: payload.file,
          });
        } else {
          response =
            await this.bulkUploadService.postBulkUploadWithoutAssignment(
              payload
            );
        }
        if (response.data.ok) {
          this.eventBus.emit("EVENT_TRIGGER", "BU001");
          const gtagData: any = {
            event: "submission_uploaded",
            params: {
              submission_type: "bulk_upload",
            },
          };
          this.eventBus.emit("GTAG_EVENT", gtagData);
          this.$router.push({
            name: "BulkUploadSplit",
            params: {
              type: "split",
              id: assignmentUuid
                ? assignmentUuid
                : response.data.data.assignment.uuid,
            },
          });
          this.eventBus.emit("REFRESH_PENDING_ACTIONS");

          if (!classUuid) {
            this.eventBus.emit("LOAD_CLASSES");
          }
        }
      }
    } catch (error) {
      const err = error as AxiosError;
      const response = err.response as AxiosResponse;
      if (response.data.message.includes("already in pending status")) {
        this.redirectToSplittingPage(this.assignmentObj.uuid);
      }
    } finally {
      this.uploadingAndSplitting = false;
    }
  }

  async convertToPDF(files: File[]) {
    const fileArray: File[] = [];

    for (let i = 0; i < files.length; i++) {
      let file = files[i];
      if (this.docTypes.includes(file.type)) {
        const fileBase64 = await fileToBase64(file);

        const pspdfkitResponse = await PSPDFKit.convertToPDF({
          document: `data:application/pdf;base64,${fileBase64}`,
          licenseKey: this.PSPDFKIT_LICENSE_KEY,
          container: ".pdf-preview-container",
        });

        if (pspdfkitResponse) {
          let convertedFile = arrayBufferToFile(
            pspdfkitResponse,
            file.name.replace(/\.[^/.]+$/, ".pdf")
          );

          file = structuredClone(convertedFile);
          fileArray.push(file);
        } else {
          fileArray.push(file);
        }
      } else {
        fileArray.push(file);
      }
    }
    return fileArray;
  }

  checkOnboardingTask() {
    const state = this.$store.getters["dashboard/getPendingActions"];
    const pendingActions = state.pendingActions;

    const submissionTasks = pendingActions.filter(
      (item: PendingActions) =>
        item.type === "onboardingUploadSubmission" &&
        item.status !== "fulfilled"
    );

    if (submissionTasks.length > 0) {
      this.eventBus.emit("CHECK_FULFILLED_TASKS");
    }
  }

  redirectToSplittingPage(uuid: string) {
    this.$router.push({
      name: "BulkUploadSplit",
      params: { type: "split", id: uuid },
    });
  }

  handleFinishUpload() {
    this.filesSucceeded = [];
    this.filesFailed = [];
  }

  triggerGAEvent(submissionCount: number) {
    const gaStorage = localStorage.getItem("GA_SESSION");
    const gaSession = gaStorage ? JSON.parse(gaStorage) : null;
    const authData = localStorage.getItem("auth");
    const userId = authData ? JSON.parse(authData).data.uuid : null;
    const gaData = {
      user_id: gaSession ? (gaSession.id ? gaSession.id : userId) : userId,
      timestamp: new Date().toISOString(),
      action_count: submissionCount,
    };
    event("submission_uploaded_count", gaData);
  }

  showToastMessage(
    severity: "error" | "success",
    title: string,
    message: string
  ) {
    this.toast.add({
      severity: severity,
      summary: title,
      detail: message,
      life: 5000,
    });
  }
}
</script>

<style scoped lang="scss"></style>
./type
