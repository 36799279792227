<script lang="ts">
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import { Vue, Component, Prop, Watch } from "vue-facing-decorator";
import { icons as AppIcons } from "../../../utils/icons";
import { useConfirm } from "primevue/useconfirm";
import { useToast } from "primevue/usetoast";
import ConfirmDialog from "primevue/confirmdialog";
import emitter from "@/config/emitter";
import UpdatesModal from "@/components/Dashboard/Updates/UpdatesModal.vue";
import { ProfileDetails } from "@/config/auth";
import PersonAvatar from "@/components/utilities/PersonAvatar.vue";
import MyAccount from "@/components/User/MyAccount.vue";
import { PendingActions } from "@/store/dashboard/dashboardTypes";
import SubscriptionInvitationModal from "@/components/Dashboard/SubscriptionInvitation/SubscriptionInvitationModal.vue";

@Component({
  components: {
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    ConfirmDialog,
    UpdatesModal,
    PersonAvatar,
    MyAccount,
    SubscriptionInvitationModal,
  },
})
export default class UserMenu extends Vue {
  @Prop({
    type: Boolean,
    required: false,
  })
  isSuperAdmin!: boolean;

  @Prop({
    type: String,
    required: true,
  })
  role!: string;

  @Prop({
    type: Object,
    required: true,
  })
  userInfo!: ProfileDetails;

  @Prop({
    type: Array,
    required: true,
  })
  pendingActions!: PendingActions[];

  icons = AppIcons;
  isUpdatesModalOpen = false;
  isMyAccountModalOpen = false;
  subscriptionInvitationModalOpen = false;
  inviteSubsInfo!: PendingActions;

  confirm = useConfirm();
  toast = useToast();
  eventBus = emitter;

  @Watch("pendingActions")
  pendingActionsWatcher() {
    this.handleUpdates();
  }

  mounted() {
    this.handleUpdates();
    this.eventBus.on("TOGGLE_UPDATES_MODAL", (flag) => {
      this.isUpdatesModalOpen = flag;
    });

    this.eventBus.on(
      "OPEN_SUBSCRIPTION_INVITATION",
      async (subscriptionInvites) => {
        this.inviteSubsInfo = subscriptionInvites;
        this.subscriptionInvitationModalOpen = true;
      }
    );
  }

  async handleUpdates() {
    try {
      const updatesModalClosedOnce = localStorage.getItem("updatesModalClosed");

      if (!updatesModalClosedOnce) {
        const pendingActions = this.pendingActions;
        if (pendingActions && pendingActions.length > 0) {
          const learningGoalActions = pendingActions.find(
            (item: PendingActions) => item.type === "learningGoals"
          );

          if (learningGoalActions) {
            this.isUpdatesModalOpen = true;
          }

          const subscriptionInvites = pendingActions.find(
            (item: PendingActions) =>
              item.type === "subscriptionTeacherInvite" &&
              item.status !== "fulfilled"
          );

          if (subscriptionInvites) {
            this.inviteSubsInfo = subscriptionInvites;
            this.subscriptionInvitationModalOpen = true;
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  handleUpdatesModalClose() {
    localStorage.setItem("updatesModalClosed", "true");
  }

  handleSubscriptionClose(isRefresh: boolean) {
    this.subscriptionInvitationModalOpen = false;

    if (isRefresh) {
      this.eventBus.emit("TOGGLE_UPDATES_MODAL", false);
    }
  }

  handleClickMyAccount() {
    if (this.role === "student") {
      this.eventBus.emit("EVENT_TRIGGER", "G007");
      this.isMyAccountModalOpen = true;
    } else {
      this.eventBus.emit("EVENT_TRIGGER", "G016");
      window.open(`${process.env.VUE_APP_ACCOUNTS_URL}/login`, "_blank");
    }
  }

  handleConfirm() {
    this.confirm.require({
      message: "Are you sure you want to logout?",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      acceptClass:
        "flex justify-center items-center gap-x-[8px] rounded-[8px] font-flohh-font-medium px-[20px] py-[12px] bg-flohh-secondary-green hover:!bg-flohh-secondary-green-dark text-flohh-text-body h-[45px] !shadow-none !border-0",
      accept: () => {
        this.handleLogout();
      },
    });
  }

  async handleLogout() {
    if (this.role) {
      const isTeacher = this.role === "teacher";
      const isStudent = this.role === "student";
      const isSuperAdmin = this.role === "super admin";

      if (!isStudent && !isSuperAdmin) {
        if (this.$route.name === "Marking") {
          await this.eventBus.emit("SAVE_MARKING");
        }
      }

      localStorage.clear();
      this.$store.dispatch("clearUser");
      if (isTeacher) {
        this.handleRedirect("/login");
      } else if (isStudent) {
        this.handleRedirect("/student/login");
      } else {
        this.handleRedirect("/superadmin/login");
      }
    }
  }

  handleClickUpdates() {
    this.eventBus.emit("EVENT_TRIGGER", "G006");
    this.isUpdatesModalOpen = true;
  }

  handleRedirect(link: string) {
    window.location.href = link;
  }
}
</script>
<template>
  <Menu as="div" class="relative">
    <MenuButton
      class="flex items-center gap-2 pr-3 pl-1 py-1 bg-flohh-neutral-95 hover:bg-flohh-neutral-85 rounded-full"
      v-tooltip.left="{
        value: 'Your profile menu',
        showDelay: 500,
      }"
    >
      <PersonAvatar
        :firstName="userInfo.profile.firstName"
        :lastName="userInfo.profile.lastName"
        :background="false"
        size="lg"
      />
      <span class="hidden lg:flex lg:items-center">
        <span v-html="icons.arrowDropDownBlack" aria-hidden="true" />
      </span>
    </MenuButton>
    <transition
      enter-active-class="transition duration-100 ease-out"
      enter-from-class="transform scale-95 opacity-0"
      enter-to-class="transform scale-100 opacity-100"
      leave-active-class="transition duration-75 ease-in"
      leave-from-class="transform scale-100 opacity-100"
      leave-to-class="transform scale-95 opacity-0"
    >
      <MenuItems
        class="absolute right-0 z-10 px-2 pb-2 mt-2.5 w-auto origin-top-right rounded-md bg-white shadow-lg border border-flohh-neutral-85 border-solid"
      >
        <div class="pb-2 pt-4 px-2 flex gap-2">
          <PersonAvatar
            :firstName="userInfo.profile.firstName"
            :lastName="userInfo.profile.lastName"
            class="!bg-flohh-secondary-blue"
            size="lg"
          />
          <div>
            <p class="text-nowrap font-flohh-font-bold text-flohh-text-body">
              {{ userInfo.profile.firstName }} {{ userInfo.profile.lastName }}
            </p>
            <p class="text-flohh-text-caption">
              {{ userInfo.workEmail }}
            </p>
          </div>
        </div>
        <MenuItem v-if="!isSuperAdmin">
          <button
            class="w-full py-2 px-4 flex justify-start hover:bg-flohh-primary-light-pink rounded-[4px] items-center text-flohh-text-body"
            @click="handleClickMyAccount"
            v-tooltip.left="{
              value: 'Your account',
              showDelay: 500,
            }"
          >
            My Account
          </button>
        </MenuItem>
        <MenuItem v-if="!isSuperAdmin">
          <button
            class="w-full py-2 px-4 flex justify-start hover:bg-flohh-primary-light-pink rounded-[4px] items-center text-flohh-text-body"
            @click="handleClickUpdates"
          >
            Updates
          </button>
        </MenuItem>
        <MenuItem>
          <button
            class="w-full py-2 px-4 flex justify-start hover:bg-flohh-primary-light-pink rounded-[4px] items-center text-flohh-text-body"
            @click="handleConfirm"
            v-tooltip.left="{ value: 'Logout', showDelay: 500 }"
          >
            Logout
          </button>
        </MenuItem>
      </MenuItems>
    </transition>
    <MyAccount v-if="role" v-model="isMyAccountModalOpen" :role="role" />
    <UpdatesModal
      v-model="isUpdatesModalOpen"
      :updates="pendingActions"
      @onUpdatesModalClose="handleUpdatesModalClose"
    />

    <SubscriptionInvitationModal
      v-model="subscriptionInvitationModalOpen"
      :inviteInfo="inviteSubsInfo"
      @onClose="handleSubscriptionClose"
    />
  </Menu>
  <ConfirmDialog />
</template>
