<template>
  <FormField label="Comment Bank">
    <div
      v-if="selectedCommentBankName"
      class="bg-flohh-primary-pink rounded-md"
    >
      <div class="flex py-[12px] px-[20px] justify-between items-center gap-2">
        <Ellipsis :content="selectedCommentBankName" />
        <AppDropdownButton
          size="xs"
          @clickMenu="handleSelectMenu"
          :menus="statuses"
          width="w-30"
          text
          dropdownIcon="moreOptionBlack"
          :loading="detaching"
          :disabled="detaching"
          class="!px-0 !w-[15px]"
        />
      </div>
    </div>
    <AppButton
      v-else
      type="transparent"
      label="Comment Bank"
      @click="handleClickCreate"
      iconLeft="plusPink"
      textColor="text-flohh-primary-pink"
      borderColor="border-flohh-primary-pink"
      size="md"
    />
  </FormField>
  <ModalUtility
    v-model="visible"
    width="614px"
    title="Select a Comment Bank"
    @onClose="visible = false"
    :draggable="false"
  >
    <template #content>
      <div class="px-[28px] py-[30px] h-[364px] overflow-auto">
        <div v-if="!listLoading" class="flex flex-col">
          <div
            class="flex gap-2 items-center w-full px-4 py-4 hover:bg-flohh-neutral-95 cursor-pointer"
            v-for="(item, index) in list"
            :key="index"
            :class="[
              {
                'bg-flohh-neutral-95': item.uuid === selectedCommentBank,
              },
            ]"
            @click="handleCommentBankClick(item.uuid)"
          >
            <FormRadioButton
              v-model="selectedCommentBank"
              :name="index.toString()"
              :inputId="index.toString()"
              :value="item.uuid"
            />
            <label
              :for="item.name"
              class="font-flohh-font-medium text-flohh-text-body cursor-pointer"
              >{{ item.name }}</label
            >
          </div>
        </div>
        <div v-else>
          <ProgressLoader />
        </div>
      </div>
    </template>
    <template #footer>
      <div class="flex pt-5 border-t border-solid border-flohh-neutral-85">
        <div class="flex-1 flex justify-start items-center">
          <AppButton @click="handleManageCommentBank" text blackLabel>
            Manage Comment Banks
          </AppButton>
        </div>
        <div class="flex-1 flex justify-end items-center gap-x-4">
          <AppButton
            type="submit"
            @click="handleClickSelect"
            :disabled="!selectedCommentBank"
            :class="!selectedCommentBank ? 'opacity-[0.5]' : ''"
          >
            <template #icon_left>
              <span v-html="icons.checkBlack"></span>
            </template>
            Select
          </AppButton>
        </div>
      </div>
    </template>
  </ModalUtility>

  <DetachDialogComponent
    v-model="detachModalOpen"
    title="Detach Comment Bank"
    :body="`Are you sure you want to detach '${selectedCommentBankName}' to this assignment?`"
    @onConfirmDetach="handleDetachCommentBank"
  />
</template>

<script lang="ts">
import { Component, Prop, Vue, Ref, Model, Watch } from "vue-facing-decorator";
import AppTypographyText from "@/components/Layout/Typhography/AppTypographyText.vue";
import AppDropdownButton from "@/components/Layout/Buttons/AppDropdownButton.vue";
import Ellipsis from "@/components/utilities/Ellipsis.vue";
import { useToast } from "primevue/usetoast";
import CreateAssignment from "@/components/Assignment/CreateAssignment/CreateAssignment.vue";
import ModalUtility from "@/components/utilities/ModalUtility.vue";
import { icons } from "@/utils/icons";
import AppButton from "@/components/Layout/Buttons/AppButton.vue";
import CommentService from "@/services/CommentService";
import FormRadioButton from "@/components/Layout/Forms/FormRadioButton.vue";
import SelectGrading from "@/components/utilities/SelectGrading.vue";
import ProgressLoader from "@/components/utilities/ProgressLoader.vue";
import FormField from "@/components/Layout/Forms/FormField.vue";
import emitter from "@/config/emitter";
import { CommentBankList as CommentBanks } from "@/store/comments/types";
import { IAttachResource } from "@/models/Comment";
import { AxiosResponse } from "axios";
import DetachDialogComponent from "./DetachDialogComponent.vue";

interface CommentBankList {
  uuid: string;
  name: string;
}

@Component({
  components: {
    AppTypographyText,
    AppDropdownButton,
    Ellipsis,
    CreateAssignment,
    ModalUtility,
    AppButton,
    FormRadioButton,
    SelectGrading,
    ProgressLoader,
    FormField,
    DetachDialogComponent,
  },
})
export default class CommentBankListModal extends Vue {
  private commentService = new CommentService();
  toast = useToast();
  eventBus = emitter;

  @Model({
    type: Boolean,
    required: true,
  })
  visible!: boolean;

  @Prop({
    type: Object,
  })
  currentCommentBank: CommentBankList = {
    uuid: "",
    name: "",
  };

  @Prop({
    type: Function,
  })
  handleCommentBankData!: (uuid: string, data: CommentBanks) => void;

  @Prop({
    type: Function,
  })
  handleEditCommentBank!: (uuid: string, data: CommentBanks) => void;

  @Prop({
    type: Function,
  })
  handleCreateCommentBank!: () => void;

  @Prop({
    type: Function,
  })
  handleDetachCB!: (uuid: string, name: string) => void;

  statuses = [
    {
      name: "Edit",
    },
    {
      name: "Detach",
    },
  ];

  icons = icons;
  list: CommentBankList[] = [];
  listLoading = true;
  selectedCommentBank = "";
  selectedCommentBankName = "";
  comments = [];

  editCommentBankData!: CommentBanks;
  isSaveModalOpen = false;
  unsavedRedirectTo = "";

  detaching = false;
  detachModalOpen = false;

  @Watch("currentCommentBank.uuid")
  async currentCommentBankUuidWatcher(value: string) {
    if (value) {
      const existing = this.list.some(
        (item: CommentBankList) => item.uuid === value
      );
      if (!existing)
        this.list.push({ uuid: value, name: this.currentCommentBank.name });
      this.selectedCommentBank = value;
      const selectedCommentBankData = this.list.find(
        (item: CommentBankList) => item.uuid === value
      );
      if (selectedCommentBankData) {
        this.selectedCommentBankName = selectedCommentBankData.name;
      }
      return;
    }
  }

  @Watch("currentCommentBank.name")
  currentCommentBankNameWatcher(value: string) {
    this.selectedCommentBankName = value;
  }

  created() {
    this.fetchCommentBankList();
  }

  async fetchCommentBankList(assignmentUuid = "") {
    try {
      this.listLoading = true;
      const res = await this.commentService.getCommentBank(assignmentUuid);
      this.list = [];
      if (res.data.ok) {
        const comments = res.data.data;
        this.comments = comments;
        comments.map((item: CommentBanks) => {
          const obj: CommentBankList = {
            uuid: item.uuid,
            name: item.name,
          };

          this.list.push(obj);
        });

        this.listLoading = false;
      } else {
        throw new Error();
      }
    } catch (error) {
      console.error(error);
    }
  }

  handleSelectMenu(action: string) {
    if (action === "Edit") {
      this.handleEdit();
    } else if (action === "Detach") {
      this.handleDetach();
    }
  }

  handleClickCreate() {
    this.visible = true;
    this.handleCreateCommentBank();
    this.eventBus.emit("EVENT_TRIGGER", "AM003");
  }

  mounted() {
    this.eventBus.on("CLOSE_COMMENT_BANK_LIST_MODAL", () => {
      this.visible = false;
    });
  }

  handleCommentBankClick(uuid: string) {
    this.selectedCommentBank = uuid;
  }

  handleManageCommentBank() {
    this.$router.push({ path: "/comment-bank" });
  }

  handleDetach() {
    if (this.$route.name === "EditAssignment") {
      this.detachModalOpen = true;
    } else {
      this.setStateAfterDetach();
    }
  }

  async handleDetachCommentBank() {
    try {
      this.detaching = true;
      const assignmentUuid = this.$route.params.id as string;

      const payload: IAttachResource = {
        resourceId: this.selectedCommentBank,
        resourceType: "comment bank",
        targetId: assignmentUuid,
        targetType: "assignment",
      };

      const response: AxiosResponse =
        await this.commentService.detachCommentBankAssignment(payload);
      if (response.data.ok) {
        this.setStateAfterDetach();
      } else {
        throw new Error();
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.detaching = false;
    }
  }

  setStateAfterDetach() {
    this.selectedCommentBankName = "";
    this.selectedCommentBank = "";

    this.showMessage(
      "Success",
      "Comment Bank Detached from this assignment",
      "success"
    );
    this.handleDetachCB("", "");
  }

  async handleEdit() {
    const assignmentUuid = this.$route.params.id as string;
    if (this.handleEditCommentBank) {
      if (this.editCommentBankData) {
        this.handleEditCommentBank(
          this.selectedCommentBank,
          this.editCommentBankData
        );
        return;
      }
      await this.fetchCommentBankList(assignmentUuid);
      const data: any = this.comments.find(
        (o: any) => o.uuid === this.selectedCommentBank
      );
      this.editCommentBankData = data;

      this.handleEditCommentBank(this.selectedCommentBank, data);
    }
  }

  handleClickSelect() {
    if (this.selectedCommentBank) {
      if (this.handleCommentBankData) {
        const data: any = this.comments.find(
          (o: any) => o.uuid === this.selectedCommentBank
        );
        this.handleCommentBankData(this.selectedCommentBank, data);
      }
    } else {
      this.showMessage("Invalid", "Please select comment bank first", "error");
    }
  }

  showMessage(
    summary: string,
    message: string,
    severity: "success" | "info" | "warn" | "error" | undefined
  ) {
    this.toast.add({
      summary: summary,
      severity: severity,
      detail: message,
      life: 3000,
    });
  }
}
</script>

<style scoped lang="scss"></style>
