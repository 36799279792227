import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "hidden lg:flex lg:items-center" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { class: "pb-2 pt-4 px-2 flex gap-2" }
const _hoisted_4 = { class: "text-nowrap font-flohh-font-bold text-flohh-text-body" }
const _hoisted_5 = { class: "text-flohh-text-caption" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PersonAvatar = _resolveComponent("PersonAvatar")!
  const _component_MenuButton = _resolveComponent("MenuButton")!
  const _component_MenuItem = _resolveComponent("MenuItem")!
  const _component_MenuItems = _resolveComponent("MenuItems")!
  const _component_MyAccount = _resolveComponent("MyAccount")!
  const _component_UpdatesModal = _resolveComponent("UpdatesModal")!
  const _component_SubscriptionInvitationModal = _resolveComponent("SubscriptionInvitationModal")!
  const _component_Menu = _resolveComponent("Menu")!
  const _component_ConfirmDialog = _resolveComponent("ConfirmDialog")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Menu, {
      as: "div",
      class: "relative"
    }, {
      default: _withCtx(() => [
        _withDirectives((_openBlock(), _createBlock(_component_MenuButton, { class: "flex items-center gap-2 pr-3 pl-1 py-1 bg-flohh-neutral-95 hover:bg-flohh-neutral-85 rounded-full" }, {
          default: _withCtx(() => [
            _createVNode(_component_PersonAvatar, {
              firstName: _ctx.userInfo.profile.firstName,
              lastName: _ctx.userInfo.profile.lastName,
              background: false,
              size: "lg"
            }, null, 8, ["firstName", "lastName"]),
            _createElementVNode("span", _hoisted_1, [
              _createElementVNode("span", {
                innerHTML: _ctx.icons.arrowDropDownBlack,
                "aria-hidden": "true"
              }, null, 8, _hoisted_2)
            ])
          ]),
          _: 1
        })), [
          [
            _directive_tooltip,
            {
        value: 'Your profile menu',
        showDelay: 500,
      },
            void 0,
            { left: true }
          ]
        ]),
        _createVNode(_Transition, {
          "enter-active-class": "transition duration-100 ease-out",
          "enter-from-class": "transform scale-95 opacity-0",
          "enter-to-class": "transform scale-100 opacity-100",
          "leave-active-class": "transition duration-75 ease-in",
          "leave-from-class": "transform scale-100 opacity-100",
          "leave-to-class": "transform scale-95 opacity-0"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_MenuItems, { class: "absolute right-0 z-10 px-2 pb-2 mt-2.5 w-auto origin-top-right rounded-md bg-white shadow-lg border border-flohh-neutral-85 border-solid" }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(_component_PersonAvatar, {
                    firstName: _ctx.userInfo.profile.firstName,
                    lastName: _ctx.userInfo.profile.lastName,
                    class: "!bg-flohh-secondary-blue",
                    size: "lg"
                  }, null, 8, ["firstName", "lastName"]),
                  _createElementVNode("div", null, [
                    _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.userInfo.profile.firstName) + " " + _toDisplayString(_ctx.userInfo.profile.lastName), 1),
                    _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.userInfo.workEmail), 1)
                  ])
                ]),
                (!_ctx.isSuperAdmin)
                  ? (_openBlock(), _createBlock(_component_MenuItem, { key: 0 }, {
                      default: _withCtx(() => [
                        _withDirectives((_openBlock(), _createElementBlock("button", {
                          class: "w-full py-2 px-4 flex justify-start hover:bg-flohh-primary-light-pink rounded-[4px] items-center text-flohh-text-body",
                          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleClickMyAccount && _ctx.handleClickMyAccount(...args)))
                        }, [
                          _createTextVNode(" My Account ")
                        ])), [
                          [
                            _directive_tooltip,
                            {
              value: 'Your account',
              showDelay: 500,
            },
                            void 0,
                            { left: true }
                          ]
                        ])
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (!_ctx.isSuperAdmin)
                  ? (_openBlock(), _createBlock(_component_MenuItem, { key: 1 }, {
                      default: _withCtx(() => [
                        _createElementVNode("button", {
                          class: "w-full py-2 px-4 flex justify-start hover:bg-flohh-primary-light-pink rounded-[4px] items-center text-flohh-text-body",
                          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleClickUpdates && _ctx.handleClickUpdates(...args)))
                        }, " Updates ")
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                _createVNode(_component_MenuItem, null, {
                  default: _withCtx(() => [
                    _withDirectives((_openBlock(), _createElementBlock("button", {
                      class: "w-full py-2 px-4 flex justify-start hover:bg-flohh-primary-light-pink rounded-[4px] items-center text-flohh-text-body",
                      onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleConfirm && _ctx.handleConfirm(...args)))
                    }, [
                      _createTextVNode(" Logout ")
                    ])), [
                      [
                        _directive_tooltip,
                        { value: 'Logout', showDelay: 500 },
                        void 0,
                        { left: true }
                      ]
                    ])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        (_ctx.role)
          ? (_openBlock(), _createBlock(_component_MyAccount, {
              key: 0,
              modelValue: _ctx.isMyAccountModalOpen,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.isMyAccountModalOpen) = $event)),
              role: _ctx.role
            }, null, 8, ["modelValue", "role"]))
          : _createCommentVNode("", true),
        _createVNode(_component_UpdatesModal, {
          modelValue: _ctx.isUpdatesModalOpen,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.isUpdatesModalOpen) = $event)),
          updates: _ctx.pendingActions,
          onOnUpdatesModalClose: _ctx.handleUpdatesModalClose
        }, null, 8, ["modelValue", "updates", "onOnUpdatesModalClose"]),
        _createVNode(_component_SubscriptionInvitationModal, {
          modelValue: _ctx.subscriptionInvitationModalOpen,
          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.subscriptionInvitationModalOpen) = $event)),
          inviteInfo: _ctx.inviteSubsInfo,
          onOnClose: _ctx.handleSubscriptionClose
        }, null, 8, ["modelValue", "inviteInfo", "onOnClose"])
      ]),
      _: 1
    }),
    _createVNode(_component_ConfirmDialog)
  ], 64))
}