import { EventDetail, EventName } from "../type";

const generalEvents = (eventName: EventName): EventDetail[] => [
  {
    id: "G001",
    name: eventName.view,
    description: "Submit assignment",
  },
  {
    id: "G002",
    name: eventName.dropdown,
    description: "Select assignment",
  },
  {
    id: "G003",
    name: eventName.view,
    description: "View assignment details",
  },
  {
    id: "G004",
    name: eventName.dropdown,
    description: "Sort table data",
  },
  {
    id: "G005",
    name: eventName.view,
    description: "Change tab",
  },
  {
    id: "G006",
    name: eventName.dropdown,
    description: "Open 'Updates' modal",
  },
  {
    id: "G007",
    name: eventName.dropdown,
    description: "Open 'My Account' modal",
  },
  {
    id: "G008",
    name: eventName.button,
    description: "Edit account details",
  },
  {
    id: "G009",
    name: eventName.button,
    description: "Save account details",
  },
  {
    id: "G010",
    name: eventName.button,
    description: "Click change password",
  },
  {
    id: "G011",
    name: eventName.button,
    description: "Save password",
  },
  {
    id: "G012", // redirect to class page via sidebar
    name: eventName.page,
    description: "Visit class page",
  },
  {
    id: "G013", // redirect to dashboard via sidebar
    name: eventName.page,
    description: "Visit dashboard",
  },
  {
    id: "G014",
    name: eventName.page,
    description: "Visit help center page",
  },
  {
    id: "G015",
    name: eventName.page,
    description: "Visit app information page",
  },
  {
    id: "G016",
    name: eventName.dropdown,
    description: "Redirect to accounts portal",
  },
];

export default generalEvents;
