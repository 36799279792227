import axios from "axios";

const TEACHER_DASHBOARD_URL = "/assignments/dashboard-summary";
const STUDENT_DASHBOARD_URL = "/student/assignments/dashboard-summary";
const METRICS_BASE_URL = "/metrics/teachers";

export default class DashboardService {
  getTeacherDashboard() {
    return axios.get(TEACHER_DASHBOARD_URL);
  }
  getStudentDashboard() {
    return axios.get(STUDENT_DASHBOARD_URL);
  }
  getTeacherMetrics(teacherUuid: string) {
    return axios.get(`${METRICS_BASE_URL}/${teacherUuid}`);
  }

  removePendingAction(uuid: string) {
    return axios.delete(`/session/pending-actions/${uuid}`);
  }

  addGTag(uuid: string, data: Record<string, string | number>) {
    return axios.put(`/session/pending-actions/${uuid}`, { data });
  }
}
